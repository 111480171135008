import React from 'react';
import { useRecurrence } from '../../hooks/useRecurrence';
import '../../styles/RecurrenceModal.css';

const RecurrenceModal = ({ onClose, onSave }) => {
    const { recurrencePattern, occurrences, setRecurrencePattern, setOccurrences } = useRecurrence();

    const handleConfirm = () => {
        onSave(recurrencePattern, occurrences); // Pass both recurrence and occurrences
        onClose(); // Close the modal after saving
    };

    return (
        <div className="recurrence-modal-overlay">
            <div className="recurrence-modal-content">
                <h3>Would you like this event to recur?</h3>
                <div className="recurrence-options">
                    <label>
                        <input
                            type="radio"
                            value="daily"
                            checked={recurrencePattern === 'daily'}
                            onChange={() => setRecurrencePattern('daily')}
                        />
                        Daily
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="weekly"
                            checked={recurrencePattern === 'weekly'}
                            onChange={() => setRecurrencePattern('weekly')}
                        />
                        Weekly
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="monthly"
                            checked={recurrencePattern === 'monthly'}
                            onChange={() => setRecurrencePattern('monthly')}
                        />
                        Monthly
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="none"
                            checked={recurrencePattern === 'none'}
                            onChange={() => setRecurrencePattern('none')}
                        />
                        None
                    </label>
                </div>
                <div className="recurrence-occurrences">
                    <label>
                        Number of occurrences (max 15):
                        <input
                            type="number"
                            min="1"
                            max="15"
                            value={occurrences}
                            onChange={(e) => setOccurrences(parseInt(e.target.value, 10))}
                        />
                    </label>
                </div>
                <div className="recurrence-modal-actions">
                    <button onClick={handleConfirm}>Confirm</button>
                    <button onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default RecurrenceModal;
