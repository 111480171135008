import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const defaultAvatarPath = '/assets/default-avatar.png';

const GymFavoriteNotificationItem = ({ data }) => {
    const [profilePicError, setProfilePicError] = useState(false);

    if (!data?.details) {
        return <div>Error: Gym favorite data is not available.</div>;
    }

    const handleProfilePicError = () => {
        setProfilePicError(true);
    };

    return (
        <div className="notification-item gym-favorite-notification-item">
            <div className="notification-item-header">
                <h3>
                    <Link to={`/profile/${data.details.userId}`}>
                        <img
                            src={profilePicError ? defaultAvatarPath : (data.details.userPhotoURL || defaultAvatarPath)}
                            alt={`${data.details.userName}'s profile`}
                            className="profile-pic"
                            onError={handleProfilePicError}
                        />
                        {data.details.userName || 'A user'}
                    </Link>{' '}
                    favorited a gym!
                </h3>
            </div>
            <p>
                Gym:{' '}
                <Link to={`/gym/${data.details.gymId}`}>
                    {data.details.gymName}
                </Link>
            </p>
            <p>
                On {new Date(data.timestamp.toDate()).toLocaleDateString()}
            </p>
        </div>
    );
};

export default GymFavoriteNotificationItem;
