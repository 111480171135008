import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../services/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';

export const useAuth = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [intendedPath, setIntendedPath] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userDocRef = doc(firestore, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                setUser({ ...user, profile: userDoc.data() });
                setShowLoginModal(false);
                
                if (intendedPath) {
                    navigate(intendedPath);
                    setIntendedPath(null);
                }
            } else {
                setUser(null);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, [navigate, intendedPath]);

    const requireAuth = useCallback((path) => {
        if (!user) {
            setIntendedPath(path);
            setShowLoginModal(true);
            return false;
        }
        return true;
    }, [user]);

    return {
        user,
        loading,
        showLoginModal,
        setShowLoginModal,
        requireAuth,
        intendedPath
    };
}; 