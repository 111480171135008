import React, { useState, useCallback, useEffect } from 'react';
import SpotifyIcon from '../common/SpotifyIcon';
import EventPlaylistSelector from './EventPlaylistSelector';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../../services/firebase';
import logActivity from '../../utils/logActivity';
import { formatUserLink, formatEventLink, formatGymLink } from '../../utils/formatLink';
import { removePlaylist } from '../../services/playlistService';
import '../../styles/EventPlaylistCard.css';

const EventPlaylistCard = ({ user, event, gym, gymId }) => {
    const [playlistEmbedCode, setPlaylistEmbedCode] = useState(event?.playlistEmbedCode || '');
    const [playlistError, setPlaylistError] = useState(null);
    const [isPlaylistLoading, setIsPlaylistLoading] = useState(false);
    const [showPlaylistSelector, setShowPlaylistSelector] = useState(false);
    const [embedLoadError, setEmbedLoadError] = useState(false);
    const [currentEvent, setCurrentEvent] = useState(event);

    useEffect(() => {
        const fetchEventData = async () => {
            if (gymId && event?.id) {
                const eventDoc = await getDoc(doc(firestore, `locations/${gymId}/events/${event.id}`));
                if (eventDoc.exists()) {
                    setCurrentEvent({ ...eventDoc.data(), id: event.id });
                }
            }
        };

        fetchEventData();
    }, [gymId, event?.id]);

    const handlePlaylistSelect = async (playlist) => {
        if (!user || !event) return;
        setIsPlaylistLoading(true);
        try {
            const embedCode = `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/${playlist.id}" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`;
            
            const playlistData = {
                playlistEmbedCode: embedCode,
                playlistId: playlist.id,
                playlistName: playlist.name,
                playlistOwner: playlist.owner.display_name,
                playlistAddedBy: user.uid,
                playlistAddedAt: new Date()
            };

            await updateDoc(doc(firestore, 'locations', gymId, 'events', event.id), playlistData);

            // Update local states
            setPlaylistEmbedCode(embedCode);
            setCurrentEvent(prev => ({
                ...prev,
                ...playlistData
            }));
            setShowPlaylistSelector(false);
            setPlaylistError(null);

            await logActivity(user.uid, 'playlist_updated', {
                userId: user.uid,
                userName: user.displayName,
                gymId: gymId,
                gymName: gym.name,
                eventId: event.id,
                eventTitle: event.title,
                userPhotoURL: user.photoURL,
                message: `${formatUserLink(user.uid, user.displayName)} updated the playlist for: ${formatEventLink(gymId, event.id, event.title)} at: ${formatGymLink(gymId, gym.name)}`
            });
        } catch (error) {
            console.error('Error saving playlist:', error);
            setPlaylistError('Failed to save the playlist. Please try again.');
        } finally {
            setIsPlaylistLoading(false);
        }
    };

    const handleRemovePlaylist = async () => {
        if (!user || !event || !gymId || !gym) {
            setPlaylistError('Unable to remove playlist: missing required data');
            return;
        }

        setIsPlaylistLoading(true);
        try {
            const locationData = {
                locationId: gymId,
                name: gym.name,
                type: 'gym'
            };

            const success = await removePlaylist(
                user,
                event,
                locationData,
                setPlaylistEmbedCode,
                setPlaylistError
            );

            if (!success) {
                setPlaylistError('Failed to remove playlist');
            }
        } catch (error) {
            console.error('Error removing playlist:', error);
            setPlaylistError('An error occurred while removing the playlist');
        } finally {
            setIsPlaylistLoading(false);
        }
    };

    const handleEmbedRetry = useCallback(() => {
        setEmbedLoadError(false);
        const currentEmbed = playlistEmbedCode;
        setPlaylistEmbedCode('');
        setTimeout(() => setPlaylistEmbedCode(currentEmbed), 100);
    }, [playlistEmbedCode]);

    return (
        <div className="event-playlist-card">
            <h3>Event Playlist</h3>
            {playlistError && (
                <div className="error-message" style={{ color: 'red', marginBottom: '10px' }}>
                    {playlistError}
                </div>
            )}
            {playlistEmbedCode ? (
                embedLoadError ? (
                    <div className="playlist-error-container" style={{ textAlign: 'center', padding: '20px' }}>
                        <p style={{ marginBottom: '15px' }}>Failed to load the playlist. This might be due to a temporary connection issue.</p>
                        <button 
                            className="spotify-connect-button"
                            onClick={handleEmbedRetry}
                            style={{ margin: '0 auto' }}
                        >
                            <SpotifyIcon /> Retry Loading
                        </button>
                    </div>
                ) : (
                    <>
                        <iframe
                            src={playlistEmbedCode.match(/src="([^"]+)"/)?.[1]}
                            style={{ borderRadius: '12px' }}
                            width="100%"
                            height="380"
                            frameBorder="0"
                            allowFullScreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            loading="lazy"
                            onError={() => setEmbedLoadError(true)}
                            onLoad={() => setEmbedLoadError(false)}
                        />
                        {(user?.uid === currentEvent?.playlistAddedBy || !currentEvent?.playlistAddedBy || gym?.owner?.uid === user?.uid) && (
                            <button 
                                className="event-remove-button" 
                                onClick={handleRemovePlaylist}
                                disabled={isPlaylistLoading}
                            >
                                {isPlaylistLoading ? 'Removing...' : 'Remove Playlist'}
                            </button>
                        )}
                    </>
                )
            ) : showPlaylistSelector ? (
                <EventPlaylistSelector 
                    userId={user?.uid}
                    onPlaylistSelect={handlePlaylistSelect}
                />
            ) : (
                <button 
                    className="spotify-connect-button"
                    onClick={() => setShowPlaylistSelector(true)}
                >
                    <SpotifyIcon />
                    Connect Spotify
                </button>
            )}
        </div>
    );
};

export default EventPlaylistCard; 