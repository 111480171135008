import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, updateDoc, collection, getDocs, arrayUnion, arrayRemove, writeBatch, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { logActivity } from '../utils/logActivity.js';
import { formatUserLink, formatGymLink } from '../utils/formatLink.js';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// console.log('Firebase Config:', firebaseConfig);

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const firestore = getFirestore(app);
const storage = getStorage(app);

const MIN_PHOTO_UPDATE_INTERVAL = 1000 * 60 * 60; // 1 hour in milliseconds
const RATE_LIMIT_COOLDOWN = 1000 * 60 * 60; // 1 hour cooldown for rate limits

onAuthStateChanged(auth, async (user) => {
    if (user) {
        try {
            const userDocRef = doc(firestore, 'users', user.uid);

            // Update last login and check profile picture
            await updateDoc(userDocRef, {
                lastLogin: serverTimestamp()
            });

            // Check and update profile picture if needed
            await checkAndUpdateProfilePicture(user);

        } catch (error) {
            console.error("Error updating user data:", error);
        }
    }
});

const uploadProfilePicture = async (user) => {
    try {
        if (!user?.photoURL) return null;

        // Validate that this is actually a Google profile picture
        const googlePhotoURLPattern = /^https:\/\/lh3\.googleusercontent\.com\//;
        if (!googlePhotoURLPattern.test(user.photoURL)) {
            console.warn('Not a valid Google profile picture URL:', user.photoURL);
            return null;
        }

        const response = await fetch(user.photoURL);
        if (!response.ok) {
            if (response.status === 429) {
                // Store the rate limit timestamp in Firestore
                const userRef = doc(firestore, 'users', user.uid);
                await updateDoc(userRef, {
                    photoURLRateLimitedAt: serverTimestamp()
                });
                console.warn('Rate limited when fetching profile picture. Will retry after cooldown.');
            } else {
                console.warn('Failed to fetch profile picture:', response.statusText);
            }
            return null;
        }

        // Validate content type
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.startsWith('image/')) {
            console.warn('Invalid content type for profile picture:', contentType);
            return null;
        }

        const blob = await response.blob();
        // Validate blob size (must be greater than 0 and less than 5MB)
        if (blob.size === 0 || blob.size > 5 * 1024 * 1024) {
            console.warn('Invalid profile picture size:', blob.size);
            return null;
        }

        const storageRef = ref(storage, `profile_pictures/${user.uid}/profile.jpg`);
        await uploadBytes(storageRef, blob);
        const photoURL = await getDownloadURL(storageRef);
        
        // Clear rate limit timestamp on successful upload
        const userRef = doc(firestore, 'users', user.uid);
        await updateDoc(userRef, {
            photoURLRateLimitedAt: null
        });
        
        // Log successful upload
        console.log('Successfully stored profile picture in Firebase Storage');
        return photoURL;
    } catch (error) {
        console.error('Error uploading profile picture:', error);
        return null;
    }
};

const checkAndUpdateProfilePicture = async (user) => {
    try {
        const userRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        
        if (!userDoc.exists()) return;

        const userData = userDoc.data();
        
        // If we already have a Firebase Storage URL, don't update
        if (userData.photoURL && userData.photoURL.includes(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET)) {
            return userData.photoURL;
        }
        
        // Check if we're in a rate limit cooldown
        if (userData.photoURLRateLimitedAt) {
            const rateLimitedAt = userData.photoURLRateLimitedAt.toMillis();
            const timeSinceRateLimit = Date.now() - rateLimitedAt;
            
            if (timeSinceRateLimit < RATE_LIMIT_COOLDOWN) {
                console.log('Still in rate limit cooldown. Time remaining:', 
                    Math.ceil((RATE_LIMIT_COOLDOWN - timeSinceRateLimit) / 1000 / 60), 'minutes');
                return userData.photoURL;
            }
        }
        
        // Only try to upload if we haven't stored it yet and not rate limited
        const photoURL = await uploadProfilePicture(user);
        if (photoURL) {
            await updateDoc(userRef, { 
                photoURL,
                lastPhotoUpdate: serverTimestamp()
            });
            return photoURL;
        }
        
        return userData.photoURL;
    } catch (error) {
        console.error('Error checking/updating profile picture:', error);
        return null;
    }
};

const checkAndCreateUserProfile = async (user) => {
    try {
        const userRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
            // For new users, try to upload their profile picture immediately
            const photoURL = await uploadProfilePicture(user);
            await setDoc(userRef, {
                uid: user.uid,
                email: user.email,
                displayName: user.displayName,
                photoURL: photoURL || user.photoURL || '',
                username: user.email.split('@')[0].toLowerCase(),
                lastPhotoUpdate: serverTimestamp(),
            });
            return true;
        } else {
            // For existing users, check if we need to update their photo
            await checkAndUpdateProfilePicture(user);
            return false;
        }
    } catch (error) {
        console.error('Error checking/creating user profile:', error);
    }
};

const logoutUser = async () => {
    try {
        await auth.signOut();
    } catch (error) {
        console.error('Error signing out:', error);
    }
};

export const fetchPlaceInfo = async (placeId) => {
    const placeDocRef = doc(firestore, 'locations', placeId);
    const placeDoc = await getDoc(placeDocRef);
    if (placeDoc.exists()) {
        return placeDoc.data();
    }
    return null;
};

export const savePlaceInfo = async (placeId, placeData) => {
    const placeDocRef = doc(firestore, 'locations', placeId);
    await setDoc(placeDocRef, placeData);
};

export const fetchEvents = async (placeId) => {
    const eventsCollection = collection(firestore, 'locations', placeId, 'events');
    const eventsSnapshot = await getDocs(eventsCollection);
    return eventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const saveEvent = async (placeId, events) => {
    const batch = writeBatch(firestore);
    const eventDocRefs = [];

    for (let event of events) {
        const eventDocRef = doc(collection(firestore, 'locations', placeId, 'events'));
        batch.set(eventDocRef, event);
        eventDocRefs.push(eventDocRef);
    }

    await batch.commit();
    return eventDocRefs;
};

// Save event with recurrence
const saveEventWithRecurrence = async (recurrence, newEventDetails, user, placeId, placeInfo, setEvents, checkAndSetOpenMatStatus, setShowRecurrenceModal, setNewEventDetails) => {
    if (!newEventDetails) return;

    let newEvents = [];
    const eventTemplate = {
        ...newEventDetails,
        addedBy: {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
        },
        attendees: [
            {
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
            }
        ],
        recurrence
    };

    const startDate = new Date(newEventDetails.start);
    const endDate = new Date(newEventDetails.end);

    // Determine the recurrence interval and generate additional events
    if (recurrence === 'daily') {
        for (let i = 0; i < 7; i++) { // Example: create daily events for a week
            let eventCopy = {
                ...eventTemplate,
                start: new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000),
                end: new Date(endDate.getTime() + i * 24 * 60 * 60 * 1000)
            };
            newEvents.push(eventCopy);
        }
    } else if (recurrence === 'weekly') {
        for (let i = 0; i < 4; i++) { // Example: create weekly events for a month
            let eventCopy = {
                ...eventTemplate,
                start: new Date(startDate.getTime() + i * 7 * 24 * 60 * 60 * 1000),
                end: new Date(endDate.getTime() + i * 7 * 24 * 60 * 60 * 1000)
            };
            newEvents.push(eventCopy);
        }
    } else if (recurrence === 'monthly') {
        for (let i = 0; i < 3; i++) { // Example: create monthly events for three months
            let eventCopy = {
                ...eventTemplate,
                start: new Date(startDate.getTime() + i * 30 * 24 * 60 * 60 * 1000),
                end: new Date(endDate.getTime() + i * 30 * 24 * 60 * 60 * 1000)
            };
            newEvents.push(eventCopy);
        }
    } else {
        newEvents.push(eventTemplate);  // No recurrence, just add the single event
    }

    try {
        const batch = writeBatch(firestore);
        let eventDocRefs = [];

        for (let event of newEvents) {
            const eventDocRef = doc(collection(firestore, 'locations', placeId, 'events'));
            batch.set(eventDocRef, event);
            eventDocRefs.push(eventDocRef);
        }

        await batch.commit();

        const savedEventsWithIds = await Promise.all(eventDocRefs.map(async (ref, index) => {
            const docSnap = await getDoc(ref);
            return {
                ...newEvents[index],
                id: docSnap.id,
            };
        }));

        setEvents(prevEvents => [...prevEvents, ...savedEventsWithIds]);

        checkAndSetOpenMatStatus();

        await updateDoc(doc(firestore, 'users', user.uid), {
            attending: arrayUnion(...savedEventsWithIds.map(event => ({
                eventId: event.id,
                locationId: placeId,
                title: event.title,
                start: event.start,
                end: event.end
            })))
        });

        await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} added an event: ${newEventDetails.title} at: ${formatGymLink(placeId, placeInfo.name)}`, 'event_creation', {
            userId: user.uid,
            userName: user.displayName,
            gymId: placeId,
            gymName: placeInfo.name,
            eventId: savedEventsWithIds.map(event => event.id),
            eventTitle: newEventDetails.title,
            userPhotoURL: user.photoURL
        });

        // Trigger the Cloud Function to send emails
        const functions = getFunctions();
        const sendNewEventEmail = httpsCallable(functions, 'sendNewEventEmail');
        await sendNewEventEmail({ 
            gymId: placeId, 
            eventId: savedEventsWithIds[0].id // We're using the first event ID for simplicity
        });

        setShowRecurrenceModal(false);
        setNewEventDetails(null);

    } catch (error) {
        console.error('Error adding event: ', error);
    }
};

export const fetchComments = async (gymId) => {
    const commentsRef = collection(firestore, `locations/${gymId}/comments`);
    const snapshot = await getDocs(commentsRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const toggleFavorite = async (userId, placeId, isFavorite) => {
    const userDocRef = doc(firestore, 'users', userId);
    const placeDocRef = doc(firestore, 'locations', placeId);
    const placeDoc = await getDoc(placeDocRef);
    if (!placeDoc.exists()) return;

    const placeData = placeDoc.data();
    let newFavoriteCount = placeData.favoriteCount || 0;

    if (isFavorite) {
        await updateDoc(userDocRef, { favorites: arrayRemove(placeId) });
        await updateDoc(placeDocRef, { favoritedBy: arrayRemove(userId) });
        newFavoriteCount--;
    } else {
        await updateDoc(userDocRef, { favorites: arrayUnion(placeId) });
        await updateDoc(placeDocRef, { favoritedBy: arrayUnion(userId) });
        newFavoriteCount++;
    }

    await updateDoc(placeDocRef, { favoriteCount: newFavoriteCount });
    return { newFavoriteCount, isFavorite: !isFavorite };
};

// Export all required functions and objects
export {
    auth,
    googleProvider,
    facebookProvider,
    firestore,
    storage,
    checkAndCreateUserProfile,
    logoutUser,
    saveEventWithRecurrence
};