import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../../services/firebase';
import { doc, getDoc, updateDoc, deleteDoc, arrayUnion, writeBatch, arrayRemove } from 'firebase/firestore';
import { collection, getDocs, query, where, limit } from 'firebase/firestore';
import { FaCalendarAlt, FaClock, FaMapMarkerAlt, FaUser, FaLink, 
    FaUserPlus, FaCalendarTimes, FaTrash, FaTimes, FaShareAlt } from 'react-icons/fa';
import CommentsSection from './CommentsSection';
import InviteButton from './InviteButton';
import logActivity from '../../utils/logActivity';
import { formatUserLink, formatGymLink, formatEventLink } from '../../utils/formatLink';
import Modal from './Modal';
import { 
    FacebookShareButton, TwitterShareButton, WhatsappShareButton, 
    TelegramShareButton, EmailShareButton,
    FacebookIcon, TwitterIcon, WhatsappIcon, 
    TelegramIcon, EmailIcon 
} from 'react-share';
import useIsMounted from '../../hooks/useIsMounted';
import safeAsync from '../../utils/safeAsync';
import EventPlaylistCard from './EventPlaylistCard';
import useComments from '../../hooks/useComments';
import LeftSidebar from '../layout/LeftSidebar';
import RightSidebar from '../layout/RightSidebar';
import {
    EventInfoLayout,
    MainContent,
    EventHeaderCard,
    EventHeaderLeft,
    EventHeaderRight,
    ShareIcons,
    EventCard,
    AttendeesList,
    AttendeeItem,
    EventActions,
    EventMetaInfo,
    MetaItem,
    LocationInfo
} from '../../styles/EventInfo.styles';
import ShareModal from './ShareModal';

const EventInfo = () => {
    const { gymId, eventId } = useParams();
    const [user] = useAuthState(auth);
    const [gym, setGym] = useState(null);
    const [event, setEvent] = useState(null);
    const [isAttending, setIsAttending] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [suggestedUsers, setSuggestedUsers] = useState([]);
    const [suggestedGyms, setSuggestedGyms] = useState([]);
    const navigate = useNavigate();
    const isMounted = useIsMounted();
    const [copySuccess, setCopySuccess] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);

    const commentsPath = useMemo(() => 
        gymId && eventId ? `locations/${gymId}/events/${eventId}/comments` : null,
        [gymId, eventId]
    );

    const { comments, loading: commentsLoading, hasMore, loadMore } = useComments(commentsPath);

    const fetchEvent = useCallback(async () => {
        if (!gymId || !eventId) return;
        
        const safeFetch = safeAsync(async () => {
            const eventDoc = await getDoc(doc(firestore, `locations/${gymId}/events`, eventId));
            if (eventDoc.exists()) {
                setEvent({
                    ...eventDoc.data(),
                    locationId: gymId,
                    id: eventId
                });
            } else {
                console.error('No such event!');
                setShowModal(true);
            }
        }, isMounted);

        try {
            await safeFetch();
        } catch (error) {
            console.error('Error fetching event:', error);
        }
    }, [gymId, eventId, isMounted]);

    const fetchGymInfo = useCallback(async () => {
        if (!gymId) return;
        
        const safeFetch = safeAsync(async () => {
            const gymDoc = await getDoc(doc(firestore, 'locations', gymId));
            if (gymDoc.exists()) {
                setGym(gymDoc.data());
            } else {
                console.error('No such gym!');
                setShowModal(true);
            }
        }, isMounted);

        try {
            await safeFetch();
        } catch (error) {
            console.error('Error fetching gym:', error);
        }
    }, [gymId, isMounted]);

    useEffect(() => {
        fetchEvent();
        fetchGymInfo();
    }, [fetchEvent, fetchGymInfo]);

    useEffect(() => {
        if (event && user) {
            const attending = event.attendees?.some(attendee => attendee.uid === user.uid);
            setIsAttending(attending);
        }
    }, [event, user]);

    const handleDeleteEvent = async () => {
        // Confirm deletion with the user
        const confirmDelete = window.confirm('Are you sure you want to delete this event?');
        if (!confirmDelete) return;

        // Ensure the user is the creator of the event
        if (user?.uid !== event.addedBy?.uid) {
            alert('You do not have permission to delete this event.');
            return;
        }

        const eventDocRef = doc(firestore, 'locations', gymId, 'events', eventId);
        try {
            // Start a batch to perform multiple writes as a single atomic operation
            const batch = writeBatch(firestore);

            // Remove the event from each user's attending array
            for (let attendee of event.attendees) {
                const userDocRef = doc(firestore, 'users', attendee.uid);
                batch.update(userDocRef, {
                    attending: arrayRemove({
                        eventId: eventId,
                        locationId: gymId,
                        title: event.title,
                        start: event.start,
                        end: event.end
                    })
                });
            }

            // Delete the event document from Firestore
            batch.delete(eventDocRef);

            // Commit the batch
            await batch.commit();

            // Log the activity of event deletion
            await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} deleted the event: ${formatEventLink(gymId, eventId, event.title)} at: ${formatGymLink(gymId, gym.name)}`, 'event_deleted', {
                userId: user.uid,
                userName: user.displayName,
                gymId: gymId,
                gymName: gym.name,
                eventId: eventId,
                eventTitle: event.title,
                userPhotoURL: user.photoURL
            });

            // Navigate back to the gym page after deletion
            navigate(`/gym/${gymId}`);
        } catch (error) {
            // Handle any errors that occur during deletion
            console.error('Error deleting event:', error.message);
            alert('Error deleting event: ' + error.message);
        }
    };

    const handleAttendClick = useCallback(async () => {
        if (!user || !event) return;

        const isHost = user.uid === event.addedBy?.uid;
        const confirmMessage = isHost 
            ? "Warning: As the event organizer, leaving this event will delete it for all attendees. Are you sure you want to proceed?"
            : "Are you sure you want to cancel your attendance?";

        if (!window.confirm(confirmMessage)) return;

        try {
            if (isHost) {
                // Delete the entire event
                await handleDeleteEvent();
            } else {
                // Just remove attendance
                const eventRef = doc(firestore, `locations/${gymId}/events`, eventId);
                const attendeeData = {
                    uid: user.uid,
                    displayName: user.displayName,
                    photoURL: user.photoURL
                };

                await updateDoc(eventRef, {
                    attendees: arrayRemove(attendeeData)
                });

                setIsAttending(false);
                
                await logActivity(user.uid, 'event_unattend', {
                    userId: user.uid,
                    userName: user.displayName,
                    eventId: eventId,
                    eventTitle: event.title,
                    userPhotoURL: user.photoURL,
                    message: `${formatUserLink(user.uid, user.displayName)} is no longer attending: ${formatEventLink(gymId, eventId, event.title)} at ${formatGymLink(gymId, gym.name)}`
                });
            }
        } catch (error) {
            console.error('Error updating attendance:', error);
        }
    }, [user, event, isAttending, gymId, eventId, gym?.name]);

    useEffect(() => {
        const fetchSuggestions = async () => {
            if (!user) return;

            try {
                // Fetch suggested users
                const usersRef = collection(firestore, 'users');
                const usersQuery = query(
                    usersRef,
                    where('role.creator', '==', true),
                    limit(5)
                );
                const userSnapshot = await getDocs(usersQuery);
                const users = userSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(u => u.id !== user.uid);

                setSuggestedUsers(users);

                // Fetch suggested gyms
                const gymsRef = collection(firestore, 'locations');
                const gymsQuery = query(gymsRef, limit(3));
                const gymSnapshot = await getDocs(gymsQuery);
                const gyms = gymSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }));

                setSuggestedGyms(gyms);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        };

        fetchSuggestions();
    }, [user]);

    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText(shareUrl);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
        } catch (err) {
            console.error('Failed to copy link:', err);
        }
    };

    if (showModal) {
        return (
            <Modal
                title="Error"
                content="Event or Gym not found. Please check the URL or try again later."
                onClose={() => navigate('/')}
            />
        );
    }

    if (!event || !gym) {
        return <div>Loading...</div>;
    }

    const shareUrl = window.location.href;
    const shareTitle = `Join me at ${event.title} on ${new Date(event.start.toDate()).toLocaleDateString()}!`;

    return (
        <EventInfoLayout>
            <LeftSidebar />
            <MainContent>
                <EventHeaderCard>
                    <EventHeaderLeft>
                        <div className="title-row">
                            <h1>{event.title}</h1>
                            {user?.uid === event.addedBy?.uid && (
                                <button 
                                    onClick={handleDeleteEvent} 
                                    className="action-icon delete"
                                    title="Delete Event"
                                >
                                    <FaTrash />
                                </button>
                            )}
                        </div>
                        <EventMetaInfo>
                            <MetaItem>
                                <FaUser />
                                Hosted by <Link to={`/profile/${event.addedBy.uid}`}>{event.addedBy.displayName}</Link>
                            </MetaItem>
                            <MetaItem>
                                <FaCalendarAlt />
                                {new Date(event.start.toDate()).toLocaleDateString()}
                            </MetaItem>
                            <MetaItem>
                                <FaClock />
                                {new Date(event.start.toDate()).toLocaleTimeString()} - {new Date(event.end.toDate()).toLocaleTimeString()}
                            </MetaItem>
                            <MetaItem>
                                <FaMapMarkerAlt />
                                <LocationInfo>
                                    <Link to={`/gym/${gymId}`}>{gym.name}</Link>
                                    <span className="address">{gym.address}</span>
                                </LocationInfo>
                            </MetaItem>
                        </EventMetaInfo>
                    </EventHeaderLeft>
                    <EventHeaderRight>
                        <div className="header-actions">
                            <button 
                                onClick={() => setShowShareModal(true)} 
                                className="action-icon"
                                title="Share Event"
                            >
                                <span>Share</span>
                                <FaShareAlt />
                            </button>
                        </div>
                    </EventHeaderRight>
                </EventHeaderCard>

                <EventCard>
                    <h3>Attendees</h3>
                    <AttendeesList>
                        {event.attendees.map(attendee => (
                            <AttendeeItem key={attendee.uid}>
                                <Link to={`/profile/${attendee.uid}`}>
                                    <img src={attendee.photoURL || 'placeholder.png'} alt={attendee.displayName} />
                                    <span>{attendee.displayName}</span>
                                </Link>
                                {user?.uid === attendee.uid && (
                                    <button 
                                        className="remove-attendance"
                                        onClick={handleAttendClick}
                                        title="Cancel attendance"
                                    >
                                        <FaTimes />
                                    </button>
                                )}
                            </AttendeeItem>
                        ))}
                    </AttendeesList>
                </EventCard>

                <EventCard>
                    <h3>Comments</h3>
                    <CommentsSection
                        comments={comments}
                        loading={commentsLoading}
                        hasMore={hasMore}
                        onLoadMore={loadMore}
                        uniqueId={eventId}
                        collectionType="event"
                        currentUser={user}
                        commentType="event" 
                        itemId={eventId}
                        userId={gymId}
                    />
                </EventCard>

                <EventCard>
                    <h3>Playlist</h3>
                    <EventPlaylistCard 
                        user={user}
                        event={event}
                        gym={gym}
                        gymId={gymId}
                    />
                </EventCard>
            </MainContent>
            <RightSidebar 
                suggestedUsers={suggestedUsers}
                suggestedGyms={suggestedGyms}
            />

            <ShareModal 
                isOpen={showShareModal}
                onClose={() => setShowShareModal(false)}
                shareUrl={window.location.href}
                shareTitle={`Join me at ${event.title} on ${new Date(event.start.toDate()).toLocaleDateString()}!`}
                event={event}
                gymName={gym.name}
            />
        </EventInfoLayout>
    );
};

export default EventInfo;