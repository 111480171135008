import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { allCountries } from 'country-region-data';
import '../../styles/EditProfileModal.css';
import { doc, updateDoc, setDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../../services/firebase';
import { isUserCreator } from '../../services/userProfileService';

Modal.setAppElement('#root');

const EditProfileModal = ({ profile, onSave, onClose, forceCompleteProfile = false }) => {
    // Parse the hometown string if it exists
    const [hometown] = useState(() => {
        if (profile.hometown) {
            const parts = profile.hometown.split(', ');
            return {
                city: parts[0] || '',
                region: parts[1] || '',
                country: parts[2] || ''
            };
        }
        return { city: '', region: '', country: '' };
    });

    const [selectedCountry, setSelectedCountry] = useState(hometown.country || profile.country || '');
    const [selectedRegion, setSelectedRegion] = useState(hometown.region || profile.region || '');
    const [selectedCity, setSelectedCity] = useState(hometown.city || profile.city || '');
    const [beltRank, setBeltRank] = useState(profile.beltRank || '');
    const [bio, setBio] = useState(profile.bio || '');
    const [username, setUsername] = useState(profile.username || '');
    const [usernameError, setUsernameError] = useState('');
    const [isCheckingUsername, setIsCheckingUsername] = useState(false);
    const [requestCalendar, setRequestCalendar] = useState(profile.requestCalendar || false);
    const [isCreator, setIsCreator] = useState(profile.role?.creator || false);
    const [creatorSettings, setCreatorSettings] = useState({
        subscriptionEnabled: profile.creatorSettings?.subscriptionEnabled || false,
        monthlyPrice: profile.creatorSettings?.monthlyPrice || 9.99,
        yearlyPrice: profile.creatorSettings?.yearlyPrice || 99.99,
        description: profile.creatorSettings?.description || '',
    });
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        const parseHometown = () => {
            if (profile.hometown) {
                const parts = profile.hometown.split(', ');
                return {
                    city: parts[0] || '',
                    region: parts[1] || '',
                    country: parts[2] || ''
                };
            }
            return { city: '', region: '', country: '' };
        };

        const hometown = parseHometown();
        if (profile.country || hometown.country) setSelectedCountry(hometown.country || profile.country);
        if (profile.region || hometown.region) setSelectedRegion(hometown.region || profile.region);
        if (profile.city || hometown.city) setSelectedCity(hometown.city || profile.city);
        if (profile.username) setUsername(profile.username);
        if (profile.beltRank) setBeltRank(profile.beltRank);
        if (profile.bio) setBio(profile.bio);
        setIsCreator(profile.role?.creator || false);
        setCreatorSettings(profile.creatorSettings || {
            subscriptionEnabled: false,
            monthlyPrice: 9.99,
            yearlyPrice: 99.99,
            description: '',
        });
    }, [profile]);

    useEffect(() => {
        const hasProfileChanges = 
            selectedCountry !== (profile.country || '') ||
            selectedRegion !== (profile.region || '') ||
            selectedCity !== (profile.city || '') ||
            beltRank !== (profile.beltRank || '') ||
            bio !== (profile.bio || '') ||
            (!profile.username && username !== (profile.username || '')) ||
            requestCalendar !== (profile.requestCalendar || false) ||
            isCreator !== (profile.role?.creator || false) ||
            (isCreator && JSON.stringify(creatorSettings) !== JSON.stringify(profile.creatorSettings || {
                subscriptionEnabled: false,
                monthlyPrice: 9.99,
                yearlyPrice: 99.99,
                description: '',
            }));
        
        setHasChanges(hasProfileChanges);
    }, [selectedCountry, selectedRegion, selectedCity, beltRank, bio, username, requestCalendar, isCreator, creatorSettings, profile]);

    const validateUsername = async (username) => {
        if (!username) {
            setUsernameError('Username is required');
            return false;
        }

        // Check username format
        const usernameRegex = /^[a-zA-Z0-9_]{3,20}$/;
        if (!usernameRegex.test(username)) {
            setUsernameError('Username must be 3-20 characters and can only contain letters, numbers, and underscores');
            return false;
        }

        setIsCheckingUsername(true);
        try {
            // Check if username is already taken
            const usernameQuery = query(
                collection(firestore, 'users'),
                where('username', '==', username.toLowerCase())
            );
            const querySnapshot = await getDocs(usernameQuery);
            
            if (!querySnapshot.empty && querySnapshot.docs[0].id !== profile.uid) {
                setUsernameError('Username is already taken');
                setIsCheckingUsername(false);
                return false;
            }

            setUsernameError('');
            setIsCheckingUsername(false);
            return true;
        } catch (error) {
            console.error('Error checking username:', error);
            setUsernameError('Error checking username availability');
            setIsCheckingUsername(false);
            return false;
        }
    };

    const handleUsernameChange = async (e) => {
        const newUsername = e.target.value;
        setUsername(newUsername);
        if (profile.username) {
            setUsernameError('Username cannot be changed once set');
            return;
        }
        await validateUsername(newUsername);
    };

    const handleSave = async () => {
        if (selectedCountry && selectedRegion && selectedCity && beltRank && bio) {
            const userId = profile.uid;
            if (!userId) {
                console.error('User ID is undefined');
                return;
            }

            // If username is not set yet, validate it
            if (!profile.username && (!username || usernameError)) {
                alert('Please set a valid username');
                return;
            }

            try {
                const docRef = doc(firestore, 'users', userId);
                const calendarRequestRef = doc(firestore, 'claimRequests', userId);

                const calendarRequestSnapshot = await getDoc(calendarRequestRef);
                const calendarRequestExists = calendarRequestSnapshot.exists();
                const calendarRequestStatus = calendarRequestExists
                    ? calendarRequestSnapshot.data().status
                    : null;

                const updateData = {
                    hometown: `${selectedCity}, ${selectedRegion}, ${selectedCountry}`,
                    country: selectedCountry,
                    region: selectedRegion,
                    city: selectedCity,
                    beltRank,
                    bio,
                    requestCalendar: requestCalendar && !calendarRequestExists,
                    role: {
                        ...(profile.role || {}),
                        admin: profile.role?.admin || false,
                        creator: isCreator,
                        moderator: profile.role?.moderator || false,
                        user: true
                    }
                };

                // Only set username if it hasn't been set before
                if (!profile.username && username) {
                    updateData.username = username.toLowerCase();
                }

                if (isCreator) {
                    updateData.creatorSettings = creatorSettings;
                }

                console.log('Updating user profile with roles:', updateData.role);
                await updateDoc(docRef, updateData);

                if (requestCalendar && (!calendarRequestExists || calendarRequestStatus !== 'approved')) {
                    await setDoc(calendarRequestRef, {
                        userId: userId,
                        type: 'calendar_request',
                        status: 'pending',
                        createdAt: new Date(),
                    });
                } else if (!requestCalendar && calendarRequestExists) {
                    await updateDoc(calendarRequestRef, { status: 'removed' });
                }

                onSave({
                    ...profile,
                    ...updateData
                });
            } catch (error) {
                console.error('Error saving profile:', error);
            }
        } else {
            alert('All fields are required.');
        }
    };

    const handleCancel = () => {
        // Reset all form fields to their original values
        setSelectedCountry(hometown.country || profile.country || '');
        setSelectedRegion(hometown.region || profile.region || '');
        setSelectedCity(hometown.city || profile.city || '');
        setBeltRank(profile.beltRank || '');
        setBio(profile.bio || '');
        setRequestCalendar(profile.requestCalendar || false);
        setIsCreator(profile.role?.creator || false);
        setCreatorSettings(profile.creatorSettings || {
            subscriptionEnabled: false,
            monthlyPrice: 9.99,
            yearlyPrice: 99.99,
            description: '',
        });
        onClose();
    };

    const handleCountryChange = (event) => {
        const country = event.target.value;
        setSelectedCountry(country);
        setSelectedRegion('');
        setSelectedCity('');
    };

    const handleRegionChange = (event) => {
        const region = event.target.value;
        setSelectedRegion(region);
        setSelectedCity('');
    };

    const handleCityChange = (event) => setSelectedCity(event.target.value);

    const selectedCountryData = allCountries.find(country => country[1] === selectedCountry);
    const regions = selectedCountryData && Array.isArray(selectedCountryData[2])
        ? selectedCountryData[2].map(region => Array.isArray(region) ? region[0] : region)
        : [];

    return (
        <Modal
            isOpen={true}
            contentLabel="Edit Profile"
            className="edit-profile-modal"
            overlayClassName="edit-profile-modal-overlay"
            onRequestClose={handleCancel}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
        >
            <div className="modal-content">
                <h2>Edit Profile</h2>
                <div className="form-group username-group">
                    <label>Username</label>
                    <div className="username-input-container">
                        <input
                            type="text"
                            value={username}
                            onChange={handleUsernameChange}
                            placeholder={profile.username ? '' : 'Choose a unique username'}
                            className={usernameError ? 'error' : ''}
                            disabled={!!profile.username}
                        />
                        {!profile.username && (
                            <>
                                {isCheckingUsername && <span className="checking-username">Checking availability...</span>}
                                {usernameError && <span className="error-message">{usernameError}</span>}
                                <p className="username-help">
                                    This will be your unique identifier and cannot be changed later.
                                    Use 3-20 characters, letters, numbers, and underscores only.
                                </p>
                            </>
                        )}
                    </div>
                </div>
                <div className="hometown-group">
                    <label>Hometown</label>
                    <div className="hometown-inputs">
                        <select 
                            value={selectedCountry} 
                            onChange={handleCountryChange}
                        >
                            <option value="" disabled>Select Country</option>
                            {allCountries.map((country) => (
                                <option key={country[1]} value={country[1]}>
                                    {country[0]}
                                </option>
                            ))}
                        </select>
                        <select 
                            value={selectedRegion} 
                            onChange={handleRegionChange} 
                            disabled={!selectedCountry}
                        >
                            <option value="" disabled>Select State/Province</option>
                            {regions.map((region, index) => (
                                <option key={`${selectedCountry}-${index}`} value={region}>
                                    {region}
                                </option>
                            ))}
                        </select>
                        <input
                            type="text"
                            value={selectedCity}
                            onChange={handleCityChange}
                            placeholder="Enter City"
                            disabled={!selectedRegion}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Belt Rank</label>
                    <select 
                        value={beltRank} 
                        onChange={(e) => setBeltRank(e.target.value)}
                    >
                        <option value="" disabled>Select Belt Rank</option>
                        <option value="None">None</option>
                        <option value="White">White</option>
                        <option value="Blue">Blue</option>
                        <option value="Purple">Purple</option>
                        <option value="Brown">Brown</option>
                        <option value="Black">Black</option>
                        <option value="Coral">Coral</option>
                        <option value="Red">Red</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Bio</label>
                    <textarea
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                        placeholder="Tell us about yourself"
                    />
                </div>
                <div className="settings-toggles-container">
                    <div className="settings-toggle-group">
                        <div className="toggle-header">
                            <h3>Profile Features</h3>
                            <p className="toggle-description">Enable additional features for your profile</p>
                        </div>
                        
                        <div className="toggle-item">
                            <label className="toggle-switch">
                                <span className="toggle-label">Events Calendar</span>
                                <div className="switch">
                                    <input
                                        type="checkbox"
                                        checked={requestCalendar}
                                        onChange={(e) => setRequestCalendar(e.target.checked)}
                                    />
                                    <span className="slider"></span>
                                </div>
                            </label>
                            <p className="toggle-description">
                                Request access to create and manage events on your profile calendar
                            </p>
                        </div>

                        <div className="toggle-item">
                            <label className="toggle-switch">
                                <span className="toggle-label">Creator Mode</span>
                                <div className="switch">
                                    <input
                                        type="checkbox"
                                        checked={isCreator}
                                        onChange={async (e) => {
                                            setIsCreator(e.target.checked);
                                            if (!e.target.checked) {
                                                setCreatorSettings({
                                                    subscriptionEnabled: false,
                                                    monthlyPrice: 9.99,
                                                    yearlyPrice: 99.99,
                                                    description: '',
                                                });
                                            }
                                        }}
                                    />
                                    <span className="slider"></span>
                                </div>
                            </label>
                            <div className="toggle-description">
                                <p>As a creator, you can:</p>
                                <ul>
                                    <li>Post public content for everyone</li>
                                    <li>Share exclusive content with your followers</li>
                                    <li>Offer premium content to paid subscribers</li>
                                    <li>Build your BJJ community and monetize your content</li>
                                </ul>
                                <p className="note">
                                    {isCreator 
                                        ? "Disabling creator mode will preserve your content and settings. You can re-enable it anytime to continue where you left off." 
                                        : "Enable creator mode to start sharing your BJJ journey and knowledge with the community."}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {isCreator && (
                    <div className="creator-settings-section">
                        <h3>Creator Settings</h3>
                        <div className="toggle-item">
                            <label className="toggle-switch">
                                <span className="toggle-label">Enable Subscriptions</span>
                                <div className="switch">
                                    <input
                                        type="checkbox"
                                        checked={creatorSettings.subscriptionEnabled}
                                        onChange={(e) => setCreatorSettings({
                                            ...creatorSettings,
                                            subscriptionEnabled: e.target.checked,
                                        })}
                                    />
                                    <span className="slider"></span>
                                </div>
                            </label>
                        </div>
                        {creatorSettings.subscriptionEnabled && (
                            <>
                                <div className="form-group">
                                    <label>Monthly Subscription Price ($)</label>
                                    <input
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        value={creatorSettings.monthlyPrice}
                                        onChange={(e) => setCreatorSettings({
                                            ...creatorSettings,
                                            monthlyPrice: parseFloat(e.target.value),
                                        })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Yearly Subscription Price ($)</label>
                                    <input
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        value={creatorSettings.yearlyPrice}
                                        onChange={(e) => setCreatorSettings({
                                            ...creatorSettings,
                                            yearlyPrice: parseFloat(e.target.value),
                                        })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Subscription Description</label>
                                    <textarea
                                        value={creatorSettings.description}
                                        onChange={(e) => setCreatorSettings({
                                            ...creatorSettings,
                                            description: e.target.value,
                                        })}
                                        placeholder="Describe what subscribers will get access to..."
                                    />
                                </div>
                            </>
                        )}
                    </div>
                )}

                <div className="form-actions">
                    <button 
                        onClick={handleSave}
                        disabled={!hasChanges}
                        className={`save-button ${!hasChanges ? 'disabled' : ''}`}
                    >
                        Save Changes
                    </button>
                    <button 
                        onClick={handleCancel}
                        className="cancel-button"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default EditProfileModal;
