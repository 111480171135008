import React, { useEffect, useState } from 'react';
import { firestore } from '../../services/firebase';
import { collection, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import UserTable from './UserTable';
import ReportedContent from './ReportedContent';
import Settings from './Settings';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import CustomLegend from './CustomLegend';
import '../../styles/AdminDashboard.css';
import useIsMounted from '../../hooks/useIsMounted';
import safeAsync from '../../utils/safeAsync';

const AdminDashboard = () => {
    const isMounted = useIsMounted();
    const [userStats, setUserStats] = useState([]);
    const [activeUsers, setActiveUsers] = useState(0);
    const [reportedContents, setReportedContents] = useState([]);
    const [pendingClaims, setPendingClaims] = useState([]);
    const [pendingCalendarRequests, setPendingCalendarRequests] = useState([]);
    const [visibleMetrics, setVisibleMetrics] = useState({
        events: true,
        favorites: true,
        comments: true,
        replies: true,
        reactions: true,
        followers: true,
        following: true,
    });

    useEffect(() => {
        const fetchUserStats = async () => {
            const safeFetch = safeAsync(async () => {
                const userCollection = collection(firestore, 'users');
                const userSnapshot = await getDocs(userCollection);
                const usersData = userSnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));

                const activeUsersCount = usersData.filter(user => {
                    const lastLoginDate = user.lastLogin?.toDate();
                    return lastLoginDate && lastLoginDate > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
                }).length;

                setActiveUsers(activeUsersCount);

                const stats = await Promise.all(usersData.map(async (user) => {
                    const commentsRef = collection(firestore, `users/${user.uid}/comments`);
                    const commentsSnapshot = await getDocs(commentsRef);
                    const commentCount = commentsSnapshot.size;

                    const repliesRef = collection(firestore, `users/${user.uid}/replies`);
                    const repliesSnapshot = await getDocs(repliesRef);
                    const replyCount = repliesSnapshot.size;

                    const reactionsRef = collection(firestore, `users/${user.uid}/reactions`);
                    const reactionsSnapshot = await getDocs(reactionsRef);
                    const reactionCount = reactionsSnapshot.size;

                    const followersCount = user.followers?.length || 0;
                    const followingCount = user.following?.length || 0;

                    return {
                        name: user.displayName,
                        events: user.attending?.length || 0,
                        favorites: user.favorites?.length || 0,
                        comments: commentCount,
                        replies: replyCount,
                        reactions: reactionCount,
                        followers: followersCount,
                        following: followingCount,
                    };
                }));

                setUserStats(stats);
            }, isMounted);

            try {
                await safeFetch();
            } catch (error) {
                console.error('Error fetching user stats:', error);
            }
        };

        const fetchReportedContents = async () => {
            const safeFetch = safeAsync(async () => {
                const reportCollection = collection(firestore, 'reports');
                const reportSnapshot = await getDocs(reportCollection);
                setReportedContents(reportSnapshot.docs.map(doc => doc.data()));
            }, isMounted);

            try {
                await safeFetch();
            } catch (error) {
                console.error('Error fetching reported contents:', error);
            }
        };

        const fetchPendingClaims = async () => {
            const safeFetch = safeAsync(async () => {
                const claimsRef = collection(firestore, 'claimRequests');
                const claimsSnapshot = await getDocs(claimsRef);

                const pending = await Promise.all(
                    claimsSnapshot.docs
                        .filter(docSnapshot => {
                            const claimData = docSnapshot.data();
                            return claimData.type === 'gym' && claimData.status === 'pending';
                        })
                        .map(async docSnapshot => {
                            const claimData = docSnapshot.data();
                            const gymDoc = await getDoc(doc(firestore, 'locations', claimData.gymId));
                            const userDoc = await getDoc(doc(firestore, 'users', claimData.userId));

                            return {
                                id: docSnapshot.id,
                                gymName: gymDoc.exists() ? gymDoc.data().name : 'Unknown Gym',
                                userName: userDoc.exists() ? userDoc.data().displayName : 'Unknown User'
                            };
                        })
                );

                setPendingClaims(pending.filter(claim => claim !== null));
            }, isMounted);

            try {
                await safeFetch();
            } catch (error) {
                console.error('Error fetching pending claims:', error);
            }
        };

        const fetchCalendarRequests = async () => {
            const safeFetch = safeAsync(async () => {
                const calendarRequestRef = collection(firestore, 'claimRequests');
                const calendarRequestSnapshot = await getDocs(calendarRequestRef);
                const pendingRequests = await Promise.all(
                    calendarRequestSnapshot.docs
                        .filter(docSnapshot => {
                            const requestData = docSnapshot.data();
                            return requestData.type === 'calendar_request' && requestData.status === 'pending';
                        })
                        .map(async (docSnapshot) => {
                            const requestData = docSnapshot.data();
                            const userDoc = await getDoc(doc(firestore, 'users', requestData.userId));
                            return {
                                id: docSnapshot.id,
                                userName: userDoc.exists() ? userDoc.data().displayName : 'Unknown User',
                                userId: requestData.userId,
                                status: requestData.status
                            };
                        })
                );
                setPendingCalendarRequests(pendingRequests);
            }, isMounted);

            try {
                await safeFetch();
            } catch (error) {
                console.error('Error fetching calendar requests:', error);
            }
        };

        fetchUserStats();
        fetchReportedContents();
        fetchPendingClaims();
        fetchCalendarRequests();
    }, []);

    const toggleMetricVisibility = (metric) => {
        setVisibleMetrics((prevMetrics) => ({
            ...prevMetrics,
            [metric]: !prevMetrics[metric],
        }));
    };

    const handleApproval = async (claimId) => {
        const claimRef = doc(firestore, 'claimRequests', claimId);
        await updateDoc(claimRef, { status: 'approved' });
        setPendingClaims(pendingClaims.filter(claim => claim.id !== claimId)); // Remove from the list
    };

    const handleDenial = async (claimId) => {
        const claimRef = doc(firestore, 'claimRequests', claimId);
        await updateDoc(claimRef, { status: 'denied' });
        setPendingClaims(pendingClaims.filter(claim => claim.id !== claimId)); // Remove from the list
    };

    const handleCalendarApproval = async (requestId, userId) => {
        const requestRef = doc(firestore, 'claimRequests', requestId);
        const userRef = doc(firestore, 'users', userId);
        await updateDoc(requestRef, { status: 'approved' });
        await updateDoc(userRef, { hasCalendar: true });
        setPendingCalendarRequests(pendingCalendarRequests.filter(request => request.id !== requestId));
    };

    const handleCalendarDenial = async (requestId) => {
        const requestRef = doc(firestore, 'claimRequests', requestId);
        await updateDoc(requestRef, { status: 'denied' });
        setPendingCalendarRequests(pendingCalendarRequests.filter(request => request.id !== requestId));
    };

    return (
        <div className="admin-dashboard">
            <h1>Admin Dashboard</h1>
            <div className="dashboard-stats">
                <div className="stats-card">
                    <h3>Total Users</h3>
                    <p>{userStats.length}</p>
                </div>
                <div className="stats-card">
                    <h3>Active Users (Last 7 Days)</h3>
                    <p>{activeUsers}</p>
                </div>
                <div className="stats-card">
                    <h3>Reported Content</h3>
                    <p>{reportedContents.length}</p>
                </div>
            </div>
            <div className="dashboard-charts">
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={userStats}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend
                            content={<CustomLegend onClick={toggleMetricVisibility} visibleMetrics={visibleMetrics}/>}
                        />
                        {visibleMetrics.events && <Line type="monotone" dataKey="events" stroke="#8884d8"/>}
                        {visibleMetrics.favorites && <Line type="monotone" dataKey="favorites" stroke="#82ca9d"/>}
                        {visibleMetrics.comments && <Line type="monotone" dataKey="comments" stroke="#ffc658"/>}
                        {visibleMetrics.replies && <Line type="monotone" dataKey="replies" stroke="#ff8042"/>}
                        {visibleMetrics.reactions && <Line type="monotone" dataKey="reactions" stroke="#8884d8"/>}
                        {visibleMetrics.followers && <Line type="monotone" dataKey="followers" stroke="#00c49f"/>}
                        {visibleMetrics.following && <Line type="monotone" dataKey="following" stroke="#ffbb28"/>}
                    </LineChart>
                </ResponsiveContainer>
            </div>

            {/* Gym Claims Section */}
            <div className="dashboard-section">
                <h2>Pending Gym Claims</h2>
                {pendingClaims.length > 0 ? (
                    <ul className="claims-list">
                        {pendingClaims.map(claim => (
                            <li key={claim.id} className="claim-item">
                                <div className="claim-info">
                                    <p>Gym: {claim.gymName}</p>
                                    <p>User: {claim.userName}</p>
                                </div>
                                <div className="claim-buttons">
                                    <button onClick={() => handleApproval(claim.id)}>Approve</button>
                                    <button onClick={() => handleDenial(claim.id)}>Deny</button>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No pending claims.</p>
                )}
            </div>


            <div className="dashboard-section">
                <h2>Pending Calendar Requests</h2>
                {pendingCalendarRequests.length > 0 ? (
                    <ul className="calendar-requests-list">
                        {pendingCalendarRequests.map(request => (
                            <li key={request.id} className="calendar-request-item">
                                <div className="request-info">
                                    <p>User: {request.userName}</p>
                                </div>
                                <div className="request-buttons">
                                    <button onClick={() => handleCalendarApproval(request.id, request.userId)}>Approve
                                    </button>
                                    <button onClick={() => handleCalendarDenial(request.id)}>Deny</button>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No pending calendar requests.</p>
                )}
            </div>

            <UserTable users={userStats}/>
            <ReportedContent reports={reportedContents}/>
            <Settings/>
        </div>
    );
};

export default AdminDashboard;
