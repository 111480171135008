import React from 'react';
import { Link } from 'react-router-dom';

const AchievementNotificationItem = ({ data }) => {
    return (
        <div className="notification-item achievement-notification-item">
            <div className="notification-item-header">
                <h3>{data.displayName} unlocked an achievement!</h3>
            </div>
            <p>Achievement: {data.achievementName}</p>
            <p>On {new Date(data.timestamp.toDate()).toLocaleDateString()}</p>
            <div className="notification-item-footer">
                <Link to={`/profile/${data.userId}`} className="view-profile-button">View Profile</Link>
                <img src={data.achievementIcon} alt={data.achievementName} className="achievement-icon" />
            </div>
        </div>
    );
};

export default AchievementNotificationItem;
