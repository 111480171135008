import React from 'react';
import PageLayout from '../layout/PageLayout';
import { useNavigate } from 'react-router-dom';

const Feed = ({ posts = [], suggestedUsers = [], suggestedGyms = [] }) => {
  const navigate = useNavigate();

  return (
    <PageLayout suggestedUsers={suggestedUsers} suggestedGyms={suggestedGyms}>
      <div className="feed-container">
        <div className="infinite-scroll-container">
          {posts.map((post) => (
            <div key={post.id} className="feed-item">
              <div className="feed-item-header">
                <img 
                  src={post.authorPhotoURL || '/default-avatar.png'} 
                  alt={post.authorName} 
                  className="profile-pic"
                />
                <h3>{post.authorName}</h3>
              </div>
              <p>{post.content}</p>
              <div className="feed-item-footer">
                <div className="reactions-container">
                  <button className="like-button">
                    {post.liked ? '❤️' : '🤍'} {post.likes}
                  </button>
                  <button className="comment-button">
                    💬 {post.comments}
                  </button>
                </div>
                <button 
                  className="view-profile-button"
                  onClick={() => navigate(`/profile/${post.authorId}`)}
                >
                  View Profile
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </PageLayout>
  );
};

export default Feed;
