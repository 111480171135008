import { storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Constants for media processing
const MAX_IMAGE_WIDTH = 1920;
const MAX_IMAGE_HEIGHT = 1080;
const IMAGE_QUALITY = 0.8; // 0-1, higher means better quality
const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // 100MB

/**
 * Process and optimize an image while maintaining quality
 * @param {File} file - The image file to process
 * @returns {Promise<Blob>} - Processed image as a Blob
 */
export const processImage = (file) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        img.onload = () => {
            // Calculate new dimensions while maintaining aspect ratio
            let width = img.width;
            let height = img.height;
            
            if (width > MAX_IMAGE_WIDTH) {
                height = Math.round((height * MAX_IMAGE_WIDTH) / width);
                width = MAX_IMAGE_WIDTH;
            }
            
            if (height > MAX_IMAGE_HEIGHT) {
                width = Math.round((width * MAX_IMAGE_HEIGHT) / height);
                height = MAX_IMAGE_HEIGHT;
            }
            
            // Set canvas dimensions
            canvas.width = width;
            canvas.height = height;
            
            // Draw and compress image
            ctx.drawImage(img, 0, 0, width, height);
            
            // Convert to blob
            canvas.toBlob(
                (blob) => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error('Failed to process image'));
                    }
                },
                'image/jpeg',
                IMAGE_QUALITY
            );
        };

        img.onerror = () => {
            reject(new Error('Failed to load image'));
        };

        // Load image from file
        img.src = URL.createObjectURL(file);
    });
};

/**
 * Check if video needs processing
 * @param {File} file - The video file to check
 * @returns {Promise<boolean>} - Whether the video needs processing
 */
const needsVideoProcessing = async (file) => {
    return new Promise((resolve) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        
        video.onloadedmetadata = () => {
            URL.revokeObjectURL(video.src);
            // Check if video is larger than max size
            resolve(file.size > MAX_VIDEO_SIZE);
        };
        
        video.onerror = () => {
            URL.revokeObjectURL(video.src);
            resolve(true); // Process if we can't determine
        };
        
        video.src = URL.createObjectURL(file);
    });
};

/**
 * Upload media to Firebase Storage with progress tracking
 * @param {Blob} file - The file to upload
 * @param {string} path - The storage path
 * @param {Function} onProgress - Progress callback
 * @returns {Promise<string>} - Download URL
 */
export const uploadMedia = async (file, path, onProgress = () => {}) => {
    const storageRef = ref(storage, path);
    
    // Create upload task
    const uploadTask = uploadBytes(storageRef, file);
    
    // Monitor upload progress
    uploadTask.on('state_changed', 
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            onProgress(Math.round(progress));
        }
    );
    
    // Wait for upload to complete
    await uploadTask;
    
    // Get download URL
    return await getDownloadURL(storageRef);
};

/**
 * Process and upload media file
 * @param {File} file - The file to process and upload
 * @param {string} type - The type of media ('image' or 'video')
 * @param {string} userId - The user's ID
 * @param {Function} onProgress - Progress callback
 * @returns {Promise<string>} - Download URL
 */
export const processAndUploadMedia = async (file, type, userId, onProgress = () => {}) => {
    try {
        let processedFile = file;
        
        if (type === 'image') {
            processedFile = await processImage(file);
            onProgress(50); // Processing complete
        } else if (type === 'video') {
            // For videos, we'll only process if necessary
            const needsProcessing = await needsVideoProcessing(file);
            if (needsProcessing) {
                // If video is too large, reject it and ask user to compress it first
                throw new Error('Video file is too large. Please compress it to under 100MB before uploading.');
            }
            onProgress(50);
        } else {
            throw new Error('Unsupported media type');
        }
        
        // Generate storage path
        const timestamp = Date.now();
        const path = `${type}s/${userId}/${timestamp}_${file.name}`;
        
        // Upload the processed file
        const downloadURL = await uploadMedia(
            processedFile,
            path,
            (progress) => {
                // Second 50% for uploading
                onProgress(50 + progress / 2);
            }
        );
        
        return downloadURL;
    } catch (error) {
        console.error('Error processing and uploading media:', error);
        throw error;
    }
}; 