import { useState } from 'react';

export const useRecurrence = (initialPattern = 'none') => {
    const [recurrencePattern, setRecurrencePattern] = useState(initialPattern);
    const [frequency, setFrequency] = useState(1);
    const [weekdays, setWeekdays] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [occurrences, setOccurrences] = useState(1);

    const handlePatternChange = (pattern) => {
        setRecurrencePattern(pattern);
        // Reset other fields when pattern changes
        if (pattern === 'none') {
            setFrequency(1);
            setWeekdays([]);
            setEndDate(null);
            setOccurrences(1);
        }
    };

    const handleWeekdayToggle = (day) => {
        setWeekdays(prev => {
            if (prev.includes(day)) {
                return prev.filter(d => d !== day);
            } else {
                return [...prev, day];
            }
        });
    };

    const generateRecurrenceRule = () => {
        if (recurrencePattern === 'none') return null;

        let rule = 'FREQ=';
        switch (recurrencePattern) {
            case 'daily':
                rule += 'DAILY';
                break;
            case 'weekly':
                rule += 'WEEKLY';
                if (weekdays.length > 0) {
                    rule += `;BYDAY=${weekdays.join(',')}`;
                }
                break;
            case 'monthly':
                rule += 'MONTHLY';
                break;
            default:
                return null;
        }

        if (frequency > 1) {
            rule += `;INTERVAL=${frequency}`;
        }

        if (endDate) {
            rule += `;UNTIL=${endDate.toISOString().split('T')[0].replace(/-/g, '')}`;
        } else if (occurrences) {
            rule += `;COUNT=${occurrences}`;
        }

        return rule;
    };

    return {
        recurrencePattern,
        frequency,
        weekdays,
        endDate,
        occurrences,
        setRecurrencePattern: handlePatternChange,
        setFrequency,
        toggleWeekday: handleWeekdayToggle,
        setEndDate,
        setOccurrences,
        generateRecurrenceRule
    };
}; 