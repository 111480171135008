import React, { useState, useMemo, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import { 
    Button, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    CircularProgress,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { VideoCall as VideoIcon, Link as LinkIcon, Image as ImageIcon } from '@mui/icons-material';
import { storage } from '../../services/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {
    CommentFormContainer,
    FormActions,
    MediaPreview,
    QuillWrapper
} from '../../styles/CommentForm.styled';
import { auth } from '../../services/firebase';
import VideoPlayer from '../VideoPlayer';
import { POST_VISIBILITY, getVisibilityLabel } from '../../constants/visibility';
import { processAndUploadMedia } from '../../services/mediaProcessingService';

const POST_TYPES = {
    COMMENT: 'comment',
    VIDEO: 'video',
    IMAGE: 'image'
};

const dialogStyles = {
    '& .MuiPaper-root': {
        backgroundColor: '#242424',
        color: '#fff',
        width: '90%',
        maxWidth: '600px',
        margin: '8px',
        '& .MuiDialogContent-root': {
            padding: {
                xs: '8px',
                sm: '12px'
            }
        },
        '& .MuiDialogActions-root': {
            padding: {
                xs: '4px 8px',
                sm: '8px 12px'
            }
        },
        '@media (max-width: 768px)': {
            width: '100%',
            maxWidth: '100%',
            margin: 0,
            borderRadius: 0,
            '& .MuiDialogContent-root': {
                padding: '8px'
            },
            '& .MuiDialogActions-root': {
                padding: '4px 8px'
            }
        }
    }
};

const quillModules = {
    clipboard: {
        matchVisual: false
    },
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['link'],
        ['clean']
    ],
    keyboard: {
        bindings: {
            tab: false,
            'indent-list': false,
            'outdent-list': false
        }
    }
};

const quillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link'
];

const CommentForm = ({ open, onClose, onSubmit, onUrlChange }) => {
    const [content, setContent] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState(null);
    const [videoUrls, setVideoUrls] = useState([]);
    const [linkDialogOpen, setLinkDialogOpen] = useState(false);
    const [linkUrl, setLinkUrl] = useState('');
    const [visibility, setVisibility] = useState(POST_VISIBILITY.PUBLIC);
    const quillRef = useRef(null);

    useEffect(() => {
        if (open && quillRef.current) {
            const editor = quillRef.current.getEditor();
            const editorElement = editor.container.querySelector('.ql-editor');
            
            if (editorElement) {
                // Use requestAnimationFrame to ensure DOM is ready
                requestAnimationFrame(() => {
                    editorElement.focus();
                    const range = document.createRange();
                    const sel = window.getSelection();
                    range.selectNodeContents(editorElement);
                    range.collapse(false);
                    sel.removeAllRanges();
                    sel.addRange(range);
                });
            }
        }
    }, [open]);

    const handleVideoUpload = async (file) => {
        if (!file) return;
        
        const user = auth.currentUser;
        if (!user) {
            setUploadError('You must be logged in to upload videos');
            return;
        }

        try {
            setUploadProgress(0);
            const downloadURL = await processAndUploadMedia(
                file,
                'video',
                user.uid,
                (progress) => setUploadProgress(progress)
            );
            
            console.log('Video uploaded successfully. URL:', downloadURL);
            setVideoUrls(prev => [...prev, downloadURL]);
            setUploadProgress(0);
            setUploadError(null);
        } catch (error) {
            console.error('Upload error:', error);
            setUploadError(`Upload error: ${error.message}`);
            setUploadProgress(0);
        }
    };

    const handleImageUpload = async (file) => {
        if (!file) return;
        
        const user = auth.currentUser;
        if (!user) {
            setUploadError('You must be logged in to upload images');
            return;
        }

        try {
            setUploadProgress(0);
            const downloadURL = await processAndUploadMedia(
                file,
                'image',
                user.uid,
                (progress) => setUploadProgress(progress)
            );
            
            console.log('Image uploaded successfully. URL:', downloadURL);
            const editor = quillRef.current.getEditor();
            const range = editor.getSelection();
            editor.insertEmbed(range.index, 'image', downloadURL);
            setUploadProgress(0);
            setUploadError(null);
        } catch (error) {
            console.error('Upload error:', error);
            setUploadError(`Upload error: ${error.message}`);
            setUploadProgress(0);
        }
    };

    const determinePostType = () => {
        if (videoUrls.length > 0) {
            return POST_TYPES.VIDEO;
        }
        // Add image detection here when implemented
        return POST_TYPES.COMMENT;
    };

    const handleSubmit = (e) => {
        e?.preventDefault();
        
        const postType = determinePostType();
        const fullContent = {
            text: content,
            videos: videoUrls,
            type: postType,
            visibility
        };

        onSubmit(fullContent);
        handleClose();
    };

    const handleClose = () => {
        setContent('');
        setVideoUrls([]);
        setUploadProgress(0);
        setUploadError(null);
        onClose();
    };

    const handleLinkSubmit = () => {
        if (linkUrl.trim() && quillRef.current) {
            const editor = quillRef.current.getEditor();
            const range = editor.getSelection(true);
            editor.insertText(range.index, linkUrl.trim(), 'link', linkUrl.trim());
            setLinkUrl('');
            setLinkDialogOpen(false);
        }
    };

    return (
        <>
            <Dialog 
                open={open} 
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                sx={dialogStyles}
            >
                <DialogTitle sx={{ pb: 0 }}>Create New Post</DialogTitle>
                <DialogContent>
                    <CommentFormContainer 
                        component="form" 
                        onSubmit={handleSubmit}
                        sx={{ position: 'relative', pt: 1 }}
                    >
                        <Box sx={{ mb: 1 }}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="visibility-label" sx={{ color: '#aaa' }}>
                                    Visibility
                                </InputLabel>
                                <Select
                                    labelId="visibility-label"
                                    value={visibility}
                                    onChange={(e) => setVisibility(e.target.value)}
                                    label="Visibility"
                                    sx={{
                                        color: '#fff',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#666'
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#888'
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#1976d2'
                                        },
                                        '.MuiSvgIcon-root': {
                                            color: '#aaa'
                                        }
                                    }}
                                >
                                    <MenuItem value={POST_VISIBILITY.PUBLIC}>Public</MenuItem>
                                    <MenuItem value={POST_VISIBILITY.FOLLOWERS}>Followers Only</MenuItem>
                                    <MenuItem value={POST_VISIBILITY.SUBSCRIBERS}>Subscribers Only</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        {uploadError && (
                            <Typography color="error" sx={{ mb: 1 }}>
                                {uploadError}
                            </Typography>
                        )}

                        {uploadProgress > 0 && uploadProgress < 100 && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <CircularProgress variant="determinate" value={uploadProgress} sx={{ mr: 1 }} />
                                <Typography>Uploading: {Math.round(uploadProgress)}%</Typography>
                            </Box>
                        )}

                        <QuillWrapper>
                            <ReactQuill
                                ref={quillRef}
                                theme="snow"
                                value={content}
                                onChange={setContent}
                                modules={quillModules}
                                formats={quillFormats}
                                placeholder="Write something..."
                            />
                        </QuillWrapper>

                        {videoUrls.length > 0 && (
                            <Box 
                                sx={{ 
                                    mt: 2,
                                    maxWidth: '500px',
                                    margin: '0 auto'
                                }}
                            >
                                {videoUrls.map((url, index) => (
                                    <Box key={index} sx={{ mb: 2 }}>
                                        <VideoPlayer src={url} />
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </CommentFormContainer>
                </DialogContent>
                <DialogActions sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 2,
                    py: 1
                }}>
                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                        <IconButton
                            onClick={() => {
                                const input = document.createElement('input');
                                input.setAttribute('type', 'file');
                                input.setAttribute('accept', 'video/mp4,video/avi,video/quicktime,video/x-ms-wmv');
                                input.click();

                                input.onchange = async () => {
                                    const file = input.files[0];
                                    if (!file) return;

                                    try {
                                        await handleVideoUpload(file);
                                    } catch (error) {
                                        setUploadError(error.message);
                                        console.error('Upload error:', error);
                                    }
                                };
                            }}
                            size="small"
                            sx={{ color: '#fff' }}
                        >
                            <VideoIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => setLinkDialogOpen(true)}
                            size="small"
                            sx={{ color: '#fff' }}
                        >
                            <LinkIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                const input = document.createElement('input');
                                input.setAttribute('type', 'file');
                                input.setAttribute('accept', 'image/*');
                                input.click();

                                input.onchange = async () => {
                                    const file = input.files[0];
                                    if (!file) return;

                                    try {
                                        await handleImageUpload(file);
                                    } catch (error) {
                                        setUploadError(error.message);
                                        console.error('Upload error:', error);
                                    }
                                };
                            }}
                            size="small"
                            sx={{ color: '#fff' }}
                        >
                            <ImageIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button onClick={handleClose} sx={{ color: '#aaa' }}>
                            Cancel
                        </Button>
                        <Button 
                            onClick={handleSubmit}
                            variant="contained"
                            disabled={!content.trim() && videoUrls.length === 0}
                        >
                            Post
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            {/* Link Dialog */}
            <Dialog
                open={linkDialogOpen}
                onClose={() => setLinkDialogOpen(false)}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: '#242424',
                        color: '#fff',
                        minWidth: '400px'
                    }
                }}
            >
                <DialogTitle>Add Link</DialogTitle>
                <DialogContent>
                    <Box 
                        component="form" 
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleLinkSubmit();
                        }}
                        sx={{ pt: 1 }}
                    >
                        <input
                            type="url"
                            value={linkUrl}
                            onChange={(e) => setLinkUrl(e.target.value)}
                            placeholder="Enter URL"
                            style={{
                                width: '100%',
                                padding: '8px 12px',
                                backgroundColor: '#363636',
                                border: 'none',
                                borderRadius: '4px',
                                color: '#fff',
                                fontSize: '16px',
                                outline: 'none'
                            }}
                            autoFocus
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setLinkDialogOpen(false)}
                        sx={{ color: '#fff' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleLinkSubmit}
                        variant="contained"
                        color="primary"
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CommentForm;