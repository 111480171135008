import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../services/firebase';
import { useAuth } from '../hooks/useAuth';
import { handleSpotifyCallback, saveSpotifyTokens } from '../services/spotifyService';

const SpotifyCallback = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAuth();

    useEffect(() => {
        const handleCallback = async () => {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');
            const state = params.get('state');
            const storedState = localStorage.getItem('spotify_auth_state');

            // Clear stored state
            localStorage.removeItem('spotify_auth_state');

            if (!code) {
                console.error('No authorization code found in URL');
                navigate('/');
                return;
            }

            if (state === null || state !== storedState) {
                console.error('State mismatch - possible CSRF attack');
                navigate('/');
                return;
            }

            if (!user) {
                console.error('No authenticated user found');
                navigate('/');
                return;
            }

            try {
                // Exchange code for access tokens
                const tokens = await handleSpotifyCallback(code);

                // Save tokens to user's profile
                await saveSpotifyTokens(user.uid, tokens);

                // Update user's Spotify connection status
                const userRef = doc(firestore, 'users', user.uid);
                await updateDoc(userRef, {
                    spotifyConnected: true
                });

                // Redirect back to profile
                navigate('/profile/' + user.uid);
            } catch (error) {
                console.error('Error handling Spotify callback:', error);
                navigate('/');
            }
        };

        handleCallback();
    }, [location, navigate, user]);

    return (
        <div style={{ padding: '2rem', textAlign: 'center' }}>
            <h2>Connecting to Spotify...</h2>
            <p>Please wait while we complete the connection.</p>
        </div>
    );
};

export default SpotifyCallback; 