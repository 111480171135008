import styled from 'styled-components';
import { LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from './layout/Sidebar.styles';

// Layout constants
const CONTENT_MIN_WIDTH = '600px';
const CONTENT_MAX_WIDTH = '800px';

export const UserProfileLayout = styled.div`
  display: grid;
  grid-template-columns: ${LEFT_SIDEBAR_WIDTH} minmax(${CONTENT_MIN_WIDTH}, ${CONTENT_MAX_WIDTH}) ${RIGHT_SIDEBAR_WIDTH};
  gap: 12px;
  min-height: 100vh;
  background-color: #000;
  max-width: 1440px;
  margin: 0 auto;
  transition: all 0.3s ease;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 1400px) {
    grid-template-columns: ${LEFT_SIDEBAR_WIDTH} minmax(${CONTENT_MIN_WIDTH}, 1fr) 240px;
    gap: 8px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 80px minmax(${CONTENT_MIN_WIDTH}, 1fr) 80px;
    gap: 4px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    width: 100vw;
    max-width: 100vw;
  }
`;

export const MainContent = styled.main`
  width: 100%;
  margin: 0 auto;
  transition: all 0.3s ease;
  position: relative;
  flex: 1;
  overflow-y: auto;
  padding: 16px;

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    margin-bottom: 60px;
  }
`;

export const UserBodyContent = styled.div`
  background-color: ${({ theme }) => theme.colors.background.primary};
  min-height: calc(100vh - 60px);
  padding: ${({ theme }) => theme.spacing(3)};
  transition: all 0.2s ease;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const UserProfileContainer = styled.div`
  max-width: 935px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(2)};

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
  }
`;

export const UserAboutCard = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  width: 100%;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    border-radius: 0;
    padding: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }
`;

export const ProfileSection = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 24px;
  align-items: start;

  @media (max-width: 768px) {
    grid-template-columns: 100px 1fr;
    gap: 12px;
    align-items: center;
  }
`;

export const ProfilePicContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
    margin: 0;
  }
`;

export const ProfilePic = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media (max-width: 768px) {
    gap: 12px;
  }
`;

export const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  h2 {
    font-size: 24px;
    margin: 0;
    color: #fff;
  }

  @media (max-width: 768px) {
    gap: 8px;
    
    h2 {
      font-size: 18px;
    }
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

export const StatsRow = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 12px;
    justify-content: flex-start;
  }
`;

export const StatItem = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-weight: 600;
  }

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

export const BioSection = styled.div`
  color: #fff;
  text-align: left;
  padding: 0;

  p {
    margin: 0;
    line-height: 1.5;
  }

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

export const IconActionButton = styled.button`
  background: none;
  border: 1px solid #333;
  color: #fff;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;

  svg {
    font-size: 20px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;

    svg {
      font-size: 18px;
    }
  }
`;

export const ActionButton = styled.button`
  background-color: ${props => props['data-following'] ? '#111' : '#fff'};
  color: ${props => props['data-following'] ? '#fff' : '#000'};
  border: 1px solid ${props => props['data-following'] ? '#333' : 'transparent'};
  padding: 8px 24px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${props => props['data-following'] ? '#222' : '#f0f0f0'};
  }

  @media (max-width: 768px) {
    padding: 6px 16px;
    font-size: 13px;
  }
`;

export const NewPostButton = styled(ActionButton)`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111;
  color: #fff;
  border: 1px solid #333;

  &:hover {
    background-color: #222;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const CreatorContentFeed = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  width: 100%;

  @media (max-width: 768px) {
    border-radius: 0;
    padding: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }
`;

export const ContentFeed = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;

export const ContentCard = styled.div`
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing(4)};
  transition: all 0.2s ease;

  @media (max-width: 768px) {
    border-radius: 0;
    padding: ${({ theme }) => theme.spacing(3)};
    margin: 0 -${({ theme }) => theme.spacing(2)};
  }

  h3 {
    margin: 0 0 ${({ theme }) => theme.spacing(3)} 0;
    font-size: ${({ theme }) => theme.typography.fontSize.large};
    color: ${({ theme }) => theme.colors.text.primary};
  }

  p {
    margin: 0 0 ${({ theme }) => theme.spacing(3)} 0;
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: ${({ theme }) => theme.typography.fontSize.base};
    line-height: ${({ theme }) => theme.typography.lineHeight.base};

    &:last-child {
      margin-bottom: 0;
    }
  }

  img, video {
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    margin-bottom: ${({ theme }) => theme.spacing(3)};

    @media (max-width: 768px) {
      border-radius: 0;
    }
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.shadows.medium};

    @media (max-width: 768px) {
      transform: none;
      box-shadow: none;
    }
  }
`;

export const CommentsSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(3)};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.shadows.medium};

  @media (max-width: 768px) {
    margin-top: ${({ theme }) => theme.spacing(2)};
    padding: ${({ theme }) => theme.spacing(2)};
    border-radius: 0;
    box-shadow: none;
  }

  h3 {
    color: ${({ theme }) => theme.colors.text.primary};
    margin: 0 0 ${({ theme }) => theme.spacing(2)};
  }
`;

export const CommentForm = styled.form`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const CommentInput = styled.textarea`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1.5)};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background-color: ${({ theme }) => theme.colors.background.paper};
  color: ${({ theme }) => theme.colors.text.primary};
  resize: vertical;
  min-height: 80px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  font-size: ${({ theme }) => theme.typography.fontSize.medium};
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.light};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const CommentButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.text.onPrimary};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.fontSize.medium};
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.primary.dark};
    transform: translateY(-1px);
  }

  &:active:not(:disabled) {
    transform: translateY(0);
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }
`;

export const CommentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const CommentItem = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => theme.spacing(2)};
  box-shadow: ${({ theme }) => theme.shadows.small};

  @media (max-width: 768px) {
    border-radius: 0;
    padding: ${({ theme }) => theme.spacing(1.5)};
    box-shadow: none;
  }
`;

export const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const CommentAuthor = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.medium};
`;

export const CommentDate = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.small};
`;

export const CommentContent = styled.p`
  color: ${({ theme }) => theme.colors.text.primary};
  margin: ${({ theme }) => theme.spacing(1)} 0;
  font-size: ${({ theme }) => theme.typography.fontSize.medium};
  line-height: 1.5;
`;

export const CommentActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const CommentActionButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.small};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
  transition: all 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const ReplySection = styled.div`
  margin-left: ${({ theme }) => theme.spacing(4)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  border-left: 2px solid ${({ theme }) => theme.colors.border};
  margin-top: ${({ theme }) => theme.spacing(2)};

  @media (max-width: 768px) {
    margin-left: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(1)};
  }
`;

export const SuggestionsContainer = styled.div`
  padding: 8px;
  background-color: #111;
  border-radius: 12px;
  margin-bottom: 8px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
    border-radius: 0;
    margin-bottom: 0;
  }

  h3 {
    color: #fff;
    font-size: 14px;
    margin: 0 0 8px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SuggestionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 1200px) {
    padding: 4px 0;
  }
`;

export const SuggestionInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1;
  min-width: 0;
`;

export const SuggestionAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 1200px) {
    width: 40px;
    height: 40px;
  }
`;

export const SuggestionDetails = styled.div`
  min-width: 0;

  h4 {
    color: #fff;
    font-size: 13px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    color: #999;
    font-size: 11px;
    margin: 2px 0 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const SuggestionButton = styled.button`
  background: none;
  border: 1px solid #333;
  color: #fff;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  margin-left: 8px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const CommentHighlight = styled('div')`
    @keyframes highlightFade {
        0% {
            background-color: rgba(25, 118, 210, 0.2);
        }
        100% {
            background-color: transparent;
        }
    }

    &.highlighted {
        animation: highlightFade 3s ease-out;
    }
`; 