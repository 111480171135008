import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../../services/firebase.js';
import { collection, query, where, limit, getDocs, getDoc, doc } from 'firebase/firestore';
import '../../styles/Notifications.css';
import { useNavigate } from 'react-router-dom';
import LeftSidebar from '../layout/LeftSidebar.js';
import RightSidebar from '../layout/RightSidebar.js';
import NotificationItem from './NotificationItem.js';
import { fetchNotifications } from '../../services/activitiesService.js';
import styled from 'styled-components';
import { LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from '../../styles/layout/Sidebar.styles';

const NOTIFICATIONS_PER_PAGE = 20;

// Custom layout without footer
const NotificationsLayout = styled.div`
    display: grid;
    grid-template-columns: ${LEFT_SIDEBAR_WIDTH} minmax(600px, 800px) ${RIGHT_SIDEBAR_WIDTH};
    gap: 12px;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.background.primary};
    max-width: 1440px;
    margin: 0 auto;
    transition: all 0.3s ease;
    position: relative;

    @media (max-width: 1400px) {
        grid-template-columns: ${LEFT_SIDEBAR_WIDTH} minmax(600px, 1fr) 240px;
        gap: 8px;
    }

    @media (max-width: 1200px) {
        grid-template-columns: 80px minmax(600px, 1fr) 80px;
        gap: 4px;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 0;
        height: 100vh;
        padding: 0;
        margin: 0;
        width: 100%;
    }
`;

const NotificationsContent = styled.main`
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    position: relative;
    padding: 8px;

    @media (max-width: 768px) {
        height: calc(100vh - 60px);
        padding: 4px;
    }

    .notifications-header {
        padding: 8px;
        margin-bottom: 8px;
        h2 {
            font-size: 1.2rem;
            margin: 0;
        }
    }

    .notifications-list {
        display: flex;
        flex-direction: column;
        gap: 1px;
    }

    .loading, .no-more, .no-activities {
        padding: 8px;
        text-align: center;
        color: ${({ theme }) => theme.colors.text.secondary};
        font-size: 0.9rem;
    }
`;

const Notifications = () => {
    const [user] = useAuthState(auth);
    const [activities, setActivities] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [pageCount, setPageCount] = useState(1);
    const [suggestedUsers, setSuggestedUsers] = useState([]);
    const [suggestedGyms, setSuggestedGyms] = useState([]);
    const navigate = useNavigate();
    const observer = useRef();
    let isMounted = true;

    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                fetchActivities(true);
            }
        });
        
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const fetchActivities = async (loadMore = false) => {
        if (!user || loading || (!hasMore && loadMore)) return;

        setLoading(true);

        try {
            const result = await fetchNotifications(
                user.uid,
                loadMore ? lastVisible : null,
                loadMore ? pageCount + 1 : 1
            );

            if (!isMounted) return;

            setActivities(prev => {
                const newActivities = loadMore ? [...prev, ...result.activities] : result.activities;
                return newActivities;
            });
            setLastVisible(result.lastVisible);
            setHasMore(result.hasMore);
            if (loadMore) {
                setPageCount(prev => prev + 1);
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch suggested users and gyms
    useEffect(() => {
        const fetchSuggestions = async () => {
            if (!user) return;

            try {
                // Get current user's data first
                const currentUserDoc = await getDoc(doc(firestore, 'users', user.uid));
                const currentUserData = currentUserDoc.data();
                const following = currentUserData?.following || [];
                const favorites = currentUserData?.favorites || [];

                // Fetch suggested users
                const usersRef = collection(firestore, 'users');
                const usersQuery = query(
                    usersRef,
                    limit(10) // Fetch more users to filter from
                );
                const userSnapshot = await getDocs(usersQuery);
                const users = userSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(u => 
                        u.id !== user.uid && // Not current user
                        !following.includes(u.id) && // Not already following
                        u.displayName // Has a display name
                    );

                // Randomly select 5 users
                const shuffled = users.sort(() => 0.5 - Math.random());
                setSuggestedUsers(shuffled.slice(0, 5));

                // Fetch suggested gyms
                const gymsRef = collection(firestore, 'locations');
                const gymsQuery = query(gymsRef, limit(10));
                const gymSnapshot = await getDocs(gymsQuery);
                const gyms = gymSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(gym => !favorites.includes(gym.id));

                setSuggestedGyms(gyms.slice(0, 3));
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        };

        fetchSuggestions();
    }, [user]);

    // Initial fetch
    useEffect(() => {
        if (user) {
            fetchActivities();
        }
        return () => {
            isMounted = false;
        };
    }, [user]);

    const handleSuggestionsChange = ({ users, gyms }) => {
        if (users) setSuggestedUsers(users);
        if (gyms) setSuggestedGyms(gyms);
    };

    return (
        <NotificationsLayout>
            <LeftSidebar />
            <NotificationsContent>
                <div className="notifications-header">
                    <h2>Notifications</h2>
                </div>
                <div className="notifications-list">
                    {activities.map((activity, index) => (
                        <NotificationItem
                            key={activity.id}
                            ref={index === activities.length - 1 ? lastElementRef : null}
                            type={activity.type}
                            data={activity}
                            gymId={activity.details?.gymId}
                            gymName={activity.details?.gymName}
                            attendees={activity.details?.attendees}
                        />
                    ))}
                    {loading && <div className="loading">Loading...</div>}
                    {!loading && !hasMore && activities.length > 0 && (
                        <div className="no-more">No more notifications</div>
                    )}
                    {!loading && activities.length === 0 && (
                        <div className="no-activities">No notifications yet</div>
                    )}
                </div>
            </NotificationsContent>
            <RightSidebar 
                suggestedUsers={suggestedUsers}
                suggestedGyms={suggestedGyms}
                onSuggestionsChange={handleSuggestionsChange}
            />
        </NotificationsLayout>
    );
};

export default Notifications;
