import styled from 'styled-components';
import { LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from './layout/Sidebar.styles';

export const GymInfoContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  transition: all 0.3s ease;
  background-color: ${({ theme }) => theme.colors.background.primary};
  min-height: 100vh;
  overflow-y: auto;
  padding-bottom: calc(60px + ${({ theme }) => theme.spacing(2)});

  @media (max-width: 768px) {
    padding: 0;
    padding-bottom: calc(60px + ${({ theme }) => theme.spacing(4)});
  }
`;

export const GymInfoCard = styled.div`
  background: ${({ theme }) => theme.colors.background.paper};
  padding: ${({ theme }) => theme.spacing(2.5)};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  width: 100%;
  margin-top: 20px;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 0;
    border-radius: 0;
    padding: ${({ theme }) => theme.spacing(2)};
  }
`;

export const TopGymInfoCard = styled(GymInfoCard)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;

  @media (max-width: 768px) {
    margin-top: 0;
    border-radius: 0;
  }
`;

export const GymBanner = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
  margin-bottom: 0;
  background: ${({ theme }) => theme.colors.background.paper};
  
  // Improved placeholder pattern when no image
  background-image: ${({ theme }) => `
    linear-gradient(
      45deg,
      ${theme.colors.background.paper} 25%,
      ${theme.colors.background.secondary} 25%,
      ${theme.colors.background.secondary} 50%,
      ${theme.colors.background.paper} 50%,
      ${theme.colors.background.paper} 75%,
      ${theme.colors.background.secondary} 75%
    )
  `};
  background-size: 8px 8px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to bottom, transparent, ${({ theme }) => theme.colors.background.primary});
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    height: 200px;
  }
`;

export const TopHalf = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2.5)};
  margin-top: -120px;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
    margin-top: -90px;
    padding: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Section = styled.div`
  flex: 1;
  justify-content: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const CenterSection = styled(Section)`
  display: flex;
  justify-content: center;
`;

export const RightSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
  text-align: right;
  margin-right: 20px;
  font-size: 15px;

  @media (max-width: 768px) {
    margin-right: 0;
    align-items: center;
    gap: 8px;
  }
`;

export const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  margin-right: 10px;
`;

export const OpenMatStatusContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.background.paper};
  padding: 6px 12px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: auto;
  }
`;

export const ActionButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  color: ${({ theme }) => theme.colors.text.primary};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.secondary};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }

  svg {
    font-size: 1.2em;
    color: ${({ theme }) => theme.colors.primary.main};
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

export const GymLogoPlaceholder = styled.div`
  width: 240px;
  height: 240px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 4px solid ${({ theme }) => theme.colors.background.paper};
  z-index: 2;
  margin-bottom: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    width: 180px;
    height: 180px;
    margin-bottom: 8px;
  }
`;

export const BottomHalf = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px 20px;
  background: ${({ theme }) => theme.colors.background.primary};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 0 16px 16px;
  }
`;

export const GymName = styled.h2`
  font-size: 2.8em;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 16px 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2em;
    margin: 12px 0;
  }
`;

export const FavoriteContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const FavoriteButton = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 12px;
    border-radius: 20px;
    background: transparent;
    cursor: default;

    div {
        cursor: pointer;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &:hover {
            opacity: 0.8;
        }
    }

    span {
        cursor: pointer;
        padding: 4px;
        font-size: 1.1rem;
        
        &:hover {
            text-decoration: underline;
        }
    }

    .star-icon {
        font-size: 3rem;
        color: #FFD700;
    }
`;

export const OpenMatStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
`;

export const StatusIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ isOpen }) => (isOpen ? '#4CAF50' : '#f44336')};
  box-shadow: 0 0 10px ${({ isOpen }) => (isOpen ? '#4CAF50' : '#f44336')};
`;

export const UploadLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(1.25)};
  color: ${({ theme }) => theme.colors.text.secondary};
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const CalendarContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(2.5)} 0;
  padding: ${({ theme }) => theme.spacing(1.25)};
  background: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.shadows.small};

  // Target FullCalendar's date text
  .fc-toolbar-title {
    @media (max-width: 430px) {
      font-size: 1.2em !important;
    }
  }

  // Ensure buttons stay visible
  .fc-toolbar {
    @media (max-width: 430px) {
      gap: 8px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .fc-header-toolbar {
    @media (max-width: 430px) {
      margin-bottom: 8px !important;
      padding: 8px 4px;
    }
  }
`;

export const ScheduleSection = styled.div`
  text-align: left;
  padding: ${({ theme }) => theme.spacing(2)};

  h3 {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: ${({ theme }) => theme.spacing(1)} 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    gap: ${({ theme }) => theme.spacing(2)};

    &:last-child {
      border-bottom: none;
    }
  }

  .day {
    font-weight: 500;
    min-width: 100px;
    padding-right: ${({ theme }) => theme.spacing(1)};
  }

  .hours {
    color: ${({ theme }) => theme.colors.text.secondary};
    text-align: right;
    flex: 1;
    word-break: keep-all;
    white-space: nowrap;
    letter-spacing: -0.2px;
  }

  @media (max-width: 480px) {
    li {
      padding: ${({ theme }) => theme.spacing(1.5)} 0;
    }

    .day {
      min-width: 85px;
      font-size: 0.95em;
    }

    .hours {
      font-size: 0.85em;
      letter-spacing: -0.3px;
    }
  }
`;

export const ContactSection = styled.div`
  text-align: left;
  padding: ${({ theme }) => theme.spacing(2)};

  h3 {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  p {
    margin: ${({ theme }) => theme.spacing(1)} 0;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  a {
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ClaimGymModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ClaimGymModal = styled.div`
  background: ${({ theme }) => theme.colors.background.paper};
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.shadows.large};
  max-width: 500px;
  width: 90%;
`;

export const ClaimGymButton = styled(ActionButton)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme, cancel }) => 
    cancel ? theme.colors.error.main : theme.colors.primary.main};

  &:hover {
    background-color: ${({ theme, cancel }) => 
      cancel ? theme.colors.error.dark : theme.colors.primary.dark};
  }
`;

export const EventLengthAlertOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const EventLengthAlertModal = styled.div`
  background: ${({ theme }) => theme.colors.background.paper};
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.shadows.large};
  max-width: 500px;
  width: 90%;
  text-align: center;
`;

export const EventLengthAlertButton = styled(ActionButton)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const PromotionalTextSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2.5)};
  width: 100%;
`;

export const PromotionalTextInput = styled.textarea`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1.25)};
  margin-top: ${({ theme }) => theme.spacing(1.25)};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  resize: vertical;
  background: ${({ theme }) => theme.colors.background.paper};
  color: ${({ theme }) => theme.colors.text.primary};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const SaveTextButton = styled(ActionButton)`
  margin-top: ${({ theme }) => theme.spacing(1.25)};
`;

export const GymOwnerControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;

  @media (max-width: 768px) {
    width: 100%;
    align-items: stretch;
  }

  p {
    color: ${({ theme }) => theme.colors.text.primary};
    background: ${({ theme }) => theme.colors.background.paper};
    padding: 6px 12px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

export const AdminMenuContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 3;
`;

export const AdminMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background: ${({ theme }) => theme.colors.background.secondary};
    transform: scale(1.05);
  }

  svg {
    font-size: 1.2em;
  }
`;

export const AdminMenu = styled.div`
  position: absolute;
  top: 48px;
  right: 0;
  background: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-width: 200px;
  overflow: hidden;
  z-index: 3;
`;

export const AdminMenuItem = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.background.secondary};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }

  svg {
    font-size: 1.2em;
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const ReviewsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: ${({ theme }) => theme.spacing(2.5)} 0;
`;

export const ReviewCard = styled.li`
  padding: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  box-shadow: ${({ theme }) => theme.shadows.small};
`;

export const ReviewAuthor = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const ReviewText = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const ReviewRating = styled.div`
  color: gold;
  font-size: 1.2em;
`;