import { createContext, useState, useCallback } from 'react';

export const MapContext = createContext();

export const MapProvider = ({ children }) => {
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [previousPosition, setPreviousPosition] = useState(null);
    const [previousSelectedPlace, setPreviousSelectedPlace] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    const updateCurrentLocation = useCallback(async () => {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject(new Error('Geolocation is not supported'));
                return;
            }

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const newLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    setCurrentLocation(newLocation);
                    setSelectedPosition(newLocation);
                    resolve(newLocation);
                },
                (error) => {
                    console.error('Geolocation error:', error);
                    reject(error);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            );
        });
    }, []);

    return (
        <MapContext.Provider value={{
            selectedPosition,
            setSelectedPosition,
            selectedPlace,
            setSelectedPlace,
            previousPosition,
            setPreviousPosition,
            previousSelectedPlace,
            setPreviousSelectedPlace,
            currentLocation,
            setCurrentLocation,
            updateCurrentLocation,
            searchResults,
            setSearchResults,
            isSearching,
            setIsSearching
        }}>
            {children}
        </MapContext.Provider>
    );
};
