import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import '../../styles/UserListModal.css';

Modal.setAppElement('#root');

const FollowingModal = ({ isOpen, onClose, following }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Following"
            className="user-list-modal"
            overlayClassName="user-list-modal-overlay"
        >
            <h2>Following</h2>
            <div className="user-grid">
                {following && following.length > 0 ? (
                    following.map((followedUser) => (
                        <div key={followedUser.uid} className="user-item">
                            <Link to={`/@${followedUser.username}`} className="user-info">
                                <img 
                                    src={followedUser.photoURL || '/default-avatar.png'} 
                                    alt={followedUser.displayName} 
                                    className="user-avatar"
                                />
                                <div className="user-details">
                                    <h3>{followedUser.displayName}</h3>
                                    <p>{followedUser.beltRank || 'No belt rank'}</p>
                                </div>
                            </Link>
                        </div>
                    ))
                ) : (
                    <p>Not following anyone yet</p>
                )}
            </div>
            <div className="form-actions">
                <button onClick={onClose}>Close</button>
            </div>
        </Modal>
    );
};

export default FollowingModal; 