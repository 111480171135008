import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore, storage } from '../../services/firebase';
import { useRole } from '../../hooks/useRole';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, limit, arrayUnion, arrayRemove, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getUserData } from '../../services/userProfileService';
import { logFollowActivity, logUnfollowActivity } from '../../utils/logActivity';
import { CREATOR_TYPES } from '../../services/creatorContentService';
import { generateShortId } from '../../utils/shortId';
import { 
    UserProfileLayout,
    MainContent,
    UserBodyContent,
    UserProfileContainer,
    UserAboutCard,
    ProfileSection,
    ProfilePicContainer,
    ProfilePic,
    ProfileInfo,
    ProfileHeader,
    HeaderActions,
    IconActionButton,
    StatsRow,
    StatItem,
    BioSection,
    ActionButton
} from '../../styles/UserProfile.styles';
import {
    Settings as SettingsIcon,
    Edit as EditIcon,
    Dashboard as DashboardIcon,
    Analytics as AnalyticsIcon,
    Help as HelpIcon
} from '@mui/icons-material';
import { 
    Menu, 
    MenuItem, 
    IconButton, 
    Button, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import moment from 'moment';
import { POST_VISIBILITY } from '../../constants/visibility';
import CommentForm from '../forms/CommentForm';
import CommentsSection from './CommentsSection';
import LeftSidebar from '../layout/LeftSidebar';
import RightSidebar from '../layout/RightSidebar';
import EventsModal from './EventsModal';
import FollowingModal from './FollowingModal';
import FollowersModal from './FollowersModal';
import FavoriteGymsModal from './FavoriteGymsModal';
import EditProfileModal from './EditProfileModal';
import EventLengthAlert from './EventLengthAlert';
import { Helmet } from 'react-helmet-async';

const UserProfile = () => {
    const { username, userId, commentId } = useParams();
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const { isAdmin, isCreator: roleIsCreator, loading: roleLoading } = useRole();
    const [profile, setProfile] = useState({});
    const [profileId, setProfileId] = useState(null);
    const [futureEvents, setFutureEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const [following, setFollowing] = useState([]);
    const [followers, setFollowers] = useState([]);
    const [favoriteGyms, setFavoriteGyms] = useState([]);
    const [isFollowing, setIsFollowing] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    const [showEventsModal, setShowEventsModal] = useState(false);
    const [showFollowingModal, setShowFollowingModal] = useState(false);
    const [showFollowersModal, setShowFollowersModal] = useState(false);
    const [showFavoriteGymsModal, setShowFavoriteGymsModal] = useState(false);
    const [showEventLengthAlert, setShowEventLengthAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [dataLoading, setDataLoading] = useState(true);
    const [isCreator, setIsCreator] = useState(false);
    const [openNewPost, setOpenNewPost] = useState(false);
    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
    const [suggestedUsers, setSuggestedUsers] = useState([]);
    const [suggestedGyms, setSuggestedGyms] = useState([]);
    const [targetCommentId, setTargetCommentId] = useState(commentId);
    const [currentPost, setCurrentPost] = useState(null);
    const [profilePicError, setProfilePicError] = useState(false);
    const defaultAvatarPath = '/assets/default-avatar.png';

    const MAX_FILE_SIZE = 4 * 1024 * 1024 * 1024; // 4GB
    const SUPPORTED_VIDEO_FORMATS = ['video/mp4', 'video/avi', 'video/quicktime', 'video/x-ms-wmv'];
    const MAX_VIDEO_DURATION = 3600; // 1 hour in seconds

    useEffect(() => {
        let isMounted = true;

        const fetchProfile = async () => {
            // Wait for auth and role loading to complete
            if (loading || roleLoading) return;

            try {
                setDataLoading(true);
                let currentUserId = null;
                let userData = null;

                // Case 1: Username provided in URL
                if (username) {
                    const usersRef = collection(firestore, 'users');
                    const usernameQuery = query(usersRef, where('username', '==', username.toLowerCase()));
                    const usernameSnapshot = await getDocs(usernameQuery);

                    if (!usernameSnapshot.empty) {
                        currentUserId = usernameSnapshot.docs[0].id;
                        userData = { id: currentUserId, ...usernameSnapshot.docs[0].data() };
                    }
                }

                // Case 2: UserId provided in URL
                if (userId && !userData) {
                    currentUserId = userId;
                    const userDoc = await getDoc(doc(firestore, 'users', userId));
                    if (userDoc.exists()) {
                        userData = { id: userId, ...userDoc.data() };
                    }
                }

                // Case 3: No identifier provided, use current user
                if (!currentUserId && user) {
                    currentUserId = user.uid;
                    const userDoc = await getDoc(doc(firestore, 'users', user.uid));
                    if (userDoc.exists()) {
                        userData = { id: user.uid, ...userDoc.data() };
                        // Only redirect if we're on /profile with no ID
                        if (userData.username && !username && !userId) {
                            navigate(`/${userData.username}`, { replace: true });
                            return;
                        }
                    }
                }

                // If no user found, redirect to 404
                if (!userData) {
                    console.warn("User not found");
                    navigate('/404');
                    return;
                }

                if (isMounted) {
                    setProfileId(currentUserId);
                    setProfile(userData);
                    
                    // Set creator status directly from user data
                    setIsCreator(!!userData.role?.creator);

                    // Check if the current user is following this profile
                    if (user && userData.followers && userData.followers.includes(user.uid)) {
                        setIsFollowing(true);
                    }

                    // Check if the user has seen the edit profile modal
                    if (user?.uid === currentUserId && !localStorage.getItem(`editProfileSeen_${currentUserId}`)) {
                        setEditProfile(true);
                        localStorage.setItem(`editProfileSeen_${currentUserId}`, 'true');
                    }

                    await Promise.all([
                        fetchEvents(userData),
                        fetchFollowData(userData),
                        fetchFavoriteGyms(userData)
                    ]);
                }

                setDataLoading(false);
            } catch (error) {
                console.error("Error fetching profile:", error);
                setDataLoading(false);
                navigate('/404');
            }
        };

        const fetchEvents = async (userData) => {
            if (!userData?.attending || !Array.isArray(userData.attending)) {
                console.warn('No attending events found');
                return;
            }

            const now = new Date();
            const oneWeekAgo = moment().subtract(1, 'weeks').toDate();

            const future = userData.attending.filter(event => 
                new Date(event.start.seconds * 1000) > now
            );
            const past = userData.attending.filter(event => 
                new Date(event.end.seconds * 1000) < now && 
                new Date(event.end.seconds * 1000) > oneWeekAgo
            );

            const futureEventsWithLocation = await Promise.all(future.map(async (event) => {
                if (!event.locationName) {
                    const locationDoc = await getDoc(doc(firestore, 'locations', event.locationId));
                    if (locationDoc.exists()) {
                        event.locationName = locationDoc.data().name;
                    }
                }
                return event;
            }));

            const pastEventsWithLocation = await Promise.all(past.map(async (event) => {
                if (!event.locationName) {
                    const locationDoc = await getDoc(doc(firestore, 'locations', event.locationId));
                    if (locationDoc.exists()) {
                        event.locationName = locationDoc.data().name;
                    }
                }
                return event;
            }));

            setFutureEvents(futureEventsWithLocation);
            setPastEvents(pastEventsWithLocation);
        };

        const fetchFollowData = async (userData) => {
            if (Array.isArray(userData.following)) {
                const followingList = await Promise.all(
                    userData.following.map(async (followId) => {
                        const followDoc = await getDoc(doc(firestore, 'users', followId));
                        return followDoc.exists() ? { uid: followDoc.id, ...followDoc.data() } : null;
                    })
                );
                setFollowing(followingList.filter(follow => follow !== null));
            }

            if (Array.isArray(userData.followers)) {
                const followersList = await Promise.all(
                    userData.followers.map(async (followerId) => {
                        const followerDoc = await getDoc(doc(firestore, 'users', followerId));
                        return followerDoc.exists() ? { uid: followerDoc.id, ...followerDoc.data() } : null;
                    })
                );
                setFollowers(followersList.filter(follower => follower !== null));
            }
        };

        const fetchFavoriteGyms = async (userData) => {
            if (Array.isArray(userData.favorites)) {
                const favoriteGymsData = await Promise.all(
                    userData.favorites.map(async (gymId) => {
                        const gymDoc = await getDoc(doc(firestore, 'locations', gymId));
                        return gymDoc.exists() ? { id: gymId, ...gymDoc.data() } : null;
                    })
                );
                setFavoriteGyms(favoriteGymsData.filter(gym => gym !== null));
            }
        };

        fetchProfile();

        return () => {
            isMounted = false;
        };
    }, [loading, user, username, userId, navigate, roleIsCreator, roleLoading]);

    useEffect(() => {
        const fetchSuggestions = async () => {
            if (!user) return;

            try {
                // Fetch suggested users
                const usersRef = collection(firestore, 'users');
                const usersQuery = query(
                    usersRef,
                    where('role.creator', '==', true),
                    limit(5)
                );
                const userSnapshot = await getDocs(usersQuery);
                const users = userSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(u => u.id !== user.uid && !following.some(f => f.uid === u.id));

                setSuggestedUsers(users);

                // Fetch suggested gyms
                const gymsRef = collection(firestore, 'locations');
                const gymsQuery = query(gymsRef, limit(3));
                const gymSnapshot = await getDocs(gymsQuery);
                const gyms = gymSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(gym => !favoriteGyms.some(f => f.id === gym.id));

                setSuggestedGyms(gyms);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        };

        fetchSuggestions();
    }, [user, following, favoriteGyms]);

    useEffect(() => {
        if (commentId) {
            setTargetCommentId(commentId);
            // Wait for comments to load
            setTimeout(() => {
                const commentElement = document.getElementById(`comment-${commentId}`);
                if (commentElement) {
                    commentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    commentElement.classList.add('highlighted');
                    setTimeout(() => commentElement.classList.remove('highlighted'), 3000);
                }
            }, 500); // Give time for comments to load
        }
    }, [commentId, profile]);

    useEffect(() => {
        const fetchPost = async () => {
            if (commentId && profileId) {
                try {
                    const commentRef = collection(firestore, `users/${profileId}/comments`);
                    const q = query(commentRef, where('shortId', '==', commentId));
                    const querySnapshot = await getDocs(q);
                    
                    if (!querySnapshot.empty) {
                        const postData = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
                        setCurrentPost(postData);
                    }
                } catch (error) {
                    console.error('Error fetching post:', error);
                }
            }
        };
        
        fetchPost();
    }, [commentId, profileId]);

    const handleFollow = async () => {
        if (!user || user.uid === profileId) return;

        try {
            const userRef = doc(firestore, 'users', user.uid);
            const followedUserRef = doc(firestore, 'users', profileId);

            // Update the current user's following field
            await updateDoc(userRef, {
                following: arrayUnion(profileId)
            });

            // Update the followed user's followers field
            await updateDoc(followedUserRef, {
                followers: arrayUnion(user.uid)
            });

            setIsFollowing(true);

            // Log activity
            const followedUserDoc = await getDoc(followedUserRef);
            const followedUserData = followedUserDoc.data();
            await logFollowActivity(user.uid, user.displayName, profileId, followedUserData.displayName, user.photoURL);
        } catch (error) {
            console.error('Error following user:', error.message);
        }
    };

    const handleUnfollow = async () => {
        if (!user || user.uid === profileId) return;

        try {
            const userRef = doc(firestore, 'users', user.uid);
            const followedUserRef = doc(firestore, 'users', profileId);

            await updateDoc(userRef, {
                following: arrayRemove(profileId)
            });

            await updateDoc(followedUserRef, {
                followers: arrayRemove(user.uid)
            });

            setIsFollowing(false);

            // Log activity
            const followedUserDoc = await getDoc(followedUserRef);
            const followedUserData = followedUserDoc.data();
            await logUnfollowActivity(user.uid, user.displayName, profileId, followedUserData.displayName, user.photoURL);
        } catch (error) {
            console.error('Error unfollowing user:', error.message);
        }
    };

    const handleSaveProfile = async (updatedProfile) => {
        if (!user) return;

        try {
            const userRef = doc(firestore, 'users', user.uid);
            await updateDoc(userRef, updatedProfile);
            setProfile(updatedProfile);
            setEditProfile(false);
        } catch (error) {
            console.error('Error saving profile:', error.message);
        }
    };

    const handleStatsClick = (type) => {
        switch (type) {
            case 'events':
                setShowEventsModal(true);
                break;
            case 'following':
                setShowFollowingModal(true);
                break;
            case 'followers':
                setShowFollowersModal(true);
                break;
            case 'gyms':
                setShowFavoriteGymsModal(true);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (commentData) => {
        if (!user) return;
        
        try {
            const shortId = generateShortId();
            const commentRef = collection(firestore, `users/${profileId}/comments`);
            const newComment = {
                text: commentData.text,
                videos: commentData.videos || [],
                userId: user.uid,
                userName: user.displayName,
                profilePicUrl: user.photoURL,
                createdAt: new Date(),
                likes: [],
                replies: [],
                visibility: commentData.visibility || POST_VISIBILITY.PUBLIC,
                shortId
            };
            
            const docRef = await addDoc(commentRef, newComment);
            
            // Update the URL with the new comment ID without reloading the page
            const newUrl = profile.username ? 
                `/${profile.username}/${shortId}` : 
                `/profile/${profileId}/${shortId}`;
            window.history.pushState({}, '', newUrl);
            setTargetCommentId(shortId);
            
            setOpenNewPost(false);
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };

    const handleUrlChange = (url) => {
        // This function will be called when the URL is changed in the CommentForm
        // You can add any URL validation or processing here if needed
        console.log('URL changed:', url);
    };

    const validateVideoFile = async (file) => {
        if (!file) return null;

        // Check file size
        if (file.size > MAX_FILE_SIZE) {
            throw new Error('Video file must be less than 4GB');
        }

        // Check file format
        if (!SUPPORTED_VIDEO_FORMATS.includes(file.type)) {
            throw new Error('Unsupported video format. Please use MP4, AVI, MOV, or WMV');
        }

        // Check video duration
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                if (video.duration > MAX_VIDEO_DURATION) {
                    reject(new Error('Video must be less than 1 hour'));
                }
                resolve(file);
            };

            video.onerror = () => {
                reject(new Error('Error validating video file'));
            };

            video.src = URL.createObjectURL(file);
        });
    };

    const uploadToFirebase = async (file, type) => {
        if (!file || !user?.uid) return;

        const timestamp = Date.now();
        const storageRef = ref(storage, `${type}/${user.uid}/${timestamp}_${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                    console.error('Upload error:', error);
                    reject(error);
                },
                async () => {
                    try {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        resolve(downloadURL);
                    } catch (error) {
                        console.error('Error getting download URL:', error);
                        reject(error);
                    }
                }
            );
        });
    };

    const handleMediaUpload = async (event, type = 'image') => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            if (type === 'video') {
                await validateVideoFile(file);
            }

            // Upload to Firebase Storage
            const downloadURL = await uploadToFirebase(file, type);
        } catch (error) {
            console.error('Upload error:', error);
        }
    };

    const handleSettingsClick = (event) => {
        setSettingsAnchorEl(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setSettingsAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleProfilePicError = (e) => {
        if (!profilePicError) {
            console.warn('Profile picture failed to load, using fallback');
            setProfilePicError(true);
        }
    };

    if (dataLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Helmet>
                {currentPost ? (
                    <>
                        <title>{`${profile.displayName || 'User'}'s Post - OpenMat App`}</title>
                        <meta name="description" content={currentPost.text || ''} />
                        
                        {/* Open Graph Meta Tags */}
                        <meta property="og:title" content={`${profile.displayName || 'User'}'s Post - OpenMat App`} />
                        <meta property="og:description" content={currentPost.text || ''} />
                        {currentPost.videos?.[0] && (
                            <>
                                <meta property="og:video" content={currentPost.videos[0]} />
                                <meta property="og:video:type" content="video/mp4" />
                            </>
                        )}
                        {currentPost.images?.[0] && (
                            <meta property="og:image" content={currentPost.images[0]} />
                        )}
                        <meta property="og:type" content="article" />
                        <meta property="og:url" content={window.location.href} />
                        
                        {/* Twitter Card Meta Tags */}
                        <meta name="twitter:card" content={currentPost.videos?.[0] ? "player" : "summary_large_image"} />
                        <meta name="twitter:title" content={`${profile.displayName || 'User'}'s Post - OpenMat App`} />
                        <meta name="twitter:description" content={currentPost.text || ''} />
                        {currentPost.videos?.[0] && (
                            <>
                                <meta name="twitter:player" content={currentPost.videos[0]} />
                                <meta name="twitter:player:width" content="1280" />
                                <meta name="twitter:player:height" content="720" />
                            </>
                        )}
                        {currentPost.images?.[0] && (
                            <meta name="twitter:image" content={currentPost.images[0]} />
                        )}
                    </>
                ) : (
                    <>
                        <title>{`${profile.displayName || 'User'} - OpenMat App`}</title>
                        <meta name="description" content={profile.bio || `Check out ${profile.displayName || 'this user'}'s profile on OpenMat App`} />
                        
                        {/* Open Graph Meta Tags */}
                        <meta property="og:title" content={`${profile.displayName || 'User'} - OpenMat App`} />
                        <meta property="og:description" content={profile.bio || `Check out ${profile.displayName || 'this user'}'s profile on OpenMat App`} />
                        <meta property="og:image" content={profilePicError ? defaultAvatarPath : (profile.photoURL || defaultAvatarPath)} />
                        <meta property="og:type" content="profile" />
                        <meta property="og:url" content={window.location.href} />
                        
                        {/* Twitter Card Meta Tags */}
                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:title" content={`${profile.displayName || 'User'} - OpenMat App`} />
                        <meta name="twitter:description" content={profile.bio || `Check out ${profile.displayName || 'this user'}'s profile on OpenMat App`} />
                        <meta name="twitter:image" content={profilePicError ? defaultAvatarPath : (profile.photoURL || defaultAvatarPath)} />
                    </>
                )}
            </Helmet>
            <UserProfileLayout>
                <LeftSidebar />

                <MainContent>
                    <UserBodyContent>
                        <UserProfileContainer>
                            <UserAboutCard>
                                <ProfileSection>
                                    <ProfilePicContainer>
                                        <ProfilePic 
                                            src={profilePicError ? defaultAvatarPath : (profile.photoURL || defaultAvatarPath)}
                                            alt={profile.displayName || 'User profile'} 
                                            onError={handleProfilePicError}
                                        />
                                    </ProfilePicContainer>
                                    <ProfileInfo>
                                        <ProfileHeader>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <h2>{profile.displayName}</h2>
                                                <span style={{ 
                                                    color: '#888',
                                                    fontSize: '16px',
                                                    fontWeight: 600 
                                                }}>
                                                    {profile.beltRank || 'White'} Belt
                                                </span>
                                            </div>
                                            <HeaderActions>
                                                {user && user.uid === profileId && (
                                                    <>
                                                        <IconActionButton onClick={() => setEditProfile(true)}>
                                                            <EditIcon />
                                                        </IconActionButton>
                                                        <IconButton
                                                            onClick={handleSettingsClick}
                                                            sx={{ color: '#fff', padding: '8px' }}
                                                        >
                                                            <SettingsIcon />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={settingsAnchorEl}
                                                            open={Boolean(settingsAnchorEl)}
                                                            onClose={handleSettingsClose}
                                                            PaperProps={{
                                                                sx: {
                                                                    backgroundColor: '#222',
                                                                    color: '#fff',
                                                                    '& .MuiMenuItem-root': {
                                                                        fontSize: '14px',
                                                                        '&:hover': {
                                                                            backgroundColor: 'rgba(255, 255, 255, 0.1)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem onClick={() => {
                                                                handleSettingsClose();
                                                                navigate('/settings');
                                                            }}>
                                                                <SettingsIcon sx={{ mr: 1 }} /> Settings
                                                            </MenuItem>
                                                            {isCreator && [
                                                                <MenuItem key="dashboard" onClick={() => {
                                                                    handleSettingsClose();
                                                                    navigate('/creator/dashboard');
                                                                }}>
                                                                    <DashboardIcon sx={{ mr: 1 }} /> Creator Dashboard
                                                                </MenuItem>,
                                                                <MenuItem key="analytics" onClick={() => {
                                                                    handleSettingsClose();
                                                                    navigate('/creator/analytics');
                                                                }}>
                                                                    <AnalyticsIcon sx={{ mr: 1 }} /> Analytics
                                                                </MenuItem>
                                                            ]}
                                                            <MenuItem onClick={() => {
                                                                handleSettingsClose();
                                                                navigate('/help');
                                                            }}>
                                                                <HelpIcon sx={{ mr: 1 }} /> Help
                                                            </MenuItem>
                                                        </Menu>
                                                    </>
                                                )}
                                                {user && user.uid !== profileId && (
                                                    <ActionButton
                                                        data-following={isFollowing}
                                                        onClick={isFollowing ? handleUnfollow : handleFollow}
                                                    >
                                                        {isFollowing ? 'Following' : 'Follow'}
                                                    </ActionButton>
                                                )}
                                            </HeaderActions>
                                        </ProfileHeader>

                                        <StatsRow>
                                            <StatItem onClick={() => handleStatsClick('events')}>
                                                <span>{futureEvents.length}</span> events
                                            </StatItem>
                                            <StatItem onClick={() => handleStatsClick('followers')}>
                                                <span>{followers.length}</span> followers
                                            </StatItem>
                                            <StatItem onClick={() => handleStatsClick('following')}>
                                                <span>{following.length}</span> following
                                            </StatItem>
                                            <StatItem onClick={() => handleStatsClick('gyms')}>
                                                <span>{favoriteGyms.length}</span> gyms
                                            </StatItem>
                                        </StatsRow>

                                        <BioSection>
                                            <p>{profile.location && `${profile.location}`}</p>
                                            <p>{profile.bio || 'No bio yet'}</p>
                                        </BioSection>

                                        {openNewPost && (
                                            <CommentForm 
                                                open={true}
                                                onClose={() => setOpenNewPost(false)}
                                                onSubmit={handleSubmit}
                                                onUrlChange={handleUrlChange}
                                                isCreator={!!profile.role?.creator}
                                            />
                                        )}
                                    </ProfileInfo>
                                </ProfileSection>
                            </UserAboutCard>

                            <CommentsSection
                                commentType="user"
                                itemId={profileId}
                                userId={profileId}
                                collectionType={`users/${profileId}/comments`}
                                openNewPost={openNewPost}
                                setOpenNewPost={setOpenNewPost}
                            >
                            </CommentsSection>
                        </UserProfileContainer>
                    </UserBodyContent>
                </MainContent>

                <RightSidebar 
                    suggestedUsers={suggestedUsers}
                    suggestedGyms={suggestedGyms}
                />

                {showEventsModal && (
                    <EventsModal
                        isOpen={showEventsModal}
                        events={[...futureEvents, ...pastEvents]}
                        onClose={() => setShowEventsModal(false)}
                    />
                )}

                {showFollowingModal && (
                    <FollowingModal
                        isOpen={showFollowingModal}
                        following={following}
                        onClose={() => setShowFollowingModal(false)}
                    />
                )}

                {showFollowersModal && (
                    <FollowersModal
                        isOpen={showFollowersModal}
                        followers={followers}
                        onClose={() => setShowFollowersModal(false)}
                    />
                )}

                {showFavoriteGymsModal && (
                    <FavoriteGymsModal
                        isOpen={showFavoriteGymsModal}
                        favoriteGyms={favoriteGyms}
                        onClose={() => setShowFavoriteGymsModal(false)}
                    />
                )}

                {editProfile && (
                    <EditProfileModal
                        isOpen={editProfile}
                        profile={profile}
                        onClose={() => setEditProfile(false)}
                        onSave={(updatedProfile) => {
                            handleSaveProfile(updatedProfile);
                            setEditProfile(false);
                        }}
                    />
                )}

                {showEventLengthAlert && (
                    <EventLengthAlert
                        message={alertMessage}
                        onClose={() => setShowEventLengthAlert(false)}
                    />
                )}
            </UserProfileLayout>
        </>
    );
};

export default UserProfile;