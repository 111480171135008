import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useParams, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useAppLogic } from './hooks/useAppLogic';
import Notifications from './components/specific/Notifications';
import Login from './pages/Login';
import GymInfo from './components/specific/GymInfo';
import UserProfile from './components/specific/UserProfile.js';
import About from './components/specific/About.js';
import ContactForm from './components/specific/ContactForm.js';
import EventInfo from './components/specific/EventInfo.js';
import MapContainer from './components/specific/MapContainer.js';
import Feed from './components/specific/Feed';
// import Sidebar from './components/specific/Sidebar.js';
import { MapProvider } from './context/MapContext';
import Logo from './components/common/Logo.js';
import LocationDeniedModal from './components/specific/LocationDeniedModal.js';
import WelcomeModal from './components/specific/WelcomeModal';
import './styles/styles.css';
import './styles/common/Logo.css';
import { UserProvider } from "./context/UserContext";
import AdminDashboard from './components/admin/AdminDashboard';
import ManageRoles from "./components/admin/ManageRoles";
import UserEventInfo from './components/specific/UserEventInfo';
import { useAuth } from './hooks/useAuth';
import SpotifyCallback from './pages/SpotifyCallback';
import Footer from './components/layout/Footer';
import { useRole } from './hooks/useRole';
import { CreatorDashboard } from './features/creator/pages/CreatorDashboard';
import { ThemeProvider } from './shared/context/ThemeContext';
import { GlobalStyles } from './shared/styles/GlobalStyles';
import { StyledApp, AppHeader, AppLink } from './styles/App.styles';
import { getUserData } from './services/userProfileService';

const PrivateRoute = ({ children }) => {
    const { user, showLoginModal, setShowLoginModal, requireAuth } = useAuth();
    const location = useLocation();

    useEffect(() => {
        if (!user) {
            requireAuth(location.pathname);
        }
    }, [user, location.pathname, requireAuth]);

    if (!user) {
        return (
            <WelcomeModal 
                open={showLoginModal}
                onClose={() => setShowLoginModal(false)}
                isLoginRequired={true}
                intendedPath={location.pathname}
            />
        );
    }

    return children;
};

const CreatorRoute = ({ children }) => {
    const { isCreator, loading } = useRole();
    const { user, showLoginModal, setShowLoginModal, requireAuth } = useAuth();
    const location = useLocation();

    useEffect(() => {
        if (!user) {
            requireAuth(location.pathname);
        }
    }, [user, location.pathname, requireAuth]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return (
            <WelcomeModal 
                open={showLoginModal}
                onClose={() => setShowLoginModal(false)}
                isLoginRequired={true}
                intendedPath={location.pathname}
            />
        );
    }

    if (!isCreator) {
        return <Navigate to="/" />;
    }

    return children;
};

const PublicMapContainerWithSidebar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const { showWelcomeModal, dismissWelcomeModal } = useAppLogic();
    
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <MapProvider>
            <MapContainer isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            {showWelcomeModal && (
                <WelcomeModal 
                    open={showWelcomeModal} 
                    onClose={dismissWelcomeModal}
                    isLoginRequired={false}
                />
            )}
        </MapProvider>
    );
};

const PrivateMapContainerWithSidebar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <MapProvider>
            <MapContainer isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        </MapProvider>
    );
};

const ProfileRedirect = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const redirectToProfile = async () => {
            try {
                const userData = await getUserData(userId);
                if (!userData) {
                    navigate('/404', { replace: true });
                    return;
                }

                // If user has a username, redirect to username-based URL
                if (userData.username) {
                    navigate(`/${userData.username}`, { replace: true });
                } else {
                    // If no username, use the userId URL
                    navigate(`/profile/${userId}`, { replace: true });
                }
            } catch (error) {
                console.error('Error redirecting to profile:', error);
                navigate('/404', { replace: true });
            } finally {
                setLoading(false);
            }
        };

        redirectToProfile();
    }, [userId, navigate]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return null;
};

const App = () => {
    const {
        loading,
        isIos,
        showInstallButton,
        showIosInstallInstructions,
        showLocationDeniedModal,
        handleInstallClick,
        handleDismissIosInstallInstructions,
        handleCloseLocationDeniedModal,
    } = useAppLogic();

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <HelmetProvider>
            <ThemeProvider>
                <GlobalStyles />
                <UserProvider>
                    <Router basename="/">
                        <StyledApp>
                            <Routes>
                                <Route path="/" element={<PublicMapContainerWithSidebar />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
                                <Route path="/find-gym" element={<PrivateRoute><PrivateMapContainerWithSidebar /></PrivateRoute>} />
                                <Route path="/gym/:id" element={<PrivateRoute><GymInfo /></PrivateRoute>} />
                                <Route path="/profile/:userId/event/:eventId" element={<PrivateRoute><UserEventInfo /></PrivateRoute>} />
                                <Route path="/gym/:gymId/event/:eventId" element={<PrivateRoute><EventInfo /></PrivateRoute>} />
                                <Route path="/@:username" element={<UserProfile />} />
                                <Route path="/profile/:userId" element={<UserProfile />} />
                                <Route path="/profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
                                <Route path="/contact" element={<PrivateRoute><ContactForm /></PrivateRoute>} />
                                <Route path="/feed" element={<PrivateRoute><Feed /></PrivateRoute>} />
                                <Route path="/admin-dashboard" element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />
                                <Route path="/manage-roles" element={<PrivateRoute><ManageRoles /></PrivateRoute>} />
                                <Route path="/spotify-callback" element={<SpotifyCallback />} />
                                <Route path="/:username" element={<UserProfile />} />
                                <Route path="/profile/:userId" element={<UserProfile />} />
                                <Route path="/profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
                                <Route path="/:username/:commentId" element={<UserProfile />} />
                                <Route 
                                    path="/creator/dashboard" 
                                    element={
                                        <CreatorRoute>
                                            <CreatorDashboard />
                                        </CreatorRoute>
                                    } 
                                />
                            </Routes>
                            <Footer />
                        </StyledApp>
                        <Logo />
                        {showInstallButton && !isIos && (
                            <button id="install-button" onClick={handleInstallClick}>
                                Install App
                            </button>
                        )}
                        {isIos && showIosInstallInstructions && (
                            <div className="ios-install-instructions">
                                <p>To install this app on your iPhone/iPad, in Safari tap the Share icon<img src="/Ei-share-apple.svg" alt="Share" />and then Add to Home Screen.</p>
                                <button onClick={handleDismissIosInstallInstructions}>Close</button>
                            </div>
                        )}
                        {showLocationDeniedModal && (
                            <LocationDeniedModal onClose={handleCloseLocationDeniedModal} />
                        )}
                    </Router>
                </UserProvider>
            </ThemeProvider>
        </HelmetProvider>
    );
};

export default App;
