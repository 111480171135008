import styled, { css } from 'styled-components';

// Layout constants
export const LEFT_SIDEBAR_WIDTH = '240px';
export const RIGHT_SIDEBAR_WIDTH = '280px';

export const NavItem = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 8px;
  text-align: left;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 1200px) {
    padding: 8px;
    justify-content: center;
    
    span {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 6px;
    width: auto;
    flex: 0;
    justify-content: center;
    max-width: 48px;

    &.primary-nav {
      order: unset;
    }

    &.more-button {
      order: 5;
    }
  }
`;

export const NewPostButton = styled.button`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-40%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    background: ${({ theme }) => theme.colors.primary.main || '#007bff'};
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
    z-index: 1001;

    &:hover {
      transform: translateX(-50%) translateY(-40%) scale(1.05);
      background: ${({ theme }) => theme.colors.primary.dark || '#0056b3'};
    }

    &:active {
      transform: translateX(-50%) translateY(-40%) scale(0.95);
    }
  }
`;

export const SidebarNewPostButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.primary.main || '#007bff'};
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 8px;
  text-align: left;

  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark || '#0056b3'};
  }

  @media (max-width: 1200px) {
    padding: 8px;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 auto;
    
    span {
      display: none;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const LeftSidebar = styled.nav`
  position: sticky;
  top: 0;
  height: 100vh;
  padding: 16px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  overflow-x: hidden;
  width: ${LEFT_SIDEBAR_WIDTH};

  @media (max-width: 1200px) {
    padding: 16px 2px;
    width: 80px;
  }

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    height: auto;
    width: 100%;
    padding: 6px 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 1000;
    overflow: visible;
    gap: 8px;

    /* Add space only to the buttons directly adjacent to NewPostButton */
    & > *:nth-child(3) {  /* Notifications button */
      margin-right: 16px;
    }
    & > *:nth-child(5) {  /* Messages button */
      margin-left: 16px;
    }
  }
`;

export const RightSidebar = styled.aside`
  position: sticky;
  top: 0;
  height: 100vh;
  padding: 16px 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: all 0.3s ease;
  margin-right: auto;
  overflow-y: auto;
  max-width: ${RIGHT_SIDEBAR_WIDTH};
  width: 100%;

  @media (max-width: 1400px) {
    max-width: 240px;
    padding: 16px 2px;
  }

  @media (max-width: 1200px) {
    padding: 16px 2px;
    max-width: 80px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SuggestionsContainer = styled.div`
  padding: 8px;
  background-color: #111;
  border-radius: 12px;
  margin-bottom: 8px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
    border-radius: 0;
    margin-bottom: 0;
  }

  h3 {
    color: #fff;
    font-size: 14px;
    margin: 0 0 8px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1200px) {
    padding: 6px;
    h3 {
      display: none;
    }
  }
`;

export const SuggestionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 1200px) {
    padding: 4px 0;
  }
`;

export const SuggestionInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1;
  min-width: 0;
`;

export const SuggestionAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 1200px) {
    width: 40px;
    height: 40px;
  }
`;

export const SuggestionDetails = styled.div`
  min-width: 0;

  h4 {
    color: #fff;
    font-size: 13px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    color: #999;
    font-size: 11px;
    margin: 2px 0 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const SuggestionButton = styled.button`
  background: none;
  border: 1px solid #333;
  color: #fff;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  margin-left: 8px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const MoreMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: all 0.3s ease;
  overflow: hidden;
  max-height: ${props => props.$isOpen ? '100vh' : '0'};
  opacity: ${props => props.$isOpen ? '1' : '0'};
  
  @media (max-width: 768px) {
    position: fixed;
    bottom: 64px; // Adjust based on your bottom bar height
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(10px);
    padding: ${props => props.$isOpen ? '16px' : '0'};
    border-top: ${props => props.$isOpen ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'};
    
    ${NavItem} {
      display: flex !important;
      max-width: none;
      justify-content: flex-start;
      padding: 12px;
      
      span {
        display: block;
      }
    }
  }
`; 