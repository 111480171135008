import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    RightSidebar as StyledRightSidebar,
    SuggestionsContainer,
    SuggestionItem,
    SuggestionInfo,
    SuggestionAvatar,
    SuggestionDetails,
    SuggestionButton,
} from '../../styles/layout/Sidebar.styles';

const RightSidebar = ({ suggestedUsers = [], suggestedGyms = [] }) => {
    const navigate = useNavigate();

    return (
        <StyledRightSidebar>
            {suggestedUsers.length > 0 && (
                <SuggestionsContainer>
                    <h3>Suggested Users</h3>
                    {suggestedUsers.map(user => (
                        <SuggestionItem key={user.id}>
                            <SuggestionInfo>
                                <SuggestionAvatar 
                                    src={user.photoURL || '/default-avatar.png'} 
                                    alt={user.displayName}
                                />
                                <SuggestionDetails>
                                    <h4>{user.displayName}</h4>
                                    <p>{user.beltRank || 'White'} Belt</p>
                                </SuggestionDetails>
                            </SuggestionInfo>
                            <SuggestionButton onClick={() => navigate(`/profile/${user.id}`)}>
                                View
                            </SuggestionButton>
                        </SuggestionItem>
                    ))}
                </SuggestionsContainer>
            )}

            {suggestedGyms.length > 0 && (
                <SuggestionsContainer>
                    <h3>Suggested Gyms</h3>
                    {suggestedGyms.map(gym => (
                        <SuggestionItem key={gym.id}>
                            <SuggestionInfo>
                                <SuggestionAvatar 
                                    src={gym.photoURL || '/default-gym.png'} 
                                    alt={gym.name}
                                />
                                <SuggestionDetails>
                                    <h4>{gym.name}</h4>
                                    <p>{gym.location}</p>
                                </SuggestionDetails>
                            </SuggestionInfo>
                            <SuggestionButton onClick={() => navigate(`/gym/${gym.id}`)}>
                                View
                            </SuggestionButton>
                        </SuggestionItem>
                    ))}
                </SuggestionsContainer>
            )}
        </StyledRightSidebar>
    );
};

export default RightSidebar; 