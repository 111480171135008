import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../../services/firebase';
import { doc, getDoc, updateDoc, deleteDoc, arrayUnion, writeBatch, arrayRemove } from 'firebase/firestore';
import { collection, getDocs } from 'firebase/firestore';
import CommentsSection from './CommentsSection';
import InviteButton from './InviteButton';
import logActivity from '../../utils/logActivity';
import { formatUserLink, formatEventLink } from '../../utils/formatLink';
import Modal from './Modal';
import '../../styles/EventInfo.css';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import useIsMounted from '../../hooks/useIsMounted';
import safeAsync from '../../utils/safeAsync';
import { savePlaylist, removePlaylist } from '../../services/playlistService';
import EventPlaylistSelector from './EventPlaylistSelector';
import SpotifyIcon from '../common/SpotifyIcon';

const UserEventInfo = () => {
    const { userId, eventId } = useParams();
    const [user] = useAuthState(auth);
    const [event, setEvent] = useState(null);
    const [isAttending, setIsAttending] = useState(false);
    const [comments, setComments] = useState([]);
    const [playlistEmbedCode, setPlaylistEmbedCode] = useState('');
    const [newPlaylistShareLink, setNewPlaylistShareLink] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [playlistError, setPlaylistError] = useState(null);
    const [isPlaylistLoading, setIsPlaylistLoading] = useState(false);
    const navigate = useNavigate();
    const isMounted = useIsMounted();
    const [showPlaylistSelector, setShowPlaylistSelector] = useState(false);
    const [embedLoadError, setEmbedLoadError] = useState(false);

    useEffect(() => {
        const fetchEvent = async () => {
            if (userId && eventId) {
                const eventDoc = await getDoc(doc(firestore, `users/${userId}/userEvents`, eventId));
                if (eventDoc.exists()) {
                    const eventData = {
                        ...eventDoc.data(),
                        userId,
                        id: eventId,
                    };

                    setEvent(eventData);
                    setPlaylistEmbedCode(eventDoc.data().playlistEmbedCode || '');
                } else {
                    console.error('No such event!');
                    setShowModal(true);
                }
            }
        };

        fetchEvent();
    }, [userId, eventId]);

    useEffect(() => {
        if (event && user) {
            const attending = event.attendees?.some(attendee => attendee.uid === user.uid);
            setIsAttending(attending);
        }
    }, [event, user]);

    useEffect(() => {
        const fetchComments = async () => {
            const safeFetch = safeAsync(async () => {
                const commentsRef = collection(firestore, `users/${userId}/userEvents/${eventId}/comments`);
                const snapshot = await getDocs(commentsRef);
                const commentsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                for (let comment of commentsList) {
                    const repliesRef = collection(firestore, `users/${userId}/userEvents/${eventId}/comments/${comment.id}/replies`);
                    const repliesSnapshot = await getDocs(repliesRef);
                    comment.replies = repliesSnapshot.docs.map(replyDoc => ({ id: replyDoc.id, ...replyDoc.data() }));
                }

                setComments(commentsList);
            }, isMounted);

            try {
                await safeFetch();
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };

        fetchComments();
    }, [userId, eventId]);

    const toggleAttendance = async () => {
        if (!user || !event) return;

        const eventDocRef = doc(firestore, `users/${userId}/userEvents`, eventId);
        const userRef = doc(firestore, 'users', user.uid);

        try {
            if (isAttending) {
                const eventSnapshot = await getDoc(eventDocRef);
                if (eventSnapshot.exists()) {
                    const eventData = eventSnapshot.data();
                    const newAttendees = eventData.attendees.filter(att => att.uid !== user.uid);

                    await updateDoc(eventDocRef, {
                        attendees: newAttendees
                    });
                }

                const userSnapshot = await getDoc(userRef);
                if (userSnapshot.exists()) {
                    const userData = userSnapshot.data();
                    const newAttending = userData.attending.filter(evt => evt.eventId !== eventId);

                    await updateDoc(userRef, {
                        attending: newAttending
                    });
                }

                await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} left the event: ${formatEventLink(userId, eventId, event.title)}`, 'event_left', {
                    userId: user.uid,
                    userName: user.displayName,
                    eventId: eventId,
                    eventTitle: event.title,
                    userPhotoURL: user.photoURL
                });
            } else {
                await updateDoc(eventDocRef, {
                    attendees: arrayUnion({
                        uid: user.uid,
                        displayName: user.displayName,
                        email: user.email,
                        photoURL: user.photoURL
                    })
                });

                await updateDoc(userRef, {
                    attending: arrayUnion({
                        eventId: eventId,
                        locationId: userId,
                        title: event.title,
                        start: event.start,
                        end: event.end
                    })
                });

                await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} joined the event: ${formatEventLink(userId, eventId, event.title)}`, 'event_joined', {
                    userId: user.uid,
                    userName: user.displayName,
                    eventId: eventId,
                    eventTitle: event.title,
                    userPhotoURL: user.photoURL
                });
            }
        } catch (error) {
            console.error('Error updating attendance:', error.message);
        }

        setIsAttending(!isAttending);
    };

    const handleDeleteEvent = async () => {
        const confirmDelete = window.confirm('Are you sure you want to delete this event?');
        if (!confirmDelete) return;

        if (!user || !event || !event.addedBy || !event.attendees) {
            console.error('Event, user, or attendees data is missing or malformed.');
            return;
        }

        if (user.uid !== event.addedBy.uid) {
            alert('You do not have permission to delete this event.');
            return;
        }

        const eventDocRef = doc(firestore, `users/${userId}/userEvents`, eventId);
        try {
            const batch = writeBatch(firestore);

            for (let attendeeUid of event.attendees) {
                const userDocRef = doc(firestore, 'users', attendeeUid);
                const userSnapshot = await getDoc(userDocRef);
                const userData = userSnapshot.data();

                if (userData && Array.isArray(userData.attending)) {
                    const newAttending = userData.attending.filter(evtId => evtId !== eventId);
                    batch.update(userDocRef, {
                        attending: newAttending
                    });
                }
            }

            batch.delete(eventDocRef);

            await batch.commit();

            await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} deleted the event: ${formatEventLink(userId, eventId, event.title)}`, 'event_deleted', {
                userId: user.uid,
                userName: user.displayName,
                eventId: eventId,
                eventTitle: event.title,
                userPhotoURL: user.photoURL
            });

            navigate(`/profile/${userId}`);
        } catch (error) {
            console.error('Error deleting event:', error.message);
            alert('Error deleting event: ' + error.message);
        }
    };

    const handleSavePlaylist = async () => {
        if (!user || !event || !userId) {
            setPlaylistError('Unable to save playlist: missing required data');
            return;
        }
        setIsPlaylistLoading(true);
        try {
            const success = await savePlaylist(
                user,
                event,
                { locationId: userId, type: 'user' },
                newPlaylistShareLink,
                setPlaylistEmbedCode,
                setNewPlaylistShareLink,
                setPlaylistError
            );
            if (!success) {
                setPlaylistError('Failed to save playlist');
            }
        } catch (error) {
            console.error('Error saving playlist:', error);
            setPlaylistError('An error occurred while saving the playlist');
        } finally {
            setIsPlaylistLoading(false);
        }
    };

    const handleRemovePlaylist = async () => {
        console.log('Debug - Event:', event);
        console.log('Debug - User:', user);
        console.log('Debug - UserId:', userId);

        if (!user || !event || !userId) {
            console.log('Debug - Missing data:', { user: !!user, event: !!event, userId: !!userId });
            setPlaylistError('Unable to remove playlist: missing required data');
            return;
        }

        setIsPlaylistLoading(true);
        try {
            // Create a proper location object that matches what the service expects
            const locationData = {
                locationId: userId,
                type: 'user',
                // Include these fields to ensure we're treating it as a user event
                isUserEvent: true,
                userId: userId
            };

            console.log('Debug - Location data:', locationData);

            const success = await removePlaylist(
                user,
                event,
                locationData,
                setPlaylistEmbedCode,
                setPlaylistError
            );

            if (!success) {
                setPlaylistError('Failed to remove playlist');
            }
        } catch (error) {
            console.error('Error removing playlist:', error);
            setPlaylistError('An error occurred while removing the playlist');
        } finally {
            setIsPlaylistLoading(false);
        }
    };

    const handleGetDirections = () => {
        if (event.location) {
            const encodedLocation = encodeURIComponent(event.location);
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodedLocation}`, '_blank');
        }
    };

    const handlePlaylistSelect = async (playlist) => {
        if (!user || !event) return;
        setIsPlaylistLoading(true);
        try {
            const embedCode = `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/${playlist.id}" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`;
            
            await updateDoc(doc(firestore, `users/${userId}/userEvents`, eventId), {
                playlistEmbedCode: embedCode,
                playlistId: playlist.id,
                playlistName: playlist.name,
                playlistOwner: playlist.owner.display_name
            });

            setPlaylistEmbedCode(embedCode);
            setShowPlaylistSelector(false);

            await logActivity(user.uid, 'playlist_updated', {
                userId: user.uid,
                userName: user.displayName,
                eventId: eventId,
                eventTitle: event.title,
                userPhotoURL: user.photoURL,
                message: `${formatUserLink(user.uid, user.displayName)} updated the playlist for: ${formatEventLink(userId, eventId, event.title)}`
            });
        } catch (error) {
            console.error('Error saving playlist:', error);
            setPlaylistError('Failed to save the playlist. Please try again.');
        } finally {
            setIsPlaylistLoading(false);
        }
    };

    const handleEmbedRetry = useCallback(() => {
        setEmbedLoadError(false);
        const currentEmbed = playlistEmbedCode;
        setPlaylistEmbedCode('');
        setTimeout(() => setPlaylistEmbedCode(currentEmbed), 100);
    }, [playlistEmbedCode]);

    if (showModal) {
        return (
            <Modal
                title="Error"
                content="Event not found. Please check the URL or try again later."
                onClose={() => navigate('/')}
            />
        );
    }

    if (!event) {
        return <div>Loading...</div>;
    }

    const shareUrl = window.location.href;
    const shareTitle = `Join me at ${event.title} on ${new Date(event.start.toDate()).toLocaleDateString()}!`;

    return (
        <div className="event-body-content">
            <div className="event-info-container">
                <div className="event-header-card">
                    <div className="event-header-left">
                        <h1>{event.title}</h1>
                        <p>Hosted by <Link to={`/profile/${event.addedBy.uid}`}>{event.addedBy.displayName}</Link></p>
                        <p>{new Date(event.start.toDate()).toLocaleString()} - {new Date(event.end.toDate()).toLocaleString()}</p>
                        <p>Location: {event.location}</p>
                        {event.location && (
                            <button onClick={handleGetDirections} className="event-directions-button">Get Directions</button>
                        )}
                    </div>
                    <div className="event-header-right">
                        <h3>Share This Event</h3>
                        <div className="event-share-icons">
                            <FacebookShareButton url={shareUrl} quote={shareTitle}>
                                <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                            <TwitterShareButton url={shareUrl} title={shareTitle}>
                                <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>
                            <WhatsappShareButton url={shareUrl} title={shareTitle}>
                                <WhatsappIcon size={32} round={true} />
                            </WhatsappShareButton>
                        </div>
                    </div>
                </div>

                <div className="event-attendees-card">
                    <h3>Attendees</h3>
                    <ul className="event-attendees-list">
                        {event.attendees?.map((attendee) => (
                            <li key={attendee.uid} className="event-attendee-item">
                                <Link to={`/profile/${attendee.uid}`} className="event-attendee-link">
                                    <div className="event-attendee-content">
                                        <img src={attendee.photoURL || 'placeholder.png'} alt="Attendee"
                                             className="event-attendee-pic" />
                                        <span className="event-attendee-name">{attendee.displayName}</span>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>

                    <div className="event-actions">
                        <InviteButton event={event} inviteType="event" />
                        <button onClick={toggleAttendance}
                                className={`event-button ${isAttending ? 'event-unattend-button' : 'event-attend-button'}`}>
                            {isAttending ? 'Leave Event' : 'Join Event'}
                        </button>

                        {user?.uid === event?.addedBy?.uid && (
                            <button onClick={handleDeleteEvent} className="event-delete-button">Delete Event</button>
                        )}
                    </div>

                </div>
                <CommentsSection
                    uniqueId={eventId}
                    collectionType={`users/${userId}/userEvents/${eventId}/comments`}
                    comments={comments}
                    setComments={setComments}
                    currentUser={user}
                    commentType="user_event"
                    itemId={eventId}
                    userId={userId}
                />
                <div className="event-playlist-card">
                    <h3>Event Playlist</h3>
                    {playlistError && (
                        <div className="error-message" style={{ color: 'red', marginBottom: '10px' }}>
                            {playlistError}
                        </div>
                    )}
                    {playlistEmbedCode ? (
                        <div>
                            {embedLoadError ? (
                                <div className="playlist-error-container" style={{ textAlign: 'center', padding: '20px' }}>
                                    <p style={{ marginBottom: '15px' }}>Failed to load the playlist. This might be due to a temporary connection issue.</p>
                                    <button 
                                        className="spotify-connect-button"
                                        onClick={handleEmbedRetry}
                                        style={{ margin: '0 auto' }}
                                    >
                                        <SpotifyIcon /> Retry Loading
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <iframe
                                        src={playlistEmbedCode.match(/src="([^"]+)"/)?.[1]}
                                        style={{ borderRadius: '12px' }}
                                        width="100%"
                                        height="352"
                                        frameBorder="0"
                                        allowFullScreen=""
                                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                        loading="lazy"
                                        onError={() => setEmbedLoadError(true)}
                                        onLoad={() => setEmbedLoadError(false)}
                                    />
                                </div>
                            )}
                            <button 
                                className="event-remove-button" 
                                onClick={handleRemovePlaylist}
                                disabled={isPlaylistLoading}
                            >
                                {isPlaylistLoading ? 'Removing...' : 'Remove Playlist'}
                            </button>
                        </div>
                    ) : showPlaylistSelector ? (
                        <EventPlaylistSelector 
                            userId={user?.uid}
                            onPlaylistSelect={handlePlaylistSelect}
                        />
                    ) : (
                        <div>
                            <p>Add a playlist to this event!</p>
                            <button 
                                className="spotify-connect-button"
                                onClick={() => setShowPlaylistSelector(true)}
                            >
                                <SpotifyIcon />
                                Connect Spotify
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserEventInfo;