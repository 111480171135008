// src/services/gymService.js
import { doc, getDoc, updateDoc, setDoc, collection, getDocs, arrayUnion, arrayRemove, writeBatch } from 'firebase/firestore';
import { firestore } from './firebase';
import logActivity from '../utils/logActivity';
import { formatUserLink, formatGymLink } from '../utils/formatLink';

export const saveGymEvent = async (gymId, events) => {
    const batch = writeBatch(firestore);

    events.forEach(event => {
        const eventDocRef = doc(collection(firestore, 'locations', gymId, 'events'));
        batch.set(eventDocRef, event);
    });

    try {
        await batch.commit();
    } catch (error) {
        console.error('Error saving events:', error);
        throw error;
    }
};

// Fetch gym info
export const fetchPlaceInfo = async (placeId, user) => {
    const placeDocRef = doc(firestore, 'locations', placeId);
    const placeDoc = await getDoc(placeDocRef);

    if (placeDoc.exists()) {
        const data = placeDoc.data();
        const isFavorite = user && data.favoritedBy && data.favoritedBy.includes(user.uid);
        return { data, isFavorite };
    } else {
        // Initialize a new gym entry if not found
        await setDoc(placeDocRef, {
            name: '',
            address: '',
            lat: 0,
            lng: 0,
            openMat: false,
            locationSaved: false,
            favoriteCount: 0,
            favoritedBy: [],
        });
        return { data: null, isFavorite: false };
    }
};

// Fetch events for a gym
export const fetchGymEvents = async (placeId) => {
    const eventsCollection = collection(firestore, 'locations', placeId, 'events');
    const eventsSnapshot = await getDocs(eventsCollection);

    return eventsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
            id: doc.id,
            title: data.title,
            start: data.start.toDate(),
            end: data.end.toDate(),
            addedBy: data.addedBy,
            attendees: data.attendees || [],
            recurrence: data.recurrence || 'none'
        };
    });
};

// Fetch favorited users' details
export const fetchFavoritedByDetails = async (favoritedBy) => {
    if (favoritedBy.length > 0) {
        const userDetails = await Promise.all(
            favoritedBy.map(async (userId) => {
                const userDoc = await getDoc(doc(firestore, 'users', userId));
                return userDoc.exists() ? { uid: userDoc.id, ...userDoc.data() } : null;
            })
        );
        return userDetails.filter(user => user !== null);
    }
    return [];
};

// Save a gym's location
export const saveGymLocation = async (placeId, lat, lng, locationSaved, user, placeInfo) => {
    try {
        await updateDoc(doc(firestore, 'locations', placeId), {
            lat,
            lng,
            locationSaved: !locationSaved,
        });

        await logActivity(user.uid, 'gym_pin', {
            message: `${formatUserLink(user.uid, user.displayName)} ${locationSaved ? 'unpinned' : 'pinned'} Gym ${locationSaved ? 'from' : 'to'} ${formatGymLink(placeId, placeInfo.name)}`,
            userId: user.uid,
            userName: user.displayName,
            gymId: placeId,
            gymName: placeInfo.name,
            userPhotoURL: user.photoURL,
        });

        return !locationSaved;
    } catch (error) {
        console.error('Error saving location:', error);
        throw error;
    }
};

// Toggle favorite status
export const toggleGymFavorite = async (isFavorite, user, placeId, placeInfo) => {
    const userDocRef = doc(firestore, 'users', user.uid);
    const placeDocRef = doc(firestore, 'locations', placeId);

    try {
        const placeDoc = await getDoc(placeDocRef);
        if (placeDoc.exists()) {
            const placeData = placeDoc.data();
            let newFavoriteCount = placeData.favoriteCount || 0;

            if (isFavorite) {
                await updateDoc(userDocRef, {
                    favorites: arrayRemove(placeId)
                });
                await updateDoc(placeDocRef, {
                    favoritedBy: arrayRemove(user.uid)
                });
                newFavoriteCount--;

                await logActivity(user.uid, 'gym_unfavorite', {
                    message: `${formatUserLink(user.uid, user.displayName)} removed ${formatGymLink(placeId, placeInfo.name)} from favorites`,
                    userId: user.uid,
                    userName: user.displayName,
                    gymId: placeId,
                    gymName: placeInfo.name,
                    userPhotoURL: user.photoURL,
                });
            } else {
                await updateDoc(userDocRef, {
                    favorites: arrayUnion(placeId)
                });
                await updateDoc(placeDocRef, {
                    favoritedBy: arrayUnion(user.uid)
                });
                newFavoriteCount++;

                await logActivity(user.uid, 'gym_favorite', {
                    message: `${formatUserLink(user.uid, user.displayName)} added ${formatGymLink(placeId, placeInfo.name)} to favorites`,
                    userId: user.uid,
                    userName: user.displayName,
                    gymId: placeId,
                    gymName: placeInfo.name,
                    userPhotoURL: user.photoURL,
                });
            }

            await updateDoc(placeDocRef, {
                favoriteCount: newFavoriteCount
            });

            return {
                isFavorite: !isFavorite,
                newFavoriteCount,
            };
        }
    } catch (error) {
        console.error('Error updating favorites: ', error);
        throw error;
    }
};
