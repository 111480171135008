import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../services/firebase';
import { getUserPlaylists, initializeSpotifyAuth, refreshSpotifyToken, saveSpotifyTokens } from '../../services/spotifyService';
import '../../styles/EventPlaylistCard.css';

const EventPlaylistSelector = ({ userId, onPlaylistSelect }) => {
    const [spotifyConnected, setSpotifyConnected] = useState(false);
    const [playlists, setPlaylists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const loadPlaylists = async () => {
            try {
                const userDoc = await getDoc(doc(firestore, 'users', userId));
                const userData = userDoc.data();
                
                // Check if we have valid tokens
                if (!userData?.spotifyTokens?.accessToken) {
                    setSpotifyConnected(false);
                    setLoading(false);
                    return;
                }

                // Check if token is expired (tokens last 1 hour)
                const tokenTimestamp = userData.spotifyTokens.timestamp;
                const tokenAge = Date.now() - tokenTimestamp;
                const ONE_HOUR = 3600000;

                let currentAccessToken = userData.spotifyTokens.accessToken;

                if (tokenAge >= ONE_HOUR) {
                    console.log('Attempting to refresh expired token...');
                    try {
                        const newTokens = await refreshSpotifyToken(userData.spotifyTokens.refreshToken);
                        await saveSpotifyTokens(userId, newTokens);
                        currentAccessToken = newTokens.accessToken;
                        console.log('Token refreshed successfully');
                    } catch (refreshError) {
                        console.error('Failed to refresh token:', refreshError);
                        setSpotifyConnected(false);
                        setError('Spotify connection expired. Please reconnect.');
                        setLoading(false);
                        return;
                    }
                }

                setSpotifyConnected(true);
                const userPlaylists = await getUserPlaylists(currentAccessToken, userId);
                setPlaylists(userPlaylists);
            } catch (error) {
                console.error('Error loading playlists:', error);
                // Check if it's an authentication error
                if (error.message.includes('Failed to fetch playlists') || error.message.includes('No refresh token available')) {
                    setSpotifyConnected(false);
                    setError('Spotify connection expired. Please reconnect.');
                } else {
                    setError('Failed to load playlists. Please try again.');
                }
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            loadPlaylists();
        }
    }, [userId]);

    const handleConnectSpotify = () => {
        initializeSpotifyAuth();
    };

    const filteredPlaylists = playlists.filter(playlist =>
        playlist.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) {
        return <div>Loading playlists...</div>;
    }

    if (!spotifyConnected || error?.includes('Spotify connection expired')) {
        return (
            <div className="spotify-section">
                <p>{error || 'Connect your Spotify account to add playlists to events!'}</p>
                <button onClick={handleConnectSpotify} className="spotify-connect-button">
                    Connect Spotify
                </button>
            </div>
        );
    }

    return (
        <div className="spotify-section">
            <div className="playlist-search">
                <input
                    type="text"
                    placeholder="Search your playlists..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="playlist-search-input"
                />
            </div>

            {error && (
                <div className="error-message">
                    {error}
                </div>
            )}

            <div className="playlists-grid">
                {filteredPlaylists.map(playlist => (
                    <div
                        key={playlist.id}
                        className="playlist-card clickable"
                        onClick={() => onPlaylistSelect(playlist)}
                    >
                        <img
                            src={playlist.images[0]?.url || '/default-playlist-image.png'}
                            alt={playlist.name}
                        />
                        <h5>{playlist.name}</h5>
                        <p>{playlist.tracks.total} tracks</p>
                        {playlist.collaborative && (
                            <span className="collaborative-badge">Collaborative</span>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EventPlaylistSelector; 