import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore, storage } from '../../services/firebase';
import { doc, getDoc, updateDoc, setDoc, collection, getDocs, arrayUnion, arrayRemove, query, where, limit, writeBatch, increment, orderBy } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FaStar, FaRegStar, FaThumbtack, FaFlag, FaUpload, FaEdit, FaCog } from 'react-icons/fa';
import CommentsSection from './CommentsSection';
import RecurrenceModal from './RecurrenceModal';
import logActivity from '../../utils/logActivity';
import { saveEventWithRecurrence } from '../../services/recurrenceService';
import { formatUserLink, formatGymLink } from '../../utils/formatLink';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import useIsMounted from '../../hooks/useIsMounted';
import safeAsync from '../../utils/safeAsync';
import LeftSidebar from '../layout/LeftSidebar';
import RightSidebar from '../layout/RightSidebar';
import {
    GymInfoContainer,
    GymInfoCard,
    TopGymInfoCard,
    GymBanner,
    TopHalf,
    Section,
    CenterSection,
    RightSection,
    ActionContainer,
    OpenMatStatusContainer,
    ActionButton,
    GymLogoPlaceholder,
    BottomHalf,
    GymName,
    FavoriteContainer,
    FavoriteButton,
    OpenMatStatus,
    StatusIndicator,
    UploadLabel,
    CalendarContainer,
    ReviewsList,
    ReviewCard,
    ReviewAuthor,
    ReviewText,
    ReviewRating,
    ClaimGymModalOverlay,
    ClaimGymModal,
    ClaimGymButton,
    EventLengthAlertOverlay,
    EventLengthAlertModal,
    EventLengthAlertButton,
    PromotionalTextSection,
    PromotionalTextInput,
    SaveTextButton,
    GymOwnerControls,
    AdminMenuContainer,
    AdminMenuButton,
    AdminMenu,
    AdminMenuItem,
    ScheduleSection,
    ContactSection
} from '../../styles/GymInfo.styles';
import { UserProfileLayout, MainContent } from '../../styles/UserProfile.styles';
import FavoritedByModal from './FavoritedByModal';
import ReviewsModal from './ReviewsModal';

SwiperCore.use([Navigation, Pagination]);

const GymInfo = () => {
    const {id: paramGymId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const [user] = useAuthState(auth);
    const [suggestedUsers, setSuggestedUsers] = useState([]);
    const [suggestedGyms, setSuggestedGyms] = useState([]);

    const gymId = paramGymId || location.state?.gymId || new URLSearchParams(location.search).get('gymId');
    const placeId = location.state?.placeId || gymId;
    const name = location.state?.name || new URLSearchParams(location.search).get('name');

    const [lat, setLat] = useState(location.state?.lat);
    const [lng, setLng] = useState(location.state?.lng);
    const address = location.state?.address;
    const [gym, setGym] = useState(null);
    const [isGymClaimed, setIsGymClaimed] = useState(false);
    const [gymOwnerName, setGymOwnerName] = useState('');
    const [gymLogoUrl, setGymLogoUrl] = useState('');
    const [bannerUrl, setBannerUrl] = useState('');
    const [promotionalText, setPromotionalText] = useState('');
    const [openMat, setOpenMat] = useState(false);
    const [locationSaved, setLocationSaved] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [favoritedBy, setFavoritedBy] = useState([]);
    const [favoritedByDetails, setFavoritedByDetails] = useState([]);
    const [events, setEvents] = useState([]);
    const [comments, setComments] = useState([]);
    const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);
    const [newEventDetails, setNewEventDetails] = useState(null);
    const [showEventLengthAlert, setShowEventLengthAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isClaimModalOpen, setIsClaimModalOpen] = useState(false);
    const [claimReference, setClaimReference] = useState('');
    const [isModerator, setIsModerator] = useState(false);
    const shareUrl = window.location.href;
    const [showAdminMenu, setShowAdminMenu] = useState(false);
    const [showFavoritedByModal, setShowFavoritedByModal] = useState(false);
    const [favoritedByUsers, setFavoritedByUsers] = useState([]);
    const [showReviewsModal, setShowReviewsModal] = useState(false);
    const [openNewPost, setOpenNewPost] = useState(false);

    const handleSaveRecurrence = (recurrence, occurrences) => {
        saveEventWithRecurrence(
            user,
            placeId,
            gym,
            newEventDetails,
            recurrence,
            occurrences,
            setEvents,
            checkAndSetOpenMatStatus,
            () => setShowRecurrenceModal(false),
            () => setNewEventDetails(null)
        );
    };

    const EventLengthAlert = ({message, onClose}) => {
        return (
            <div className="event-length-alert-overlay">
                <div className="event-length-alert-box">
                    <span style={{whiteSpace: 'pre-line', textAlign: 'center'}}>{message}</span>
                    <div style={{marginTop: '20px', textAlign: 'center'}}>
                        <button onClick={onClose} style={{padding: '10px 20px'}}>OK</button>
                    </div>
                </div>
            </div>
        );
    };

    const handleDateSelect = useCallback(
        (selectionInfo) => {
            const {startStr, endStr, start, end} = selectionInfo;
            const minDuration = 1 * 60 * 60 * 1000;
            if (end - start < minDuration) {
                setAlertMessage("Events must be at least 1 hour long.\nC'mon, who wants an open mat less than an hour long?");
                setShowEventLengthAlert(true);
                return;
            }

            const hasOverlap = events.some(event =>
                start < event.end && end > event.start
            );
            if (hasOverlap) {
                setAlertMessage('Events cannot overlap.');
                setShowEventLengthAlert(true);
                return;
            }

            const title = "Open Mat";
            if (user) {
                setNewEventDetails({title, start: new Date(startStr), end: new Date(endStr)});
                setShowRecurrenceModal(true);
            } else {
                console.error('User not authenticated');
            }
        },
        [events, user]
    );

    const renderEventContent = (eventInfo) => {
        return (
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <div style={{flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {eventInfo.timeText} {eventInfo.event.title}
                </div>
                <div style={{display: 'flex', gap: '10px', paddingRight: '10px', paddingTop: '5px'}}>
                    <FacebookShareButton url={window.location.href} quote={eventInfo.event.title}>
                        <FacebookIcon size={20} round={true}/>
                    </FacebookShareButton>
                    <TwitterShareButton url={window.location.href} title={eventInfo.event.title}>
                        <TwitterIcon size={20} round={true}/>
                    </TwitterShareButton>
                    <WhatsappShareButton url={window.location.href} title={eventInfo.event.title}>
                        <WhatsappIcon size={20} round={true}/>
                    </WhatsappShareButton>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const fetchGymData = async () => {
            const safeFetch = safeAsync(async () => {
                const gymDocRef = doc(firestore, 'locations', gymId);
                const gymDoc = await getDoc(gymDocRef);
                
                if (gymDoc.exists()) {
                    // Existing gym - load data
                    const gymData = gymDoc.data();
                        setGym(gymData);
                    setFavoritedBy(gymData.favoritedBy || []);
                    setOpenMat(gymData.openMat || false);
                    setLocationSaved(gymData.locationSaved || false);
                    setGymLogoUrl(gymData.logoUrl || '');
                    setBannerUrl(gymData.bannerUrl || '');
                    setPromotionalText(gymData.promotionalText || '');

                    // Check if the gym is claimed
                    const claimRequestRef = doc(firestore, 'claimRequests', gymId);
                    const claimRequestDoc = await getDoc(claimRequestRef);
                    if (claimRequestDoc.exists()) {
                        const claimRequestData = claimRequestDoc.data();

                        if (claimRequestData.status === 'approved' && claimRequestData.type === 'gym') {
                            setIsGymClaimed(true);

                            // Fetch the owner's name
                            const ownerDocRef = doc(firestore, 'users', claimRequestData.userId);
                            const ownerDoc = await getDoc(ownerDocRef);
                            if (ownerDoc.exists()) {
                                setGymOwnerName(ownerDoc.data().displayName);
                            }

                            // Check if the current user is the gym owner
                            if (user && user.uid === claimRequestData.userId) {
                                setIsModerator(true);
                            }
                        }
                    } else {
                        setIsGymClaimed(false);
                        setIsModerator(false);
                    }
                } else if (location.state) {
                    // Create new gym with data from location state
                    const newGymData = {
                        name: name || location.state.name,
                        address: address || location.state.address,
                        lat: lat || location.state.lat,
                        lng: lng || location.state.lng,
                        place_id: placeId || location.state.place_id,
                        phone: location.state.phone || null,
                        website: location.state.website || null,
                        photos: location.state.photos || [],
                        rating: location.state.rating || null,
                        user_ratings_total: location.state.user_ratings_total || 0,
                        opening_hours: location.state.opening_hours || null,
                        createdBy: user?.uid || null,
                        createdAt: new Date().toISOString(),
                        openMat: false,
                        locationSaved: false,
                        favoritedBy: [],
                        claimed: false
                    };

                    // Save to Firestore
                    await setDoc(gymDocRef, newGymData);

                    // Update local state
                    setGym(newGymData);
                    setOpenMat(false);
                    setLocationSaved(false);
                    setFavoritedBy([]);
                    setIsGymClaimed(false);
                    setIsModerator(false);
                } else {
                    console.error('No gym data found and no location state to create it');
                    navigate(-1);
                }
            }, isMounted);

            try {
                await safeFetch();
            } catch (error) {
                console.error('Error fetching/creating gym data:', error);
                navigate(-1);
            }
        };

        if (gymId) {
            fetchGymData();
        }
    }, [gymId, user, name, address, lat, lng, placeId, location.state, navigate, isMounted]);

    useEffect(() => {
        const fetchFavoritedByDetails = async () => {
            const safeFetch = safeAsync(async () => {
                if (favoritedBy.length > 0) {
                    const userDetails = await Promise.all(
                        favoritedBy.map(async (userId) => {
                            const userDoc = await getDoc(doc(firestore, 'users', userId));
                            return userDoc.exists() ? { uid: userDoc.id, ...userDoc.data() } : null;
                        })
                    );

                    setFavoritedByDetails(userDetails.filter(user => user !== null));

                    // Check if the current user is in the favoritedBy list
                    const currentUserUid = auth.currentUser?.uid;
                    if (currentUserUid) {
                        const userHasFavorited = favoritedBy.includes(currentUserUid);
                        setIsFavorite(userHasFavorited);
                    }
                }
            }, isMounted);

            await safeFetch();
        };

        fetchFavoritedByDetails();
    }, [favoritedBy]);

    useEffect(() => {
        const fetchEvents = async () => {
            const safeFetch = safeAsync(async () => {
                if (gymId) {
                    const eventsCollection = collection(firestore, 'locations', gymId, 'events');
                    const eventsSnapshot = await getDocs(eventsCollection);

                    const eventsList = eventsSnapshot.docs.map(doc => {
                        const data = doc.data();
                        return {
                            id: doc.id,
                            title: data.title,
                            start: data.start.toDate(),
                            end: data.end.toDate(),
                            addedBy: data.addedBy,
                            attendees: data.attendees || [],
                            recurrence: data.recurrence || 'none'
                        };
                    });

                    setEvents(eventsList);
                }
            }, isMounted);

            try {
                await safeFetch();
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, [gymId]);

    useEffect(() => {
        const fetchComments = async () => {
            if (!gymId) return;
            
            const commentsRef = collection(firestore, `locations/${gymId}/comments`);
            const q = query(commentsRef, orderBy('createdAt', 'desc'));
            const snapshot = await getDocs(q);
            
            const fetchedComments = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            setComments(fetchedComments);
        };
        
        fetchComments();
    }, [gymId]);

    const saveLocation = async () => {
        const safeSave = safeAsync(async () => {
            const newLocationSaved = !locationSaved;
            const gymDocRef = doc(firestore, 'locations', placeId);

            const updateData = {
                locationSaved: newLocationSaved,
            };

            if (newLocationSaved && (lat !== undefined && lng !== undefined)) {
                updateData.lat = lat;
                updateData.lng = lng;
            }

            await updateDoc(gymDocRef, updateData);
            setLocationSaved(newLocationSaved);

            await logActivity(user.uid, `${formatUserLink(user.uid, user.displayName)} ${newLocationSaved ? 'pinned' : 'unpinned'} Gym ${newLocationSaved ? 'to' : 'from'} ${formatGymLink(placeId, gym.name)}`, 'gym_pin', {
                userId: user.uid,
                userName: user.displayName,
                gymId: placeId,
                gymName: gym.name,
                userPhotoURL: user.photoURL
            });
        }, isMounted);

        try {
            await safeSave();
        } catch (error) {
            console.error('Error saving location:', error);
        }
    };

    const toggleFavorite = async () => {
        if (user && gymId) {
            const userDocRef = doc(firestore, 'users', user.uid);
            const gymDocRef = doc(firestore, 'locations', gymId);
            const batch = writeBatch(firestore);

            try {
                if (isFavorite) {
                    // Remove from favorites
                    batch.update(userDocRef, {
                        favorites: arrayRemove(gymId)
                    });
                    batch.update(gymDocRef, {
                        favoritedBy: arrayRemove(user.uid),
                        favoriteCount: increment(-1)
                    });
                } else {
                    // Add to favorites
                    batch.update(userDocRef, {
                        favorites: arrayUnion(gymId)
                    });
                    batch.update(gymDocRef, {
                        favoritedBy: arrayUnion(user.uid),
                        favoriteCount: increment(1)
                    });
                }

                // Commit the batch
                await batch.commit();

                // Update the local state
                setIsFavorite(!isFavorite);
                setFavoritedBy(prev => 
                    isFavorite ? prev.filter(uid => uid !== user.uid) : [...prev, user.uid]
                );
            } catch (error) {
                console.error('Error updating favorites: ', error);
                throw error;
            }
        }
    };

    const checkAndSetOpenMatStatus = useCallback(async () => {
        if (!gymId) {
            console.error('Invalid gymId. It should be a non-empty string.');
            return;
        }

        try {
            const now = new Date();

            // Fetch all events for the location
            const eventsCollection = collection(firestore, 'locations', gymId, 'events');
            const eventsSnapshot = await getDocs(eventsCollection);

            // Filter events to find any that are currently ongoing or scheduled in the future
            const relevantEvents = eventsSnapshot.docs.filter(doc => {
                const eventData = doc.data();
                const eventStart = eventData.start.toDate();
                const eventEnd = eventData.end.toDate();
                // Check if the event is ongoing or in the future
                return eventEnd >= now;
            });

            // Determine if there are any ongoing or future events
            const openMatExists = relevantEvents.length > 0;

            // Update the openMat status in Firestore
            await updateDoc(doc(firestore, 'locations', gymId), {openMat: openMatExists});
            setOpenMat(openMatExists);

        } catch (error) {
            console.error('Error updating openMat status:', error);
        }
    }, [gymId]);

    useEffect(() => {
        checkAndSetOpenMatStatus(); // Call this on component load to ensure openMat status is accurate
    }, [checkAndSetOpenMatStatus]);

    const handleLogoUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const storageRef = ref(storage, `gymLogos/${gymId}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Handle progress if needed
            },
            (error) => {
                console.error('Upload error:', error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                await updateDoc(doc(firestore, 'locations', gymId), {logoUrl: downloadURL});
                setGymLogoUrl(downloadURL);
            }
        );
    };

    const handleBannerUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const storageRef = ref(storage, `gymBanners/${gymId}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Handle progress if needed
            },
            (error) => {
                console.error('Upload error:', error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                await updateDoc(doc(firestore, 'locations', gymId), {bannerUrl: downloadURL});
                setBannerUrl(downloadURL);
            }
        );
    };

    const handlePromotionalTextChange = async (event) => {
        const text = event.target.value;
        setPromotionalText(text);
        await updateDoc(doc(firestore, 'locations', gymId), {promotionalText: text});
    };

    const handleClaimGym = async () => {
        if (user) {
            try {
                // Generate a unique reference number
                const referenceNumber = Math.random().toString(36).substring(2, 15);

                // Store the claim request in Firestore with the correct type and status
                const claimRequestRef = doc(firestore, 'claimRequests', gymId);
                await setDoc(claimRequestRef, {
                    gymId,
                    userId: user.uid,
                    status: 'pending',
                    type: 'gym',
                    referenceNumber: referenceNumber,
                    createdAt: new Date(),
                });

                // Set the reference number state
                setClaimReference(referenceNumber);

                // Open the claim modal
                setIsClaimModalOpen(true); // Make sure this line exists
            } catch (error) {
                console.error('Error submitting claim request:', error);
            }
        } else {
            alert('You need to be logged in to claim a gym.');
        }
    };

    const handleClaimSubmit = () => {
        // Open Instagram with a pre-filled message
        const instagramURL = `https://www.instagram.com/openmatapp/`;
        window.open(instagramURL, '_blank');

        // Close the modal
        setIsClaimModalOpen(false);
    };

    const bannerStyle = bannerUrl
        ? {
            backgroundImage: `url(${bannerUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.4,
        }
        : {};

    useEffect(() => {
        const fetchSuggestions = async () => {
            if (!user) return;

            try {
                // Fetch suggested users
                const usersRef = collection(firestore, 'users');
                const usersQuery = query(
                    usersRef,
                    where('role.creator', '==', true),
                    limit(5)
                );
                const userSnapshot = await getDocs(usersQuery);
                const users = userSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(u => u.id !== user.uid);

                setSuggestedUsers(users);

                // Fetch suggested gyms
                const gymsRef = collection(firestore, 'locations');
                const gymsQuery = query(gymsRef, limit(3));
                const gymSnapshot = await getDocs(gymsQuery);
                const gyms = gymSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }));

                setSuggestedGyms(gyms);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        };

        fetchSuggestions();
    }, [user]);

    // Add useEffect to scroll to top when component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Empty dependency array means this runs once when component mounts

    // Add this new effect to fetch favorited users' details
    useEffect(() => {
        const fetchFavoritedUsers = async () => {
            if (!favoritedBy.length) {
                setFavoritedByUsers([]);
                return;
            }

            try {
                const usersData = await Promise.all(
                    favoritedBy.map(async (uid) => {
                        const userDoc = await getDoc(doc(firestore, 'users', uid));
                        if (userDoc.exists()) {
                            return { uid, ...userDoc.data() };
                        }
                        return null;
                    })
                );
                setFavoritedByUsers(usersData.filter(user => user !== null));
            } catch (error) {
                console.error('Error fetching favorited users:', error);
            }
        };

        fetchFavoritedUsers();
    }, [favoritedBy]);

    // Split the favorite button click handler
    const handleFavoriteClick = (e) => {
        e.stopPropagation();
        if (!user) {
            // Handle not logged in case if needed
            return;
        }
        toggleFavorite();
    };

    const handleFavoriteCountClick = (e) => {
        e.stopPropagation();
        setShowFavoritedByModal(true);
    };

    if (!gym) return <div>Loading...</div>;

    return (
        <UserProfileLayout>
            <LeftSidebar />
            <MainContent>
                <GymInfoContainer>
                    <TopGymInfoCard>
                        {bannerUrl ? (
                            <GymBanner>
                                <img src={bannerUrl} alt="Gym Banner" />
                                {user && (
                                    <AdminMenuContainer>
                                        <AdminMenuButton onClick={() => setShowAdminMenu(!showAdminMenu)}>
                                            <FaCog />
                                        </AdminMenuButton>
                                        {showAdminMenu && (
                                            <AdminMenu>
                                                {!isGymClaimed && (
                                                    <AdminMenuItem onClick={handleClaimGym}>
                                                        <FaFlag /> Claim Gym
                                                    </AdminMenuItem>
                                                )}
                                                <AdminMenuItem onClick={saveLocation}>
                                                    <FaThumbtack /> {locationSaved ? 'Unpin Gym' : 'Pin Gym'}
                                                </AdminMenuItem>
                                            </AdminMenu>
                                        )}
                                    </AdminMenuContainer>
                                )}
                            </GymBanner>
                        ) : (
                            <GymBanner>
                                {user && (
                                    <AdminMenuContainer>
                                        <AdminMenuButton onClick={() => setShowAdminMenu(!showAdminMenu)}>
                                            <FaCog />
                                        </AdminMenuButton>
                                        {showAdminMenu && (
                                            <AdminMenu>
                                                {!isGymClaimed && (
                                                    <AdminMenuItem onClick={handleClaimGym}>
                                                        <FaFlag /> Claim Gym
                                                    </AdminMenuItem>
                                                )}
                                                <AdminMenuItem onClick={saveLocation}>
                                                    <FaThumbtack /> {locationSaved ? 'Unpin Gym' : 'Pin Gym'}
                                                </AdminMenuItem>
                                            </AdminMenu>
                                        )}
                                    </AdminMenuContainer>
                                )}
                            </GymBanner>
                        )}
                        
                        <TopHalf>
                            <Section>
                                <OpenMatStatusContainer>
                                    <OpenMatStatus>
                                        <StatusIndicator isOpen={openMat} />
                                        <span>{openMat ? 'Open Mat Available' : 'No Open Mat'}</span>
                                    </OpenMatStatus>
                                </OpenMatStatusContainer>
                            </Section>

                            <CenterSection>
                                {isModerator ? (
                                    <GymLogoPlaceholder>
                                        {gymLogoUrl ? (
                                            <img src={gymLogoUrl} alt="Gym Logo" />
                                        ) : (
                                            <UploadLabel htmlFor="logo-upload">
                                                <FaUpload />
                                                <span>Upload Logo</span>
                                                <input
                                                    id="logo-upload"
                                                    type="file"
                                                    onChange={handleLogoUpload}
                                                    style={{ display: 'none' }}
                                                />
                                            </UploadLabel>
                                        )}
                                    </GymLogoPlaceholder>
                                ) : (
                                    <GymLogoPlaceholder>
                                        {gymLogoUrl && <img src={gymLogoUrl} alt="Gym Logo" />}
                                    </GymLogoPlaceholder>
                                )}
                            </CenterSection>

                            <RightSection>
                                {isGymClaimed && (
                                    <p>Owned by {gymOwnerName}</p>
                                )}
                            </RightSection>
                        </TopHalf>

                        <BottomHalf>
                            <GymName>{gym.name}</GymName>
                            <FavoriteContainer>
                                <FavoriteButton>
                                    <div onClick={handleFavoriteClick}>
                                        {isFavorite ? <FaStar className="star-icon" /> : <FaRegStar className="star-icon" />}
                                    </div>
                                    <span onClick={handleFavoriteCountClick}>
                                        {favoritedBy.length} {favoritedBy.length === 1 ? 'favorite' : 'favorites'}
                                    </span>
                                </FavoriteButton>
                            </FavoriteContainer>
                        </BottomHalf>
                    </TopGymInfoCard>

                    <GymInfoCard>
                        <h3>Open Mat Schedule</h3>
                        <CalendarContainer>
                            <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                height={825}
                                initialView="timeGridDay"
                                selectable={true}
                                events={events}
                                eventContent={renderEventContent}
                                slotMinTime="07:00:00"
                                slotMaxTime="22:00:00"
                                allDaySlot={false}
                                selectMirror={true}
                                select={handleDateSelect}
                                eventClick={({event}) => {
                                    navigate(`/gym/${gymId}/event/${event.id}`, {state: {gymId}});
                                }}
                            />
                        </CalendarContainer>
                    </GymInfoCard>

                    <GymInfoCard>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                            <h3>Comments</h3>
                            {user && (
                                <ActionButton onClick={() => setOpenNewPost(true)}>
                                    New Comment
                                </ActionButton>
                            )}
                        </div>
                        <CommentsSection
                            uniqueId={gymId}
                            collectionType="locations"
                            commentType="gym"
                            itemId={gymId}
                            userId={user?.uid}
                            openNewPost={openNewPost}
                            setOpenNewPost={setOpenNewPost}
                            singlePostMode={false}
                        />
                    </GymInfoCard>

                    <GymInfoCard>
                        <ScheduleSection>
                            <h3>Opening Hours</h3>
                            {gym.opening_hours && gym.opening_hours.length > 0 ? (
                                <ul>
                                    {gym.opening_hours.map((hour, index) => {
                                        const [day, hours] = hour.split(': ');
                                        return (
                                            <li key={index}>
                                                <span className="day">{day}</span>
                                                <span className="hours">{hours || 'Closed'}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                <p>No opening hours available.</p>
                            )}
                        </ScheduleSection>
                    </GymInfoCard>

                    <GymInfoCard>
                        <ContactSection>
                            <h3>Contact Information</h3>
                            <p>Phone: {gym.phone || 'N/A'}</p>
                            <p>
                                Website:{' '}
                                {gym.website ? (
                                    <a href={gym.website} target="_blank" rel="noopener noreferrer">
                                        {gym.website}
                                    </a>
                                ) : (
                                    'N/A'
                                )}
                            </p>
                        </ContactSection>
                    </GymInfoCard>

                    <GymInfoCard>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3>Reviews</h3>
                            <ActionButton onClick={() => setShowReviewsModal(true)}>
                                View All Reviews ({gym.reviews ? gym.reviews.length : 0})
                            </ActionButton>
                        </div>
                    </GymInfoCard>

                    {showRecurrenceModal && (
                        <RecurrenceModal 
                            onClose={() => setShowRecurrenceModal(false)} 
                            onSave={handleSaveRecurrence}
                        />
                    )}

                    <ReviewsModal 
                        isOpen={showReviewsModal}
                        onClose={() => setShowReviewsModal(false)}
                        reviews={gym.reviews || []}
                    />

                    {showEventLengthAlert && (
                        <EventLengthAlertOverlay>
                            <EventLengthAlertModal>
                                <span style={{whiteSpace: 'pre-line', textAlign: 'center'}}>{alertMessage}</span>
                                <div style={{marginTop: '20px', textAlign: 'center'}}>
                                    <EventLengthAlertButton onClick={() => setShowEventLengthAlert(false)}>OK</EventLengthAlertButton>
                                </div>
                            </EventLengthAlertModal>
                        </EventLengthAlertOverlay>
                    )}

                    {isClaimModalOpen && (
                        <ClaimGymModalOverlay>
                            <ClaimGymModal>
                                <h3>Claim This Gym</h3>
                                <p>
                                    Your reference number is: <strong>{claimReference}</strong>
                                </p>
                                <p>
                                    We have copied this number to your clipboard. Please message us on Instagram with this
                                    reference number to verify your ownership.
                                </p>
                                <ClaimGymButton onClick={handleClaimSubmit}>
                                    Open Instagram
                                </ClaimGymButton>
                                <ClaimGymButton onClick={() => setIsClaimModalOpen(false)} cancel>
                                    Cancel
                                </ClaimGymButton>
                            </ClaimGymModal>
                        </ClaimGymModalOverlay>
                    )}

                    {isGymClaimed && isModerator && (
                        <GymInfoCard>
                            <h3>Promotional Text</h3>
                            <PromotionalTextSection>
                                <PromotionalTextInput
                                    value={promotionalText}
                                    onChange={(e) => handlePromotionalTextChange(e)}
                                    placeholder="Add promotional text for your gym..."
                                    rows={4}
                                />
                                <SaveTextButton onClick={() => handlePromotionalTextChange({ target: { value: promotionalText } })}>
                                    Save Text
                                </SaveTextButton>
                            </PromotionalTextSection>
                        </GymInfoCard>
                    )}
                </GymInfoContainer>
            </MainContent>

            <RightSidebar 
                suggestedUsers={suggestedUsers}
                suggestedGyms={suggestedGyms}
            />

            <FavoritedByModal 
                isOpen={showFavoritedByModal}
                onClose={() => setShowFavoritedByModal(false)}
                users={favoritedByUsers}
            />
        </UserProfileLayout>
    );
};

export default GymInfo;
