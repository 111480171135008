import React from 'react';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import { PageLayout as StyledPageLayout, PageContent } from '../../styles/layout/PageLayout.styles';

const PageLayout = ({ children, suggestedUsers = [], suggestedGyms = [] }) => {
  return (
    <StyledPageLayout>
      <LeftSidebar />
      <PageContent>
        {children}
      </PageContent>
      <RightSidebar 
        suggestedUsers={suggestedUsers}
        suggestedGyms={suggestedGyms}
      />
    </StyledPageLayout>
  );
};

export default PageLayout; 