// src/components/common/InviteButton.js
import React from 'react';
import '../../styles/InviteButton.css';

const InviteButton = ({ event, gymName, inviteType, icon, iconOnly }) => {
    const handleInvite = () => {
        let message = '';
        let encodedMessage = '';
        let smsLink = '';

        if (inviteType === 'event' && event && gymName) {
            const eventStart = new Date(event.start.seconds * 1000).toLocaleString();
            const eventEnd = new Date(event.end.seconds * 1000).toLocaleString();
            message = `You are invited to ${event.title} at ${gymName} on ${eventStart}. Check it out on our app!`;
            const eventLink = `${window.location.origin}/gym/${event.locationId}/event/${event.id}`;
            encodedMessage = encodeURIComponent(`${message} ${eventLink}`);
        } else if (inviteType === 'app') {
            message = `Join me on the OpenMat App! Download it now: ${window.location.origin}`;
            encodedMessage = encodeURIComponent(message);
        }

        smsLink = `sms:?body=${encodedMessage}`;
        window.location.href = smsLink;
    };

    return (
        <button 
            onClick={handleInvite} 
            className={`invite-button ${iconOnly ? 'icon-only' : ''}`}
            title="Invite Friends"
        >
            {icon}
            {!iconOnly && 'Invite Friends'}
        </button>
    );
};

export default InviteButton;
