import { 
    collection, 
    query, 
    orderBy, 
    limit, 
    startAfter, 
    getDocs,
    where,
    Timestamp 
} from 'firebase/firestore';
import { firestore } from './firebase';

const NOTIFICATIONS_PER_PAGE = 20;
const MAX_PAGES = 10;
const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;

// Fetch notifications for a user
export const fetchNotifications = async (userId, lastVisible = null, pageCount = 1) => {
    try {
        // If we've already loaded 10 pages, don't fetch more
        if (pageCount > MAX_PAGES) {
            return { activities: [], lastVisible: null, hasMore: false };
        }

        let notificationsQuery;

        // Only apply the 3-day limit if we're past page 3
        if (pageCount > 3) {
            const threeDaysAgo = new Date(Date.now() - THREE_DAYS_IN_MS);
            const timestamp = Timestamp.fromDate(threeDaysAgo);

            notificationsQuery = lastVisible ? 
                query(
                    collection(firestore, `users/${userId}/notifications`),
                    where('timestamp', '>=', timestamp),
                    orderBy('timestamp', 'desc'),
                    startAfter(lastVisible),
                    limit(NOTIFICATIONS_PER_PAGE)
                ) :
                query(
                    collection(firestore, `users/${userId}/notifications`),
                    where('timestamp', '>=', timestamp),
                    orderBy('timestamp', 'desc'),
                    limit(NOTIFICATIONS_PER_PAGE)
                );
        } else {
            notificationsQuery = lastVisible ? 
                query(
                    collection(firestore, `users/${userId}/notifications`),
                    orderBy('timestamp', 'desc'),
                    startAfter(lastVisible),
                    limit(NOTIFICATIONS_PER_PAGE)
                ) :
                query(
                    collection(firestore, `users/${userId}/notifications`),
                    orderBy('timestamp', 'desc'),
                    limit(NOTIFICATIONS_PER_PAGE)
                );
        }

        const snapshot = await getDocs(notificationsQuery);
        const activities = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(activity => activity.actorId !== userId); // Filter out self-notifications

        return {
            activities,
            lastVisible: snapshot.docs[snapshot.docs.length - 1],
            hasMore: snapshot.docs.length === NOTIFICATIONS_PER_PAGE && pageCount < MAX_PAGES
        };
    } catch (error) {
        console.error('Error fetching notifications:', error);
        return { activities: [], lastVisible: null, hasMore: false };
    }
};

// Fetch notifications from the old notifications collection
export const fetchOldNotifications = async (userId, lastVisible = null, pageSize = NOTIFICATIONS_PER_PAGE) => {
    try {
        const notificationsRef = collection(firestore, 'notifications');
        const baseQuery = query(
            notificationsRef,
            where('userId', '==', userId),
            orderBy('timestamp', 'desc'),
            limit(pageSize)
        );

        const querySnapshot = await getDocs(baseQuery);
        const notifications = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        return {
            activities: notifications,
            lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
            hasMore: notifications.length === pageSize
        };
    } catch (error) {
        console.error('Error fetching old notifications:', error);
        throw error;
    }
};

// Fetch notifications feed for a user
export const fetchUserFeed = async (userId, following = [], favoriteGyms = [], lastVisible = null) => {
    try {
        // Get user's own notifications
        const userNotifications = await fetchNotifications(userId, lastVisible);
        
        // Get notifications from followed users
        const followedUsersNotifications = await Promise.all(
            following.map(async followedId => {
                const result = await fetchNotifications(followedId, lastVisible);
                return result.activities;
            })
        );

        // Combine all notifications
        let allActivities = [
            ...userNotifications.activities,
            ...followedUsersNotifications.flat()
        ];

        // Sort by timestamp
        allActivities.sort((a, b) => {
            const timeA = b.timestamp?.seconds || b.timestamp;
            const timeB = a.timestamp?.seconds || a.timestamp;
            return timeA - timeB;
        });

        // Take only the first NOTIFICATIONS_PER_PAGE items
        const paginatedActivities = allActivities.slice(0, NOTIFICATIONS_PER_PAGE);

        return {
            activities: paginatedActivities,
            lastVisible: paginatedActivities[paginatedActivities.length - 1]?.timestamp,
            hasMore: allActivities.length > NOTIFICATIONS_PER_PAGE
        };
    } catch (error) {
        console.error('Error fetching user feed:', error);
        return { activities: [], lastVisible: null, hasMore: false };
    }
};

// Fetch notifications for a specific gym
export const fetchGymNotifications = async (gymId, lastVisible = null) => {
    try {
        let notificationsQuery = query(
            collection(firestore, `locations/${gymId}/notifications`),
            orderBy('timestamp', 'desc'),
            limit(NOTIFICATIONS_PER_PAGE)
        );

        if (lastVisible) {
            notificationsQuery = query(
                collection(firestore, `locations/${gymId}/notifications`),
                orderBy('timestamp', 'desc'),
                startAfter(lastVisible),
                limit(NOTIFICATIONS_PER_PAGE)
            );
        }

        const snapshot = await getDocs(notificationsQuery);
        return {
            activities: snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
            lastVisible: snapshot.docs[snapshot.docs.length - 1],
            hasMore: snapshot.docs.length === NOTIFICATIONS_PER_PAGE
        };
    } catch (error) {
        console.error('Error fetching gym notifications:', error);
        return { activities: [], lastVisible: null, hasMore: false };
    }
};

// Fetch notifications for a specific user's profile
export const fetchUserNotifications = async (userId, lastVisible = null) => {
    try {
        let notificationsQuery = query(
            collection(firestore, `users/${userId}/notifications`),
            orderBy('timestamp', 'desc'),
            limit(NOTIFICATIONS_PER_PAGE)
        );

        if (lastVisible) {
            notificationsQuery = query(
                collection(firestore, `users/${userId}/notifications`),
                orderBy('timestamp', 'desc'),
                startAfter(lastVisible),
                limit(NOTIFICATIONS_PER_PAGE)
            );
        }

        const snapshot = await getDocs(notificationsQuery);
        return {
            activities: snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
            lastVisible: snapshot.docs[snapshot.docs.length - 1],
            hasMore: snapshot.docs.length === NOTIFICATIONS_PER_PAGE
        };
    } catch (error) {
        console.error('Error fetching user notifications:', error);
        return { activities: [], lastVisible: null, hasMore: false };
    }
};

// Fetch notifications for a specific page
export const fetchPageNotifications = async (pageId, lastVisible = null) => {
    try {
        let notificationsQuery = query(
            collection(firestore, `pages/${pageId}/notifications`),
            orderBy('timestamp', 'desc'),
            limit(NOTIFICATIONS_PER_PAGE)
        );

        if (lastVisible) {
            notificationsQuery = query(
                collection(firestore, `pages/${pageId}/notifications`),
                orderBy('timestamp', 'desc'),
                startAfter(lastVisible),
                limit(NOTIFICATIONS_PER_PAGE)
            );
        }

        const snapshot = await getDocs(notificationsQuery);
        return {
            activities: snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
            lastVisible: snapshot.docs[snapshot.docs.length - 1],
            hasMore: snapshot.docs.length === NOTIFICATIONS_PER_PAGE
        };
    } catch (error) {
        console.error('Error fetching page notifications:', error);
        return { activities: [], lastVisible: null, hasMore: false };
    }
}; 

