import React from 'react';
import { Link } from 'react-router-dom';

const GymPinNotificationItem = ({ data }) => {
    return (
        <div className="notification-item gym-pin-notification-item">
            <div className="notification-item-header">
                <h3>{data.displayName} pinned a gym!</h3>
            </div>
            <p>Gym: <Link to={`/gym/${data.gymId}`}>{data.gymName}</Link></p>
            <p>On {new Date(data.timestamp.toDate()).toLocaleDateString()}</p>
        </div>
    );
};

export default GymPinNotificationItem;
