import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../../services/firebase';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import { toggleGymFavorite } from '../../services/gymService';
import {
    RightSidebar as StyledRightSidebar,
    SuggestionsContainer,
    SuggestionItem,
    SuggestionInfo,
    SuggestionAvatar,
    SuggestionDetails,
    SuggestionButton,
} from '../../styles/layout/Sidebar.styles';

const RightSidebar = ({ suggestedUsers = [], suggestedGyms = [] }) => {
    const [user] = useAuthState(auth);
    const [following, setFollowing] = useState([]);
    const [favoriteGyms, setFavoriteGyms] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            if (!user) return;
            
            const userDoc = await getDoc(doc(firestore, 'users', user.uid));
            const userData = userDoc.data();
            setFollowing(userData?.following || []);
            setFavoriteGyms(userData?.favorites || []);
        };

        fetchUserData();
    }, [user]);

    const handleFollow = async (userToFollow) => {
        if (!user) return;
        
        const userRef = doc(firestore, 'users', user.uid);
        const followedRef = doc(firestore, 'users', userToFollow.id);

        try {
            // Update the current user's following field
            await updateDoc(userRef, {
                following: arrayUnion(userToFollow.id)
            });

            // Update the followed user's followers field
            await updateDoc(followedRef, {
                followers: arrayUnion(user.uid)
            });

            setFollowing(prev => [...prev, userToFollow.id]);
        } catch (error) {
            console.error('Error following user:', error);
        }
    };

    const handleUnfollow = async (userToUnfollow) => {
        if (!user) return;
        
        const userRef = doc(firestore, 'users', user.uid);
        const followedRef = doc(firestore, 'users', userToUnfollow.id);

        try {
            await updateDoc(userRef, {
                following: arrayRemove(userToUnfollow.id)
            });

            await updateDoc(followedRef, {
                followers: arrayRemove(user.uid)
            });

            setFollowing(prev => prev.filter(id => id !== userToUnfollow.id));
        } catch (error) {
            console.error('Error unfollowing user:', error);
        }
    };

    const handleToggleFavorite = async (gym) => {
        if (!user) return;
        
        try {
            const isFavorite = favoriteGyms.includes(gym.id);
            await toggleGymFavorite(isFavorite, user, gym.id, gym);
            
            if (isFavorite) {
                setFavoriteGyms(prev => prev.filter(id => id !== gym.id));
            } else {
                setFavoriteGyms(prev => [...prev, gym.id]);
            }
        } catch (error) {
            console.error('Error toggling gym favorite:', error);
        }
    };

    return (
        <StyledRightSidebar>
            {suggestedUsers.length > 0 && (
                <SuggestionsContainer>
                    <h3>Suggested Users</h3>
                    {suggestedUsers.map(suggestedUser => (
                        <SuggestionItem key={suggestedUser.id}>
                            <SuggestionInfo>
                                <SuggestionAvatar 
                                    src={suggestedUser.photoURL || '/default-avatar.png'} 
                                    alt={suggestedUser.displayName}
                                />
                                <SuggestionDetails>
                                    <h4>{suggestedUser.displayName}</h4>
                                    <p>{suggestedUser.beltRank || 'White'} Belt</p>
                                </SuggestionDetails>
                            </SuggestionInfo>
                            <SuggestionButton 
                                onClick={() => following.includes(suggestedUser.id) ? 
                                    handleUnfollow(suggestedUser) : handleFollow(suggestedUser)}
                            >
                                {following.includes(suggestedUser.id) ? 'Following' : 'Follow'}
                            </SuggestionButton>
                        </SuggestionItem>
                    ))}
                </SuggestionsContainer>
            )}

            {suggestedGyms.length > 0 && (
                <SuggestionsContainer>
                    <h3>Suggested Gyms</h3>
                    {suggestedGyms.map(gym => (
                        <SuggestionItem key={gym.id}>
                            <SuggestionInfo>
                                <SuggestionAvatar 
                                    src={gym.photoURL || '/default-gym.png'} 
                                    alt={gym.name}
                                />
                                <SuggestionDetails>
                                    <h4>{gym.name}</h4>
                                    <p>{gym.location}</p>
                                </SuggestionDetails>
                            </SuggestionInfo>
                            <SuggestionButton onClick={() => handleToggleFavorite(gym)}>
                                {favoriteGyms.includes(gym.id) ? 'Favorited' : 'Favorite'}
                            </SuggestionButton>
                        </SuggestionItem>
                    ))}
                </SuggestionsContainer>
            )}
        </StyledRightSidebar>
    );
};

export default RightSidebar; 