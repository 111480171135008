import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useComments } from '../../hooks/useComments';
import { 
    Avatar, 
    CardHeader, 
    Typography, 
    Box, 
    Divider, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Menu
} from '@mui/material';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import CommentForm from '../forms/CommentForm';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IconButton from '@mui/material/IconButton';
import '../../styles/CommentsSection.css';
import 'react-quill/dist/quill.snow.css';
import { getUserItems } from '../../services/firestoreService';
import { addComment, toggleLike, toggleReplyLike } from '../../services/commentsService';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../services/firebase';
import { Link } from 'react-router-dom';
import { POST_VISIBILITY } from '../../constants/visibility';
import {
    CommentCard,
    CommentContent,
    ReplyContainer,
    ActionButton,
    ReplyForm,
    CommentsContainer
} from '../../styles/Comments.styled';
import ReactQuill from 'react-quill';
import styled from '@emotion/styled';
import VideoPlayer from '../VideoPlayer';
import { formatDistanceToNow } from 'date-fns';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { generateShortId } from '../../utils/shortId';

const beltColors = {
    white: '#FFFFFF',
    blue: '#0000FF',
    purple: '#800080',
    brown: '#8B4513',
    black: '#000000',
    default: '#FF0000'  // red as default
};

const getBeltColor = (beltRank) => beltColors[beltRank?.toLowerCase()] || beltColors.default;

const formatDate = (timestamp) => {
    if (!timestamp) return null;
    
    let date;
    if (timestamp instanceof Date) {
        date = timestamp;
    } else if (timestamp.seconds) {
        // Firebase Timestamp
        date = new Date(timestamp.seconds * 1000);
    } else if (typeof timestamp === 'object' && timestamp.toDate) {
        // Firestore Timestamp
        date = timestamp.toDate();
    } else if (typeof timestamp === 'number') {
        // Unix timestamp in milliseconds
        date = new Date(timestamp);
    } else {
        return null;
    }

    // Validate the date
    if (isNaN(date.getTime())) {
        return null;
    }

    return date;
};

const Reply = ({ reply, onDelete, currentUser, commentId, onToggleLike }) => {
    return (
        <CommentCard>
            <CardHeader
                avatar={
                    <Link to={`/profile/${reply.userId}`}>
                        <Avatar src={reply.profilePicUrl || 'default-profile.png'} sx={{ width: 32, height: 32 }} />
                    </Link>
                }
                title={
                    <Link to={`/profile/${reply.userId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {reply.userName}
                        </Typography>
                    </Link>
                }
                subheader={
                    <Typography 
                        variant="caption" 
                        color="text.secondary"
                        title={reply.createdAt ? new Date(reply.createdAt.seconds * 1000).toLocaleString() : 'Unknown date'}
                    >
                        {formatDate(reply.createdAt) && formatDistanceToNow(formatDate(reply.createdAt), { addSuffix: true })}
                    </Typography>
                }
                action={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.25 }}>
                        <IconButton 
                            size="small" 
                            onClick={() => onToggleLike(commentId, reply.id)}
                        >
                            {reply.likedBy?.includes(currentUser?.uid) ? (
                                <FavoriteIcon sx={{ color: getBeltColor(reply.userBeltRank) }} />
                            ) : (
                                <FavoriteBorderIcon />
                            )}
                        </IconButton>
                        <Typography variant="caption" sx={{ ml: -0.5 }}>{reply.likedBy?.length || 0}</Typography>
                    </Box>
                }
            />
            <CommentContent>
                <div 
                    className="ql-editor" 
                    dangerouslySetInnerHTML={{ 
                        __html: typeof reply.text === 'object' ? reply.text.text : reply.text 
                    }} 
                />
                {currentUser && currentUser.uid === reply.userId && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                        <ActionButton 
                            className="delete"
                            onClick={() => onDelete(commentId, reply.id)}
                        >
                            Delete
                        </ActionButton>
                    </Box>
                )}
            </CommentContent>
        </CommentCard>
    );
};

const CommentsSection = ({ 
    commentType, 
    itemId, 
    userId, 
    collectionType,
    openNewPost,
    setOpenNewPost,
    singlePostMode: propsSinglePostMode
}) => {
    const { user } = useAuth();
    const { 
        comments, 
        loading,
        hasMore,
        loadMore,
        addLocalComment,
        handleDeleteComment,
        handleDeleteReply,
        handleAddReply
    } = useComments(user, commentType || 'user', itemId, userId, collectionType || 'users');

    const [embedUrl, setEmbedUrl] = useState('');
    const [replyingTo, setReplyingTo] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteType, setDeleteType] = useState(null);
    const [deleteIds, setDeleteIds] = useState({});
    const [userBeltRanks, setUserBeltRanks] = useState({});
    const [isFollowing, setIsFollowing] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const replyInputRef = React.useRef();
    const [selectedTier, setSelectedTier] = useState('all');
    const [targetCommentId, setTargetCommentId] = useState(null);
    const [userProfiles, setUserProfiles] = useState({});
    const [singlePostMode, setSinglePostMode] = useState(propsSinglePostMode || false);
    const [singlePost, setSinglePost] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeCommentId, setActiveCommentId] = useState(null);

    useEffect(() => {
        const checkUserRelationship = async () => {
            if (user && userId && user.uid !== userId) {
                // Check if following
                const userDoc = await getDoc(doc(firestore, 'users', user.uid));
                const userData = userDoc.data();
                setIsFollowing(userData?.following?.includes(userId) || false);

                // Check if subscribed
                const subscriptionDoc = await getDoc(doc(firestore, `users/${userId}/subscribers/${user.uid}`));
                setIsSubscribed(subscriptionDoc.exists());
            }
        };

        checkUserRelationship();
    }, [user, userId]);

    const canViewPost = (post) => {
        if (!post.visibility || post.visibility === POST_VISIBILITY.PUBLIC) return true;
        if (!user) return false;
        if (user.uid === userId) return true;
        if (post.visibility === POST_VISIBILITY.FOLLOWERS) return isFollowing || isSubscribed;
        if (post.visibility === POST_VISIBILITY.SUBSCRIBERS) return isSubscribed;
        return false;
    };

    const filteredComments = comments.filter(comment => canViewPost(comment));

    useEffect(() => {
        const fetchUserBeltRank = async (uid) => {
            if (!uid || userBeltRanks[uid]) return;
            
            try {
                const userDoc = await getDoc(doc(firestore, 'users', uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setUserBeltRanks(prev => ({
                        ...prev,
                        [uid]: userData.beltRank || 'default'
                    }));
                }
            } catch (error) {
                console.error('Error fetching user belt rank:', error);
            }
        };

        comments.forEach(comment => {
            if (comment.likedBy) {
                comment.likedBy.forEach(likerUid => {
                    fetchUserBeltRank(likerUid);
                });
            }
            if (comment.replies) {
                comment.replies.forEach(reply => {
                    if (reply.likedBy) {
                        reply.likedBy.forEach(likerUid => {
                            fetchUserBeltRank(likerUid);
                        });
                    }
                });
            }
        });
    }, [comments, userBeltRanks]);

    const handleSubmit = async (commentData) => {
        if (!user) {
            console.error('User is not logged in');
            return;
        }
        
        try {
            const now = new Date();
            const commentToAdd = {
                ...commentData,
                userId: user.uid,
                userName: user.displayName || 'Anonymous',
                profilePicUrl: user.photoURL || 'default-profile.png',
                createdAt: { seconds: Math.floor(now.getTime() / 1000), nanoseconds: 0 },
                replies: [],
                likedBy: []
            };

            // Only generate shortId and update URL for user profile posts
            if (commentType === 'user') {
                const shortId = generateShortId();
                commentToAdd.shortId = shortId;
                
                const newComment = await addComment(userId, commentType, commentToAdd);
                setEmbedUrl('');
                
                // Update URL only for user profile posts
                const newUrl = userProfiles[user.uid]?.username ? 
                    `/${userProfiles[user.uid].username}/${shortId}` :
                    `/profile/${user.uid}/${shortId}`;
                window.history.pushState({}, '', newUrl);
                setTargetCommentId(shortId);
                
                const updatedComments = [{
                    ...commentToAdd,
                    id: newComment.id,
                    createdAt: commentToAdd.createdAt
                }, ...comments];
                
                addLocalComment(updatedComments);
            } else {
                // Handle gym comments
                const targetUserId = itemId; // For gym comments, itemId is the gymId
                const targetType = 'location';
                
                const newComment = await addComment(targetUserId, targetType, commentToAdd);
                setEmbedUrl('');
                
                // Update local state with the new comment
                const updatedComments = [{
                    ...commentToAdd,
                    id: newComment.id,
                    createdAt: commentToAdd.createdAt
                }, ...comments];
                
                addLocalComment(updatedComments);
            }
            
            setOpenNewPost(false);
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };

    const handleReplySubmit = async (commentId, replyText) => {
        try {
            if (!user) {
                console.error('User is not logged in');
                return;
            }
            const now = new Date();
            const replyData = {
                text: replyText,
                userId: user.uid,
                userName: user.displayName,
                profilePicUrl: user.photoURL || '',
                createdAt: { seconds: Math.floor(now.getTime() / 1000), nanoseconds: 0 }
            };
            const targetUserId = commentType === 'gym' ? itemId : 
                               commentType === 'event' ? itemId : userId;
            const targetType = commentType === 'gym' ? 'location' : 
                             commentType === 'event' ? `event/${userId}` : commentType;
            await handleAddReply(commentId, replyData, targetUserId, targetType);
            setReplyingTo(null);
            setReplyText('');
        } catch (error) {
            console.error('Error submitting reply:', error);
        }
    };

    const handleDeleteCommentLocal = (commentId) => {
        setDeleteType('comment');
        setDeleteIds({ commentId });
        setOpenDeleteDialog(true);
    };

    const handleToggleLikeLocal = async (commentId) => {
        try {
            if (!user) {
                console.error('User is not logged in');
                return;
            }
            const targetUserId = commentType === 'gym' ? itemId : 
                               commentType === 'event' ? itemId : userId;
            const targetType = commentType === 'gym' ? 'location' : 
                             commentType === 'event' ? `event/${userId}` : commentType;
            const updatedComment = await toggleLike(targetUserId, targetType, commentId, user.uid);
            
            // Update the comment in the local state
            const updatedComments = comments.map(comment => 
                comment.id === commentId ? { 
                    ...comment, 
                    ...updatedComment,
                    replies: comment.replies // Preserve the existing replies
                } : comment
            );
            addLocalComment(updatedComments);
        } catch (error) {
            console.error('Error toggling like:', error);
        }
    };

    const handleUrlChange = (url) => {
        let embedCode = '';
        if (url.includes('youtube.com') || url.includes('youtu.be')) {
            const videoId = url.split('v=')[1] || url.split('/').pop();
            embedCode = `https://www.youtube.com/embed/${videoId}`;
        } else if (url.includes('vimeo.com')) {
            const videoId = url.split('/').pop();
            embedCode = `https://player.vimeo.com/video/${videoId}`;
        } else if (url.includes('imgur.com')) {
            const imgurRegex = /(?:imgur\.com\/(?:a\/)?(?:gallery\/)?)([\w-]+)(?:\.(?:jpg|jpeg|png|gif))?/i;
            const match = url.match(imgurRegex);
            if (match && match[1]) {
                embedCode = `https://i.imgur.com/${match[1]}.jpg`;
            }
        }
        setEmbedUrl(embedCode);
    };

    const renderEmbed = (url) => {
        if (!url) return null;

        if (url.includes('youtube.com/embed/')) {
            return <iframe width="100%" height="315" src={url} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen className="comment-embed" title="YouTube video player"></iframe>;
        } else if (url.includes('player.vimeo.com/video/')) {
            return <iframe src={url} width="100%" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen className="comment-embed" title="Vimeo video player"></iframe>;
        } else if (url.includes('i.imgur.com')) {
            return <img src={url} alt="Imgur embedded content" className="comment-embed" />;
        }

        return null;
    };

    const handleDeleteReplyClick = (commentId, replyId) => {
        setDeleteType('reply');
        setDeleteIds({ commentId, replyId });
        setOpenDeleteDialog(true);
    };

    const handleConfirmDelete = async () => {
        if (deleteType === 'comment') {
            try {
                const success = await handleDeleteComment(deleteIds.commentId, userId, collectionType);
                if (!success) {
                    console.error("Failed to delete the comment");
                    alert("Failed to delete the comment. Please try again.");
                }
            } catch (error) {
                console.error("Error deleting the comment:", error);
                alert("An error occurred while deleting the comment. Please try again.");
            }
        } else if (deleteType === 'reply') {
            await handleDeleteReply(deleteIds.commentId, deleteIds.replyId);
        }
        setOpenDeleteDialog(false);
    };

    const handleToggleReplyLike = async (commentId, replyId) => {
        try {
            if (!user) {
                console.error('User is not logged in');
                return;
            }
            const targetUserId = commentType === 'gym' ? itemId : 
                               commentType === 'event' ? itemId : userId;
            const targetType = commentType === 'gym' ? 'location' : 
                             commentType === 'event' ? `event/${userId}` : commentType;
            const updatedReply = await toggleReplyLike(targetUserId, targetType, commentId, replyId, user.uid);
            
            const updatedComments = comments.map(comment => 
                comment.id === commentId 
                    ? {
                        ...comment,
                        replies: comment.replies.map(reply =>
                            reply.id === replyId
                                ? { ...reply, ...updatedReply }
                                : reply
                        )
                    }
                    : comment
            );
            addLocalComment(updatedComments);
        } catch (error) {
            console.error('Error toggling reply like:', error);
        }
    };

    const handleReplyClick = (commentId) => {
        setReplyingTo(commentId);
        // Use setTimeout to ensure the input is rendered before focusing
        setTimeout(() => {
            if (replyInputRef.current) {
                replyInputRef.current.focus();
            }
        }, 0);
    };

    const renderContent = (comment) => {
        return (
            <>
                <div 
                    className="ql-editor" 
                    dangerouslySetInnerHTML={{ 
                        __html: typeof comment.text === 'object' ? comment.text.text : comment.text 
                    }} 
                />
                {comment.embedUrl && renderEmbed(comment.embedUrl)}
                {comment.text?.videos && comment.text.videos.map((videoUrl, index) => (
                    <Box 
                        key={index} 
                        sx={{ 
                            mt: 2,
                            width: '100%',
                            maxWidth: '500px',
                            margin: '0 auto',
                            '& [data-vjs-player]': {
                                width: '100%',
                                height: '100%',
                                display: 'block'
                            },
                            '& .video-js': {
                                width: '100%',
                                height: '100%',
                                display: 'block'
                            }
                        }}
                    >
                        <VideoPlayer src={videoUrl} />
                    </Box>
                ))}
            </>
        );
    };

    useEffect(() => {
        const fetchComments = async () => {
            try {
                // Construct the proper path based on comment type
                const path = commentType === 'gym' ? `locations/${itemId}/comments` :
                           commentType === 'event' ? `locations/${itemId}/events/${userId}/comments` :
                           `users/${userId}/comments`;
                           
                const commentsRef = collection(firestore, path);
                const q = query(commentsRef, orderBy('createdAt', 'desc'));
                const snapshot = await getDocs(q);
                const fetchedComments = await Promise.all(snapshot.docs.map(async doc => {
                    const commentData = doc.data();
                    // If comment doesn't have a shortId, generate one and update the document
                    if (!commentData.shortId) {
                        const shortId = generateShortId();
                        await updateDoc(doc.ref, { shortId });
                        commentData.shortId = shortId;
                    }
                    return {
                        id: doc.id,
                        ...commentData,
                        createdAt: commentData.createdAt?.seconds ? 
                            new Date(commentData.createdAt.seconds * 1000) : 
                            commentData.createdAt
                    };
                }));
                addLocalComment(fetchedComments);
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };

        fetchComments();
    }, [commentType, itemId, userId]);

    // Add function to fetch user profile data
    const fetchUserProfile = async (uid) => {
        if (!uid || userProfiles[uid]) return;
        
        try {
            const userDoc = await getDoc(doc(firestore, 'users', uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setUserProfiles(prev => ({
                    ...prev,
                    [uid]: userData
                }));
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };

    // Fetch user profiles when comments change
    useEffect(() => {
        comments.forEach(comment => {
            fetchUserProfile(comment.userId);
        });
    }, [comments]);

    // Check if we're in single post mode (URL contains a shortId)
    useEffect(() => {
        const path = window.location.pathname;
        const matches = path.match(/\/(?:profile\/[^\/]+|[^\/]+)\/([A-Za-z0-9]{8})$/);
        if (matches && matches[1]) {
            const targetShortId = matches[1];
            // Find the specific post
            const post = comments.find(c => c.shortId === targetShortId);
            if (post) {
                setSinglePostMode(true);
                setSinglePost(post);
            } else {
                setSinglePostMode(false);
                setSinglePost(null);
            }
        } else {
            setSinglePostMode(false);
            setSinglePost(null);
        }
    }, [comments]);

    // Filter comments based on whether we're in single post mode
    const displayedComments = singlePostMode && singlePost ? [singlePost] : filteredComments;

    const handleOpenMenu = (event, comment) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setActiveCommentId(comment.shortId);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setActiveCommentId(null);
    };

    const handleCopyLink = (comment) => {
        const url = userProfiles[comment.userId]?.username ? 
            `/${userProfiles[comment.userId].username}/${comment.shortId}` :
            `/profile/${comment.userId}/${comment.shortId}`;
        navigator.clipboard.writeText(window.location.origin + url);
        handleCloseMenu();
    };

    return (
        <CommentsContainer>
            {!singlePostMode && (
                <CommentForm 
                    open={openNewPost}
                    onClose={() => setOpenNewPost(false)}
                    onSubmit={handleSubmit}
                    onUrlChange={handleUrlChange}
                    isCreator={user?.uid === userId}
                />
            )}

            <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
                {displayedComments.map((comment) => comment && (
                    <Box 
                        component="li" 
                        key={comment.id}
                        id={`comment-${comment.shortId}`}
                    >
                        <CommentCard 
                            sx={{ 
                                backgroundColor: '#1e1e1e',
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.05)'
                                }
                            }}
                        >
                            <CardHeader
                                avatar={
                                    <Link to={`/profile/${comment.userId}`}>
                                        <Avatar 
                                            src={comment.profilePicUrl || comment.user?.photoURL || 'default-profile.png'} 
                                            sx={{ width: 40, height: 40 }}
                                        />
                                    </Link>
                                }
                                title={
                                    <Link to={`/profile/${comment.userId}`} style={{ textDecoration: 'none', color: '#fff' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 600, color: '#fff' }}>
                                            {comment.userName || 'Anonymous'}
                                        </Typography>
                                    </Link>
                                }
                                action={
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleOpenMenu(e, comment)}
                                            sx={{ color: '#fff' }}
                                        >
                                            <MoreHorizIcon />
                                        </IconButton>
                                        <IconButton 
                                            size="small"
                                            onClick={() => handleToggleLikeLocal(comment.id)}
                                            sx={{ color: '#fff' }}
                                        >
                                            {comment.likedBy?.includes(user?.uid) ? (
                                                <FavoriteIcon sx={{ color: getBeltColor(userBeltRanks[user?.uid]) }} />
                                            ) : (
                                                <FavoriteBorderIcon />
                                            )}
                                        </IconButton>
                                        <Typography variant="caption" sx={{ ml: -0.5, color: '#fff' }}>
                                            {comment.likedBy?.length || 0}
                                        </Typography>
                                    </Box>
                                }
                                sx={{ py: 1, px: 2 }}
                            />
                            <Divider />
                            <CommentContent>
                                {renderContent(comment)}
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
                                    <ActionButton 
                                        className="reply"
                                        onClick={() => handleReplyClick(comment.id)}
                                    >
                                        Reply
                                    </ActionButton>
                                    {user && user.uid === comment.userId && (
                                        <ActionButton 
                                            className="delete"
                                            onClick={() => handleDeleteCommentLocal(comment.id)}
                                        >
                                            Delete
                                        </ActionButton>
                                    )}
                                </Box>
                            </CommentContent>
                            
                            {replyingTo === comment.id && (
                                <ReplyForm>
                                    <ReactQuill
                                        value={replyText}
                                        onChange={setReplyText}
                                        placeholder="Write a reply..."
                                        modules={{
                                            toolbar: [
                                                ['bold', 'italic', 'underline'],
                                                [{'list': 'ordered'}, {'list': 'bullet'}],
                                                ['link']
                                            ]
                                        }}
                                        theme="snow"
                                    />
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 3 }}>
                                        <Button 
                                            variant="outlined" 
                                            size="small"
                                            onClick={() => {
                                                setReplyingTo(null);
                                                setReplyText('');
                                            }}
                                            sx={{ 
                                                color: '#aaa',
                                                borderColor: '#666',
                                                '&:hover': {
                                                    borderColor: '#aaa',
                                                    backgroundColor: 'rgba(255, 255, 255, 0.05)'
                                                }
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button 
                                            variant="contained" 
                                            size="small"
                                            onClick={() => handleReplySubmit(comment.id, replyText)}
                                            disabled={!replyText.trim()}
                                            sx={{
                                                backgroundColor: '#1976d2',
                                                color: '#fff',
                                                '&:hover': {
                                                    backgroundColor: '#1565c0'
                                                },
                                                '&.Mui-disabled': {
                                                    backgroundColor: '#424242',
                                                    color: '#666'
                                                }
                                            }}
                                        >
                                            Submit Reply
                                        </Button>
                                    </Box>
                                </ReplyForm>
                            )}
                            
                            {comment.replies && comment.replies.length > 0 && (
                                <ReplyContainer className="reply">
                                    {comment.replies.map((reply) => (
                                        <Reply
                                            key={reply.id}
                                            reply={{ ...reply, userBeltRank: userBeltRanks[reply.userId] }}
                                            commentId={comment.id}
                                            onDelete={handleDeleteReplyClick}
                                            onToggleLike={handleToggleReplyLike}
                                            currentUser={user}
                                        />
                                    ))}
                                </ReplyContainer>
                            )}
                        </CommentCard>
                    </Box>
                ))}
            </Box>
            
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                PaperProps={{
                    sx: {
                        backgroundColor: '#242424',
                        color: '#fff',
                        '& .MuiMenuItem-root': {
                            fontSize: '14px',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)'
                            }
                        }
                    }
                }}
            >
                <MenuItem onClick={() => {
                    const comment = displayedComments.find(c => c.shortId === activeCommentId);
                    if (comment) handleCopyLink(comment);
                }}>
                    Copy link to post
                </MenuItem>
            </Menu>

            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                PaperProps={{
                    style: {
                        backgroundColor: '#242424',
                        color: '#fff'
                    }
                }}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#aaa' }}>
                        Are you sure you want to delete this {deleteType}? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} sx={{ color: '#fff' }}>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </CommentsContainer>
    );
};

export default CommentsSection;