import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import '../../styles/Modal.css';

Modal.setAppElement('#root');

const FavoriteGymsModal = ({ isOpen, onClose, favoriteGyms }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Favorite Gyms"
            className="edit-profile-modal"
            overlayClassName="edit-profile-modal-overlay"
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                }
            }}
        >
            <h2>Favorite Gyms</h2>
            <div className="form-group">
                {favoriteGyms && favoriteGyms.length > 0 ? (
                    favoriteGyms.map((gym) => (
                        <div key={gym.id} className="user-item">
                            <Link to={`/gym/${gym.id}`} className="user-info">
                                <img 
                                    src={gym.photoURL || '/default-gym.png'} 
                                    alt={gym.name} 
                                    className="user-avatar"
                                />
                                <div className="user-details">
                                    <h3>{gym.name}</h3>
                                    <p>{gym.location}</p>
                                </div>
                            </Link>
                        </div>
                    ))
                ) : (
                    <p>No favorite gyms yet</p>
                )}
            </div>
            <div className="form-actions">
                <button onClick={onClose}>Close</button>
            </div>
        </Modal>
    );
};

export default FavoriteGymsModal; 