// src/services/playlistService.js

import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../services/firebase';
import logActivity from '../utils/logActivity';
import { formatUserLink, formatEventLink, formatGymLink } from '../utils/formatLink';

// Validate and format Spotify playlist URL
const validateAndFormatSpotifyUrl = (url) => {
    if (!url) return { isValid: false, error: 'Please enter a Spotify playlist URL' };

    // Remove any trailing slashes and query parameters
    url = url.split('?')[0].replace(/\/$/, '');

    // Regular expressions for different Spotify URL formats
    const spotifyPatterns = [
        /^https?:\/\/open\.spotify\.com\/playlist\/([a-zA-Z0-9]+)$/,
        /^https?:\/\/open\.spotify\.com\/embed\/playlist\/([a-zA-Z0-9]+)$/,
        /^spotify:playlist:([a-zA-Z0-9]+)$/
    ];

    for (const pattern of spotifyPatterns) {
        const match = url.match(pattern);
        if (match) {
            const playlistId = match[1];
            return {
                isValid: true,
                embedLink: `https://open.spotify.com/embed/playlist/${playlistId}`
            };
        }
    }

    return { isValid: false, error: 'Invalid Spotify playlist URL. Please make sure you\'re sharing a playlist link.' };
};

// Save a Spotify playlist to an event
export const savePlaylist = async (user, event, location, newPlaylistShareLink, setPlaylistEmbedCode, setNewPlaylistShareLink, setError) => {
    if (!user || !event || !location?.locationId) {
        setError?.('Invalid event or location data');
        return false;
    }

    const { isValid, embedLink, error } = validateAndFormatSpotifyUrl(newPlaylistShareLink);
    if (!isValid) {
        setError?.(error);
        return false;
    }

    let eventDocRef;
    let activityMessage;

    // Check if it's a user event or gym event
    if (location.name) {
        // Gym event
        eventDocRef = doc(firestore, 'locations', location.locationId, 'events', event.id);
        activityMessage = `${formatUserLink(user.uid, user.displayName)} updated the playlist for: ${formatEventLink(location.locationId, event.id, event.title)} at: ${formatGymLink(location.locationId, location.name)}`;
    } else {
        // User event
        eventDocRef = doc(firestore, 'users', location.locationId, 'userEvents', event.id);
        activityMessage = `${formatUserLink(user.uid, user.displayName)} updated the playlist for: ${formatEventLink(location.locationId, event.id, event.title)}`;
    }

    const embedCode = `<iframe style="border-radius:12px" src="${embedLink}" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`;

    try {
        await updateDoc(eventDocRef, { playlistEmbedCode: embedCode });
        setPlaylistEmbedCode(embedCode);
        setNewPlaylistShareLink('');
        setError?.(null);

        await logActivity(user.uid, 'playlist_updated', {
            userId: user.uid,
            userName: user.displayName,
            eventId: event.id,
            eventTitle: event.title,
            userPhotoURL: user.photoURL,
            message: activityMessage
        });
        return true;
    } catch (error) {
        console.error('Error saving playlist:', error);
        setError?.('Failed to save the playlist. Please try again.');
        return false;
    }
};

// Remove a Spotify playlist from an event
export const removePlaylist = async (user, event, location, setPlaylistEmbedCode, setError) => {
    console.log('Debug - Service - Location:', location);
    console.log('Debug - Service - Event:', event);

    if (!user || !event || !location?.locationId) {
        console.log('Debug - Service - Missing data:', { 
            user: !!user, 
            event: !!event, 
            locationId: !!location?.locationId 
        });
        setError?.('Invalid event or location data');
        return false;
    }

    let eventDocRef;
    let activityMessage;

    // Check if it's a user event or gym event
    if (location.isUserEvent || location.type === 'user') {
        // User event
        eventDocRef = doc(firestore, 'users', location.locationId, 'userEvents', event.id);
        activityMessage = `${formatUserLink(user.uid, user.displayName)} removed the playlist for: ${formatEventLink(location.locationId, event.id, event.title)}`;
    } else {
        // Gym event
        eventDocRef = doc(firestore, 'locations', location.locationId, 'events', event.id);
        activityMessage = `${formatUserLink(user.uid, user.displayName)} removed the playlist for: ${formatEventLink(location.locationId, event.id, event.title)} at: ${formatGymLink(location.locationId, location.name)}`;
    }

    try {
        console.log('Debug - Service - Updating document at path:', eventDocRef.path);
        await updateDoc(eventDocRef, { 
            playlistEmbedCode: '',
            playlistId: null,
            playlistName: null,
            playlistOwner: null
        });
        
        setPlaylistEmbedCode('');
        setError?.(null);

        await logActivity(user.uid, 'playlist_removed', {
            userId: user.uid,
            userName: user.displayName,
            eventId: event.id,
            eventTitle: event.title,
            userPhotoURL: user.photoURL,
            message: activityMessage
        });
        return true;
    } catch (error) {
        console.error('Error removing playlist:', error);
        setError?.('Failed to remove the playlist. Please try again.');
        return false;
    }
};
