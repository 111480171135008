import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../services/firebase';

const UserContext = createContext();

export const UserData = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserData] = useState(null);
    const [userLoading, setUserLoading] = useState(true);

    useEffect(() => {
        let mounted = true;

        const fetchUserData = async () => {
            // Don't fetch if auth is still loading or there's no user
            if (loading || !user) {
                if (mounted) {
                    setUserData(null);
                    setUserLoading(false);
                }
                return;
            }

            try {
                // Ensure we have a valid user ID before proceeding
                if (!user.uid) {
                    console.warn("No valid user ID available");
                    if (mounted) {
                        setUserData(null);
                        setUserLoading(false);
                    }
                    return;
                }

                const userDocRef = doc(firestore, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);

                if (!mounted) return;

                if (userDoc.exists()) {
                    setUserData(userDoc.data());
                } else {
                    console.warn("User document does not exist.");
                    setUserData(null);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                if (mounted) {
                    setUserData(null);
                }
            } finally {
                if (mounted) {
                    setUserLoading(false);
                }
            }
        };

        fetchUserData();

        return () => {
            mounted = false;
        };
    }, [user, loading]);

    // Show loading state while authentication is initializing
    if (loading) {
        return <div>Initializing authentication...</div>;
    }

    // Show loading state while fetching user data
    if (userLoading) {
        return <div>Loading user data...</div>;
    }

    if (error) {
        console.error('Authentication error:', error);
        return <div>Authentication error: {error.message}</div>;
    }

    return (
        <UserContext.Provider value={{ user, userData, loading: userLoading }}>
            {children}
        </UserContext.Provider>
    );
};
