import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../services/firebase';

export const useAppLogic = () => {
    const [user, loading] = useAuthState(auth);
    const [isNewUser, setIsNewUser] = useState(false);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [intendedPath, setIntendedPath] = useState(null);

    useEffect(() => {
        const checkProfile = async () => {
            if (user) {
                const userRef = doc(firestore, 'users', user.uid);
                const userDoc = await getDoc(userRef);
                const isNew = !userDoc.exists();
                setIsNewUser(isNew);
                
                // Show welcome modal for new users or if it hasn't been seen
                if (isNew || !localStorage.getItem('welcomeModalSeen')) {
                    setShowWelcomeModal(true);
                }
            } else {
                setShowWelcomeModal(false);
            }
        };

        if (!loading) {
            checkProfile();
        }
    }, [user, loading]);

    const dismissWelcomeModal = () => {
        setShowWelcomeModal(false);
        localStorage.setItem('welcomeModalSeen', 'true');
    };

    const requireAuth = (path) => {
        if (!user) {
            setIntendedPath(path);
            setShowLoginModal(true);
            return false;
        }
        return true;
    };

    return {
        user,
        loading,
        isNewUser,
        showWelcomeModal,
        showLoginModal,
        setShowLoginModal,
        requireAuth,
        dismissWelcomeModal,
        intendedPath
    };
}; 