import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../services/firebase';

export const useRole = () => {
  const [user] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkRoles = async () => {
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setIsAdmin(!!userData.role?.admin);
          setIsCreator(!!userData.role?.creator);
        }
      }
      setLoading(false);
    };

    checkRoles();
  }, [user]);

  return { isAdmin, isCreator, loading };
}; 