import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore } from './firebase';

const SPOTIFY_CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const SPOTIFY_REDIRECT_URI = `${window.location.origin}/spotify-callback`;
const SPOTIFY_SCOPES = [
    'user-read-private',
    'user-read-email',
    'playlist-read-private',
    'playlist-read-collaborative',
    'playlist-modify-public',
    'playlist-modify-private',
    'user-read-currently-playing',
    'user-read-recently-played'
].join(' ');

// Initialize Spotify OAuth
export const initializeSpotifyAuth = () => {
    const state = generateRandomString(16);
    localStorage.setItem('spotify_auth_state', state);

    const authUrl = new URL('https://accounts.spotify.com/authorize');
    authUrl.searchParams.append('response_type', 'code');
    authUrl.searchParams.append('client_id', SPOTIFY_CLIENT_ID);
    authUrl.searchParams.append('scope', SPOTIFY_SCOPES);
    authUrl.searchParams.append('redirect_uri', SPOTIFY_REDIRECT_URI);
    authUrl.searchParams.append('state', state);

    window.location.href = authUrl.toString();
};

// Handle Spotify OAuth callback
export const handleSpotifyCallback = async (code) => {
    try {
        const basicAuth = btoa(`${SPOTIFY_CLIENT_ID}:${process.env.REACT_APP_SPOTIFY_CLIENT_SECRET}`);
        const response = await fetch('https://accounts.spotify.com/api/token', {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${basicAuth}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                grant_type: 'authorization_code',
                code,
                redirect_uri: SPOTIFY_REDIRECT_URI,
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Spotify token error:', errorData);
            throw new Error(errorData.error_description || 'Failed to get access token');
        }

        const data = await response.json();
        return {
            accessToken: data.access_token,
            refreshToken: data.refresh_token,
            expiresIn: data.expires_in,
        };
    } catch (error) {
        console.error('Error handling Spotify callback:', error);
        throw error;
    }
};

// Save Spotify tokens to user profile
export const saveSpotifyTokens = async (userId, tokens) => {
    const userRef = doc(firestore, 'users', userId);
    await updateDoc(userRef, {
        spotifyTokens: {
            ...tokens,
            timestamp: Date.now(),
        },
    });
};

// Refresh Spotify access token
export const refreshSpotifyToken = async (refreshToken) => {
    try {
        const basicAuth = btoa(`${SPOTIFY_CLIENT_ID}:${process.env.REACT_APP_SPOTIFY_CLIENT_SECRET}`);
        const response = await fetch('https://accounts.spotify.com/api/token', {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${basicAuth}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                grant_type: 'refresh_token',
                refresh_token: refreshToken,
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Spotify token refresh error:', errorData);
            throw new Error(errorData.error_description || 'Failed to refresh access token');
        }

        const data = await response.json();
        return {
            accessToken: data.access_token,
            refreshToken: data.refresh_token || refreshToken, // Some responses don't include a new refresh token
            expiresIn: data.expires_in,
        };
    } catch (error) {
        console.error('Error refreshing Spotify token:', error);
        throw error;
    }
};

// Get user's playlists with automatic token refresh
export const getUserPlaylists = async (accessToken, userId) => {
    try {
        const response = await fetch('https://api.spotify.com/v1/me/playlists', {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (response.status === 401) {
            // Token expired, try to refresh
            const userDoc = await getDoc(doc(firestore, 'users', userId));
            const userData = userDoc.data();
            
            if (!userData?.spotifyTokens?.refreshToken) {
                throw new Error('No refresh token available');
            }

            const newTokens = await refreshSpotifyToken(userData.spotifyTokens.refreshToken);
            await saveSpotifyTokens(userId, newTokens);

            // Retry with new access token
            const retryResponse = await fetch('https://api.spotify.com/v1/me/playlists', {
                headers: {
                    'Authorization': `Bearer ${newTokens.accessToken}`,
                },
            });

            if (!retryResponse.ok) {
                throw new Error('Failed to fetch playlists after token refresh');
            }

            const data = await retryResponse.json();
            return data.items;
        }

        if (!response.ok) {
            throw new Error('Failed to fetch playlists');
        }

        const data = await response.json();
        return data.items;
    } catch (error) {
        console.error('Error fetching playlists:', error);
        throw error;
    }
};

// Create a new collaborative playlist
export const createCollaborativePlaylist = async (accessToken, userId, name, description) => {
    try {
        const userResponse = await fetch('https://api.spotify.com/v1/me', {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });
        const userData = await userResponse.json();

        const response = await fetch(`https://api.spotify.com/v1/users/${userData.id}/playlists`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name,
                description,
                public: true,
                collaborative: true,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to create playlist');
        }

        const playlist = await response.json();
        return playlist;
    } catch (error) {
        console.error('Error creating playlist:', error);
        throw error;
    }
};

// Get currently playing track
export const getCurrentlyPlaying = async (accessToken) => {
    try {
        const response = await fetch('https://api.spotify.com/v1/me/player/currently-playing', {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (response.status === 204) {
            return null; // No track currently playing
        }

        if (!response.ok) {
            throw new Error('Failed to fetch currently playing track');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching currently playing track:', error);
        throw error;
    }
};

// Helper function to generate random string for state parameter
const generateRandomString = (length) => {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let text = '';
    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}; 