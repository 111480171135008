import React from 'react';
import { 
    FacebookShareButton, TwitterShareButton, WhatsappShareButton, 
    TelegramShareButton, EmailShareButton,
    FacebookIcon, TwitterIcon, WhatsappIcon, 
    TelegramIcon, EmailIcon 
} from 'react-share';
import { FaLink, FaSms } from 'react-icons/fa';
import styled from 'styled-components';

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background: #242424;
    padding: 24px;
    border-radius: 12px;
    width: 320px;
    text-align: center;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 8px;

    h2 {
        margin: 0;
        color: white;
        font-size: 20px;
        flex-grow: 1;
        text-align: center;
    }

    button {
        background: none;
        border: none;
        color: #999;
        cursor: pointer;
        font-size: 24px;
        padding: 4px;
        line-height: 1;
        position: absolute;
        right: 16px;
        top: 16px;
        
        &:hover {
            color: white;
        }
    }
`;

const ShareGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    padding: 0 12px;
    
    /* Center the last row when it's not full */
    &::after {
        content: '';
        grid-column: span 1;
    }

    /* The Copy Link button */
    & > *:last-child {
        grid-column: 2;
    }
    
    button {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        color: white;
        width: 100%;
        
        span {
            font-size: 12px;
            margin-top: 4px;
        }
    }

    .custom-icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .message-icon {
        background: #4CAF50;
    }

    .copy-icon {
        background: #2196F3;
    }
`;

const ShareModal = ({ isOpen, onClose, shareUrl, shareTitle, event, gymName }) => {
    if (!isOpen) return null;

    const handleSMSShare = () => {
        const eventStart = new Date(event.start.seconds * 1000).toLocaleString();
        const message = `You are invited to ${event.title} at ${gymName} on ${eventStart}. Check it out on our app!`;
        const eventLink = `${window.location.origin}/gym/${event.locationId}/event/${event.id}`;
        const encodedMessage = encodeURIComponent(`${message} ${eventLink}`);
        const smsLink = `sms:?body=${encodedMessage}`;
        window.location.href = smsLink;
    };

    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText(shareUrl);
            alert('Link copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy link:', err);
        }
    };

    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={e => e.stopPropagation()}>
                <ModalHeader>
                    <h2>Share Event</h2>
                    <button onClick={onClose}>×</button>
                </ModalHeader>
                <ShareGrid>
                    <FacebookShareButton url={shareUrl} quote={shareTitle}>
                        <FacebookIcon size={48} round />
                        <span>Facebook</span>
                    </FacebookShareButton>
                    
                    <TwitterShareButton url={shareUrl} title={shareTitle}>
                        <TwitterIcon size={48} round />
                        <span>Twitter</span>
                    </TwitterShareButton>
                    
                    <WhatsappShareButton url={shareUrl} title={shareTitle}>
                        <WhatsappIcon size={48} round />
                        <span>WhatsApp</span>
                    </WhatsappShareButton>
                    
                    <TelegramShareButton url={shareUrl} title={shareTitle}>
                        <TelegramIcon size={48} round />
                        <span>Telegram</span>
                    </TelegramShareButton>
                    
                    <EmailShareButton url={shareUrl} subject={shareTitle} body={`Check out this event: ${shareUrl}`}>
                        <EmailIcon size={48} round />
                        <span>Email</span>
                    </EmailShareButton>

                    <button onClick={handleSMSShare}>
                        <div className="custom-icon message-icon">
                            <FaSms size={24} color="white" />
                        </div>
                        <span>Message</span>
                    </button>

                    <button onClick={handleCopyLink}>
                        <div className="custom-icon copy-icon">
                            <FaLink size={24} color="white" />
                        </div>
                        <span>Copy Link</span>
                    </button>
                </ShareGrid>
            </ModalContent>
        </ModalOverlay>
    );
};

export default ShareModal; 