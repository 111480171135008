import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { firestore } from '../services/firebase.js';
import { doc, getDoc } from 'firebase/firestore';
import { formatEventLink, formatGymLink } from './formatLink.js';

export const logActivity = async (sourceId, sourceType, message, type, details) => {
    if (!sourceId || !sourceType || !message || !type || !details) {
        console.error("Missing required fields for logging activity:", { sourceId, sourceType, message, type, details });
        return;
    }

    const activityData = {
        message,
        type,
        details,
        timestamp: serverTimestamp(),
    };

    if (details.userPhotoURL) {
        activityData.userPhotoURL = details.userPhotoURL;
    }

    try {
        // Add to the source's notifications collection (user/gym/page)
        const sourcePath = sourceType === 'user' 
            ? `users/${sourceId}/notifications`
            : sourceType === 'gym' 
                ? `locations/${sourceId}/notifications`
                : `pages/${sourceId}/notifications`;
                
        await addDoc(collection(firestore, sourcePath), activityData);
    } catch (error) {
        console.error("Error logging activity: ", error);
    }
};

// Log activity to user's feed when they follow someone
export const logFollowActivity = async (followerId, followerName, followedId, followedName, followerPhotoURL) => {
    const message = `<a href="/profile/${followerId}"><strong>${followerName}</strong></a> started following you`;
    const details = {
        followerId,
        followerName,
        followedId,
        followedName,
        userPhotoURL: followerPhotoURL || '',
    };
    // Log in the follower's activity feed
    await logActivity(followerId, 'user', message, 'follow', details);
};

// Log activity to user's feed when they unfollow someone
export const logUnfollowActivity = async (followerId, followerName, followedId, followedName, followerPhotoURL) => {
    const message = `<a href="/profile/${followerId}"><strong>${followerName}</strong></a> unfollowed you`;
    const details = {
        followerId,
        followerName,
        followedId,
        followedName,
        userPhotoURL: followerPhotoURL || '',
    };
    // Log in the follower's activity feed
    await logActivity(followerId, 'user', message, 'unfollow', details);
};

// Log activity to the content's feed when someone comments
export const logCommentActivity = async (
    commenterId,
    commenterName,
    commentText,
    commentId,
    userPhotoURL,
    contentId,
    contentType
) => {
    if (!commenterId || !commenterName || !commentText || !commentId || !contentId || !contentType) {
        throw new Error("Missing required fields for logging activity.");
    }

    const message = `<a href="/profile/${commenterId}"><strong>${commenterName}</strong></a> commented: "${commentText}"`;
    const details = {
        commenterId,
        commenterName,
        commentText,
        commentId,
        userPhotoURL: userPhotoURL || "",
    };

    // Log in the content's activity feed
    await logActivity(contentId, contentType, message, 'comment', details);
};

// Log activity to the original comment's feed when someone replies
export const logReplyActivity = async (replierId, replierName, repliedToCommentId, replyText, replierPhotoURL, contentId, contentType, profilePageId) => {
    const message = `<a href="/profile/${replierId}"><strong>${replierName}</strong></a> replied to a <a href="/profile/${profilePageId}#comment-${repliedToCommentId}"><strong>comment</strong></a>: "${replyText}"`;
    const details = {
        replierId,
        replierName,
        replyText,
        userPhotoURL: replierPhotoURL || '',
        repliedToCommentId,
    };
    // Log in the content's activity feed
    await logActivity(contentId, contentType, message, 'reply', details);
};

// Log activity to the content's feed when someone reacts
export const logReactionActivity = async (
    reactorId,
    reactorName,
    reactedToCommentId,
    reactionType,
    reactorPhotoURL = '',
    contentId,
    contentType,
    profilePageId,
    commentText = ''
) => {
    if (!reactorId || !reactorName || !reactedToCommentId || !reactionType || !contentId || !contentType) {
        console.error("Missing required fields for logging activity.");
        return;
    }

    const message = `<a href="/profile/${reactorId}"><strong>${reactorName}</strong></a> ${reactionType} a <a href="/profile/${profilePageId}#comment-${reactedToCommentId}">comment</a>: ${commentText}`;
    const details = {
        reactorId,
        reactorName,
        reactionType,
        userPhotoURL: reactorPhotoURL,
        reactedToCommentId,
    };
    // Log in the content's activity feed
    await logActivity(contentId, contentType, message, 'reaction', details);
};

// Log activity to the gym's feed when an event is updated
export const logEventUpdateActivity = async (
    userId,
    userName,
    eventId,
    eventTitle,
    gymId,
    gymName,
    userPhotoURL,
    updatedFields
) => {
    const message = `<a href="/profile/${userId}"><strong>${userName}</strong></a> updated the event: ${formatEventLink(gymId, eventId, eventTitle)} at ${formatGymLink(gymId, gymName)}`;
    const details = {
        userId,
        userName,
        eventId,
        eventTitle,
        gymId,
        gymName,
        userPhotoURL: userPhotoURL || '',
        updatedFields
    };

    // Log in the gym's activity feed
    await logActivity(gymId, 'gym', message, 'event_updated', details);
};

// Log activity to the gym's feed for event reminders
export const logEventReminderActivity = async (
    eventId,
    eventTitle,
    gymId,
    gymName,
    startTime
) => {
    const timeUntilEvent = startTime - Date.now();
    const hoursUntilEvent = Math.floor(timeUntilEvent / (1000 * 60 * 60));
    
    let reminderMessage;
    if (hoursUntilEvent <= 1) {
        reminderMessage = `Event ${formatEventLink(gymId, eventId, eventTitle)} at ${formatGymLink(gymId, gymName)} starts in less than an hour!`;
    } else {
        reminderMessage = `Event ${formatEventLink(gymId, eventId, eventTitle)} at ${formatGymLink(gymId, gymName)} starts in ${hoursUntilEvent} hours.`;
    }

    const details = {
        eventId,
        eventTitle,
        gymId,
        gymName,
        startTime,
        hoursUntilEvent
    };

    // Log in the gym's activity feed
    await logActivity(gymId, 'gym', reminderMessage, 'event_reminder', details);
};

// Log activity to the gym's feed when it's updated
export const logGymUpdateActivity = async (
    userId,
    userName,
    gymId,
    gymName,
    userPhotoURL,
    updatedFields
) => {
    const message = `<a href="/profile/${userId}"><strong>${userName}</strong></a> updated ${formatGymLink(gymId, gymName)}`;
    const details = {
        userId,
        userName,
        gymId,
        gymName,
        userPhotoURL: userPhotoURL || '',
        updatedFields
    };

    // Log in the gym's activity feed
    await logActivity(gymId, 'gym', message, 'gym_updated', details);
};

// Log activity to the content's feed when someone is mentioned
export const logMentionActivity = async (
    mentionerId,
    mentionerName,
    mentionedUserId,
    mentionedUserName,
    mentionerPhotoURL,
    commentText,
    commentId,
    contentId,
    contentType
) => {
    const message = `<a href="/profile/${mentionerId}"><strong>${mentionerName}</strong></a> mentioned <a href="/profile/${mentionedUserId}"><strong>${mentionedUserName}</strong></a> in a comment: "${commentText}"`;
    const details = {
        mentionerId,
        mentionerName,
        mentionedUserId,
        mentionedUserName,
        userPhotoURL: mentionerPhotoURL || '',
        commentText,
        commentId
    };

    // Log in the content's activity feed
    await logActivity(contentId, contentType, message, 'mention', details);
};

export default logActivity;
