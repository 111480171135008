import { styled } from '@mui/material/styles';
import { Box, Card } from '@mui/material';

export const CommentFormCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'none'
}));

export const CommentFormContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const FormActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(1),
  gap: theme.spacing(1)
}));

export const MediaPreview = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& img, & iframe': {
    maxWidth: '100%',
    maxHeight: '200px',
    objectFit: 'contain'
  }
}));

export const QuillWrapper = styled(Box)`
    .ql-editor {
        min-height: 150px;
        color: #fff;
        font-size: 16px;
        width: 100%;

        p {
            width: 100%;
        }

        img {
            width: 100%;
            max-width: 100%;
            height: auto !important;
        }
    }

    .ql-toolbar {
        background-color: #363636;
        border: none !important;
        border-radius: 4px 4px 0 0;

        .ql-stroke {
            stroke: #fff;
        }

        .ql-fill {
            fill: #fff;
        }

        .ql-picker {
            color: #fff;
        }
    }

    .ql-container {
        background-color: #363636;
        border: none !important;
        border-radius: 0 0 4px 4px;
        font-size: 16px;
        width: 100%;
    }

    .ql-video-container {
        position: relative;
        padding-top: 16px;
        padding-bottom: 16px;
        
        .video-wrapper {
            position: relative;
            width: 100%;
            
            video {
                display: block;
                max-width: 100%;
                height: auto;
                margin: 0 auto;
            }
        }
    }
`; 