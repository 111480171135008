import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import '../../styles/EventsModal.css';

Modal.setAppElement('#root');

const EventsModal = ({ isOpen, onClose, events, title }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel={title || "Events"}
            className="events-modal"
            overlayClassName="events-modal-overlay"
        >
            <h2>{title || "Events"}</h2>
            <div className="events-grid">
                {events && events.length > 0 ? (
                    events.map((event) => (
                        <Link 
                            key={event.id} 
                            to={`/gym/${event.gymId}/event/${event.id}`} 
                            className="event-item"
                        >
                            <div className="event-details">
                                <h3>{event.title}</h3>
                                <p>
                                    {new Date(event.start).toLocaleDateString()} - {new Date(event.end).toLocaleDateString()}
                                </p>
                            </div>
                        </Link>
                    ))
                ) : (
                    <p>No events to display</p>
                )}
            </div>
            <div className="form-actions">
                <button onClick={onClose}>Close</button>
            </div>
        </Modal>
    );
};

export default EventsModal; 