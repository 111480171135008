import React from 'react';
import { Link } from 'react-router-dom';

const ReplyNotificationItem = ({ data }) => {
    return (
        <div className="notification-item reply-notification-item">
            <div className="notification-item-header">
                <h3>
                    <Link to={`/profile/${data.replierId}`}>
                        {data.replierName}
                    </Link>{' '}
                    replied to your comment!
                </h3>
            </div>
            <p>Reply: {data.replyText}</p>
            <p>On {new Date(data.timestamp.toDate()).toLocaleDateString()}</p>
        </div>
    );
};

export default ReplyNotificationItem;
