import React, { useEffect, useState } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../../services/firebase';
import '../../styles/ManageRoles.css';

const ManageRoles = () => {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [currentUser] = useAuthState(auth);

    useEffect(() => {
        const fetchUsers = async () => {
            const userCollection = collection(firestore, 'users');
            const userSnapshot = await getDocs(userCollection);
            const usersData = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUsers(usersData);

            // Initialize roles state with fetched users' roles
            const initialRoles = {};
            usersData.forEach(user => {
                initialRoles[user.id] = {
                    admin: user.role?.admin || false,
                    moderator: user.role?.moderator || false,
                    creator: user.role?.creator || false,
                    user: user.role?.user || true,
                };
            });
            setRoles(initialRoles);
        };

        fetchUsers();
    }, []);

    const handleRoleChange = (userId, role, value) => {
        setRoles(prevRoles => ({
            ...prevRoles,
            [userId]: {
                ...prevRoles[userId],
                [role]: value,
            },
        }));
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {
            const updatePromises = Object.keys(roles).map(async userId => {
                const userRef = doc(firestore, 'users', userId);
                await updateDoc(userRef, { role: roles[userId] });
            });
            await Promise.all(updatePromises);
            alert('Roles updated successfully!');
        } catch (error) {
            console.error('Error saving roles:', error);
            alert('Failed to update roles.');
        }
        setIsSaving(false);
    };

    return (
        <div className="manage-roles">
            <h1>Manage User Roles</h1>
            <table className="roles-table">
                <thead>
                <tr>
                    <th>User Name</th>
                    <th>Admin</th>
                    <th>Moderator</th>
                    <th>Creator</th>
                    <th>User</th>
                </tr>
                </thead>
                <tbody>
                {users.map(user => (
                    <tr key={user.id}>
                        <td>{user.displayName || 'Unknown User'}</td>
                        <td>
                            <input
                                type="checkbox"
                                checked={roles[user.id]?.admin || false}
                                onChange={e =>
                                    handleRoleChange(user.id, 'admin', e.target.checked)
                                }
                                disabled={user.id === currentUser?.uid} // Disable admin checkbox for the current user
                            />
                        </td>
                        <td>
                            <input
                                type="checkbox"
                                checked={roles[user.id]?.moderator || false}
                                onChange={e =>
                                    handleRoleChange(user.id, 'moderator', e.target.checked)
                                }
                            />
                        </td>
                        <td>
                            <input
                                type="checkbox"
                                checked={roles[user.id]?.creator || false}
                                onChange={e =>
                                    handleRoleChange(user.id, 'creator', e.target.checked)
                                }
                            />
                        </td>
                        <td>
                            <input
                                type="checkbox"
                                checked={roles[user.id]?.user || false}
                                onChange={e =>
                                    handleRoleChange(user.id, 'user', e.target.checked)
                                }
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <button onClick={handleSave} disabled={isSaving}>
                {isSaving ? 'Saving...' : 'Save Changes'}
            </button>
        </div>
    );
};

export default ManageRoles;
