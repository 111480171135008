import React from 'react';
import { useLoadScript } from '@react-google-maps/api';
import LoadingOverlay from '../common/LoadingOverlay';

const libraries = ['places', 'geometry'];

const GoogleMapsLoader = ({ children }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
        version: "weekly"
    });

    if (loadError) {
        console.error('Error loading Google Maps:', loadError);
        return <div>Error loading maps. Please try again later.</div>;
    }

    if (!isLoaded) {
        return <LoadingOverlay />;
    }

    return React.cloneElement(children, { isLoaded, loadError });
};

export { libraries };
export default GoogleMapsLoader; 