import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../services/firebase';
import '../styles/Login.css';

const Login = () => {
    const navigate = useNavigate();

    const handleGoogleSignIn = async () => {
        try {
            const provider = new GoogleAuthProvider();
            await signInWithPopup(auth, provider);
            navigate('/');
        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <h1>Welcome to Open Mat</h1>
                <p>Connect with the BJJ community</p>
                <button onClick={handleGoogleSignIn} className="google-sign-in">
                    Sign in with Google
                </button>
            </div>
        </div>
    );
};

export default Login; 