import styled from 'styled-components';
import { LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from './layout/Sidebar.styles';

// Layout constants
const CONTENT_MIN_WIDTH = '600px';
const CONTENT_MAX_WIDTH = '800px';

export const EventInfoLayout = styled.div`
  display: grid;
  grid-template-columns: ${LEFT_SIDEBAR_WIDTH} minmax(${CONTENT_MIN_WIDTH}, ${CONTENT_MAX_WIDTH}) ${RIGHT_SIDEBAR_WIDTH};
  gap: 12px;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background.primary};
  max-width: 1440px;
  margin: 0 auto;
  transition: all 0.3s ease;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 1400px) {
    grid-template-columns: ${LEFT_SIDEBAR_WIDTH} minmax(${CONTENT_MIN_WIDTH}, 1fr) 240px;
    gap: 8px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 80px minmax(${CONTENT_MIN_WIDTH}, 1fr) 80px;
    gap: 4px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    width: 100vw;
    max-width: 100vw;
  }
`;

export const MainContent = styled.main`
  width: 100%;
  margin: 0 auto;
  transition: all 0.3s ease;
  position: relative;
  flex: 1;
  overflow-y: auto;
  padding: 16px;

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    margin-bottom: 60px;
  }
`;

export const EventHeaderCard = styled.div`
  background: ${({ theme }) => theme.colors.background.paper};
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  @media (max-width: 768px) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
  }
`;

export const EventHeaderLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  text-align: left;

  .title-row {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    h1 {
      margin: 0;
      color: ${({ theme }) => theme.colors.text.primary};
      font-size: ${({ theme }) => theme.typography.fontSize.xxlarge};
      line-height: 1.2;
      text-align: left;
    }

    .action-icon {
      background: none;
      border: none;
      padding: 8px;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.text.secondary};
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      gap: 6px;

      span {
        font-size: 14px;
      }

      &:hover {
        color: ${({ theme }) => theme.colors.text.primary};
      }

      &.delete:hover {
        color: #FF4444;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

export const EventMetaInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  text-align: left;
`;

export const MetaItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.medium};
  text-align: left;

  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.colors.primary.main};
    margin-top: 2px;
  }

  a {
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: none;
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const EventHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  min-width: 240px;

  .header-actions {
    display: flex;
    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacing(1)};

    .action-icon {
      background: #2A2A2A;
      border: none;
      padding: 8px 12px;
      cursor: pointer;
      color: #E0E0E0;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 4px;

      span {
        font-size: 14px;
        font-weight: 500;
      }

      &:hover {
        background: #363636;
      }

      &.delete:hover {
        color: #FF4444;
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  h3 {
    margin: 0;
    color: ${({ theme }) => theme.colors.text.primary};
    font-size: ${({ theme }) => theme.typography.fontSize.large};
  }

  @media (max-width: 768px) {
    min-width: unset;
  }
`;

export const ShareIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  
  button {
    transition: transform 0.2s ease;
    
    &:hover {
      transform: scale(1.1);
    }

    &.copy-button {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: none;
      background-color: #333;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      &.success {
        background-color: #4caf50;
        color: #fff;
      }

      &:hover {
        background-color: #444;
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const EventCard = styled.div`
  background: ${({ theme }) => theme.colors.background.paper};
  padding: ${({ theme }) => theme.spacing(2.5)};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  h3 {
    margin: 0 0 ${({ theme }) => theme.spacing(2)};
    color: ${({ theme }) => theme.colors.text.primary};
    text-align: left;
  }

  .event-main-actions {
    display: flex;
    gap: ${({ theme }) => theme.spacing(1.5)};
    margin-top: ${({ theme }) => theme.spacing(3)};

    button {
      height: 36px;
      padding: 0 16px;
      border-radius: 0;
      border: none;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      &.attend {
        background-color: ${({ theme }) => theme.colors.primary.main};
        color: white;

        &:hover {
          background-color: ${({ theme }) => theme.colors.primary.dark};
        }
      }

      &.unattend {
        background-color: #2A2A2A;
        color: #E0E0E0;

        &:hover {
          background-color: #363636;
        }
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
`;

export const AttendeesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const AttendeeItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text.primary};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    span {
      font-size: 14px;
      text-align: center;
    }
  }

  .remove-attendance {
    position: absolute;
    bottom: 28px;
    right: calc(50% - 26px);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #2A2A2A;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #E0E0E0;
    transition: all 0.2s ease;

    svg {
      width: 12px;
      height: 12px;
    }

    &:hover {
      background: #363636;
    }
  }
`;

export const EventActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  margin-top: ${({ theme }) => theme.spacing(3)};
  justify-content: flex-end;
  align-items: stretch;

  > * {  // This targets both buttons and the InviteButton component
    min-width: 160px;
    height: 36px;
    padding: 0 16px;
    border-radius: 0;
    border: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  button {
    svg {
      width: 14px;
      height: 14px;
    }

    &.attend {
      background-color: ${({ theme }) => theme.colors.primary.main};
      color: white;

      &:hover {
        background-color: ${({ theme }) => theme.colors.primary.dark};
      }
    }

    &.unattend {
      background-color: #2A2A2A;
      color: #E0E0E0;

      &:hover {
        background-color: #363636;
      }
    }

    &.delete {
      background-color: transparent;
      color: #FF4444;
      border: 1px solid #FF4444;

      &:hover {
        background-color: rgba(255, 68, 68, 0.1);
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
    
    > * {
      width: 100%;
      min-width: unset;
    }
  }
`;

export const LocationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .address {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: ${({ theme }) => theme.typography.fontSize.small};
  }
`; 