import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../../services/firebase';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import '../../styles/UserListModal.css';

Modal.setAppElement('#root');

const FollowersModal = ({ isOpen, onClose, followers }) => {
    const [user] = useAuthState(auth);
    const [myFollowing, setMyFollowing] = useState([]);

    useEffect(() => {
        const fetchMyFollowing = async () => {
            if (!user) return;
            
            const myUserDoc = await getDoc(doc(firestore, 'users', user.uid));
            const myData = myUserDoc.data();
            setMyFollowing(myData?.following || []);
        };

        fetchMyFollowing();
    }, [user]);

    const handleFollow = async (followerToFollow) => {
        if (!user) return;
        
        const userRef = doc(firestore, 'users', user.uid);
        const followerRef = doc(firestore, 'users', followerToFollow.uid);

        if (myFollowing.includes(followerToFollow.uid)) {
            // Unfollow
            await updateDoc(userRef, {
                following: arrayRemove(followerToFollow.uid)
            });
            await updateDoc(followerRef, {
                followers: arrayRemove(user.uid)
            });
            setMyFollowing(prev => prev.filter(id => id !== followerToFollow.uid));
        } else {
            // Follow
            await updateDoc(userRef, {
                following: arrayUnion(followerToFollow.uid)
            });
            await updateDoc(followerRef, {
                followers: arrayUnion(user.uid)
            });
            setMyFollowing(prev => [...prev, followerToFollow.uid]);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Followers"
            className="user-list-modal"
            overlayClassName="user-list-modal-overlay"
        >
            <div className="modal-header">
                <h2>Followers</h2>
                <button className="close-button" onClick={onClose}>×</button>
            </div>
            <div className="user-list">
                {followers && followers.length > 0 ? (
                    followers.map((follower) => (
                        <div key={follower.uid} className="user-list-item">
                            <div className="user-list-info">
                                <div className="user-avatar-stack">
                                    <img 
                                        src={follower.photoURL || '/default-avatar.png'} 
                                        alt={follower.displayName} 
                                        className="user-avatar"
                                    />
                                    <span className="display-name">{follower.displayName}</span>
                                </div>
                            </div>
                            {user?.uid !== follower.uid && (
                                <Button 
                                    variant="contained"
                                    size="small"
                                    onClick={() => handleFollow(follower)}
                                    sx={{
                                        minWidth: '100px',
                                        backgroundColor: myFollowing.includes(follower.uid) ? '#242424' : '#1976d2',
                                        '&:hover': {
                                            backgroundColor: myFollowing.includes(follower.uid) ? '#333' : '#1565c0'
                                        }
                                    }}
                                >
                                    {myFollowing.includes(follower.uid) ? 'Following' : 'Follow'}
                                </Button>
                            )}
                        </div>
                    ))
                ) : (
                    <p>No followers yet</p>
                )}
            </div>
        </Modal>
    );
};

export default FollowersModal; 