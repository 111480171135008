import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../services/firebase';
import JiuJitsuReactions from '../../common/JiuJitsuReactions';
import '../../../styles/NotificationItem.css';

const defaultAvatarPath = '/assets/default-avatar.png';

const CommentNotificationItem = ({ data }) => {
    const [displayName, setDisplayName] = useState('');
    const [userPhotoURL, setUserPhotoURL] = useState('');
    const [commenterPhotoURL, setCommenterPhotoURL] = useState('');
    const [userPhotoError, setUserPhotoError] = useState(false);
    const [commenterPhotoError, setCommenterPhotoError] = useState(false);

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (data?.userId) {
                const userDoc = await getDoc(doc(firestore, 'users', data.userId));
                if (userDoc.exists()) {
                    setDisplayName(userDoc.data().displayName || 'another user');
                    setUserPhotoURL(userDoc.data().photoURL || defaultAvatarPath);
                } else {
                    setDisplayName('another user');
                }
            }

            if (data?.details?.commenterId) {
                const commenterDoc = await getDoc(doc(firestore, 'users', data.details.commenterId));
                if (commenterDoc.exists()) {
                    setCommenterPhotoURL(commenterDoc.data().photoURL || defaultAvatarPath);
                }
            }
        };

        fetchUserInfo();
    }, [data?.userId, data?.details?.commenterId]);

    const handleUserPhotoError = () => {
        setUserPhotoError(true);
    };

    const handleCommenterPhotoError = () => {
        setCommenterPhotoError(true);
    };

    // Log the data for debugging purposes
    console.log('CommentNotificationItem data:', data);

    if (!data || !data.details) {
        return <div>Error: Comment data is not available.</div>;
    }

    return (
        <div className="feed-item">
            <div className="feed-item-header">
                <h3>
                    {data.details.isSelfPost ? (
                        <>
                            <Link to={`/profile/${data.details.commenterId}`}>
                                <img
                                    src={commenterPhotoError ? defaultAvatarPath : (commenterPhotoURL || defaultAvatarPath)}
                                    alt={`${data.details.commenterName}'s profile`}
                                    className="profile-pic"
                                    onError={handleCommenterPhotoError}
                                />
                                {data.details.commenterName || 'A user'}
                            </Link>
                            {' '}posted:
                        </>
                    ) : (
                        <>
                            <Link to={`/profile/${data.details.commenterId}`}>
                                <img
                                    src={commenterPhotoError ? defaultAvatarPath : (commenterPhotoURL || defaultAvatarPath)}
                                    alt={`${data.details.commenterName}'s profile`}
                                    className="profile-pic"
                                    onError={handleCommenterPhotoError}
                                />
                                {data.details.commenterName || 'A user'}
                            </Link>
                            {' '}posted to{' '}
                            <Link to={`/profile/${data.userId}`}>
                                <img
                                    src={userPhotoError ? defaultAvatarPath : (userPhotoURL || defaultAvatarPath)}
                                    alt={`${displayName}'s profile`}
                                    className="profile-pic"
                                    onError={handleUserPhotoError}
                                />
                                {displayName || 'another user'}
                            </Link>'s profile:
                        </>
                    )}
                </h3>
            </div>
            <div className="feed-item-body">
                <p>{data.details.commentText}</p>
            </div>
            <p>On {new Date(data.timestamp?.toDate()).toLocaleDateString()}</p>
            <JiuJitsuReactions itemId={data.id} />
        </div>
    );
};

export default CommentNotificationItem;
