import React from 'react';
import PropTypes from 'prop-types';
import { formatRelativeTime } from '../../utils/dateUtils.js';
import '../../styles/NotificationItem.css';

// All items are notification items
import EventNotificationItem from './notificationItems/EventNotificationItem.js';
import MediaPostNotificationItem from './notificationItems/MediaPostNotificationItem.js';
import TrainingLogNotificationItem from './notificationItems/TrainingLogNotificationItem.js';
import MilestoneNotificationItem from './notificationItems/MilestoneNotificationItem.js';
import AchievementNotificationItem from './notificationItems/AchievementNotificationItem.js';
import FollowNotificationItem from './notificationItems/FollowNotificationItem.js';
import CommentNotificationItem from './notificationItems/CommentNotificationItem.js';
import NewUserNotificationItem from './notificationItems/NewUserNotificationItem.js';
import ReactionNotificationItem from './notificationItems/ReactionNotificationItem.js';
import ReplyNotificationItem from './notificationItems/ReplyNotificationItem.js';
import GymFavoriteNotificationItem from './notificationItems/GymFavoriteNotificationItem.js';
import GymPinNotificationItem from './notificationItems/GymPinNotificationItem.js';

const NotificationItem = ({ type, data, gymId, gymName, attendees }) => {
    try {
        // Add timestamp to data for all notification types
        const enrichedData = {
            ...data,
            formattedTimestamp: data.timestamp ? formatRelativeTime(data.timestamp.seconds ? data.timestamp.toDate() : new Date(data.timestamp)) : '',
            // Add source information
            sourceType: data.sourceType || 'user',
            sourceId: data.sourceId || data.userId,
            // Add user information
            userId: data.userId,
            userPhotoURL: data.userPhotoURL,
            userName: data.details?.userName || data.userName,
            // Add details
            details: {
                ...data.details,
                gymId: gymId || data.details?.gymId,
                gymName: gymName || data.details?.gymName,
                attendees: attendees || data.details?.attendees
            }
        };

        switch (type) {
            case 'event':
            case 'event_creation':
            case 'event_created':
                return <EventNotificationItem data={enrichedData} />;
            case 'event_deleted':
                return <EventNotificationItem data={enrichedData} isDeleted={true} />;
            case 'event_joined':
            case 'event_left':
                return <EventNotificationItem data={enrichedData} />;
            case 'follow':
            case 'unfollow':
                return <FollowNotificationItem data={enrichedData} />;
            case 'comment':
                return <CommentNotificationItem data={enrichedData} />;
            case 'newUser':
            case 'new_user':
                return <NewUserNotificationItem data={enrichedData} />;
            case 'milestone':
                return <MilestoneNotificationItem data={enrichedData} />;
            case 'achievement':
                return <AchievementNotificationItem data={enrichedData} />;
            case 'media_post':
                return <MediaPostNotificationItem data={enrichedData} />;
            case 'training_log':
                return <TrainingLogNotificationItem data={enrichedData} />;
            case 'gym_favorite':
            case 'gym_unfavorite':
                return <GymFavoriteNotificationItem data={enrichedData} />;
            case 'gym_pin':
                return <GymPinNotificationItem data={enrichedData} />;
            case 'reaction':
                return <ReactionNotificationItem data={enrichedData} />;
            case 'reply':
                return <ReplyNotificationItem data={enrichedData} />;
            default:
                return (
                    <div className="notification-item">
                        <div className="notification-content">
                            <span>{data.message || `Unknown activity type: ${type}`}</span>
                            <span className="timestamp">{enrichedData.formattedTimestamp}</span>
                        </div>
                    </div>
                );
        }
    } catch (error) {
        console.error('Error rendering notification:', error);
        return (
            <div className="notification-item error">
                <div className="notification-content">
                    <span>Error displaying notification</span>
                </div>
            </div>
        );
    }
};

NotificationItem.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    gymId: PropTypes.string,
    gymName: PropTypes.string,
    attendees: PropTypes.array
};

export default NotificationItem;
