import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import '../../styles/UserListModal.css';

Modal.setAppElement('#root');

const FollowersModal = ({ isOpen, onClose, followers }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Followers"
            className="user-list-modal"
            overlayClassName="user-list-modal-overlay"
        >
            <h2>Followers</h2>
            <div className="user-grid">
                {followers && followers.length > 0 ? (
                    followers.map((follower) => (
                        <div key={follower.uid} className="user-item">
                            <Link to={`/@${follower.username}`} className="user-info">
                                <img 
                                    src={follower.photoURL || '/default-avatar.png'} 
                                    alt={follower.displayName} 
                                    className="user-avatar"
                                />
                                <div className="user-details">
                                    <h3>{follower.displayName}</h3>
                                    <p>{follower.beltRank || 'No belt rank'}</p>
                                </div>
                            </Link>
                        </div>
                    ))
                ) : (
                    <p>No followers yet</p>
                )}
            </div>
            <div className="form-actions">
                <button onClick={onClose}>Close</button>
            </div>
        </Modal>
    );
};

export default FollowersModal; 