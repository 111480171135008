import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../services/firebase';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
} from 'react-share';
import Countdown from 'react-countdown';

const EventNotificationItem = ({ data, gymId, gymName, attendees = [] }) => {
    const [eventExists, setEventExists] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [eventData, setEventData] = useState(null);

    // useEffect(() => {
    //     const checkEventExists = async () => {
    //         try {
    //             if (!data.details?.eventId || !data.details?.gymId) {
    //                 setEventExists(false);
    //                 setIsLoading(false);
    //                 return;
    //             }

    //             const eventRef = doc(firestore, `locations/${data.details.gymId}/events`, data.details.eventId);
    //             const eventDoc = await getDoc(eventRef);
                
    //             if (eventDoc.exists()) {
    //                 setEventData(eventDoc.data());
    //                 setEventExists(true);
    //             } else {
    //                 setEventExists(false);
    //             }
    //             setIsLoading(false);
    //         } catch (error) {
    //             console.error('Error checking event existence:', error);
    //             setEventExists(false);
    //             setIsLoading(false);
    //         }
    //     };

    //     checkEventExists();
    // }, [data.details?.eventId, data.details?.gymId]);

    // Return null for both loading and non-existent events to prevent blank rows
    if (isLoading || !eventExists || !eventData) {
        return null;
    }

    const eventDate = eventData.start ? new Date(eventData.start.toDate()) : new Date();

    return (
        <div className="feed-item">
            <div className="feed-item-left">
                <div className="feed-item-header">
                    <h3>Event: {eventData.title || 'Untitled Event'}</h3>
                    {data.details?.gymId && data.details?.gymName && (
                        <p>Hosted by: <Link to={`/gym/${data.details.gymId}`}>{data.details.gymName}</Link></p>
                    )}
                    <Countdown date={eventDate} />
                </div>
                <div className="feed-item-body">
                    <p>{eventData.description || 'No description available'}</p>
                    <p>Attendees: {eventData.attendees?.length || 0}</p>
                </div>
            </div>
            <div className="feed-item-right">
                <FacebookShareButton url={window.location.href} quote={eventData.title || 'Event'}>
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href} title={eventData.title || 'Event'}>
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={window.location.href} title={eventData.title || 'Event'}>
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
            </div>
        </div>
    );
};

export default EventNotificationItem;
