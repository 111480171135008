import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from '../common/LoadingOverlay';
import { collection, getDocs, doc, getDoc, updateDoc, query, where, limit } from 'firebase/firestore';
import { firestore, auth } from '../../services/firebase';
import { MapContext } from '../../context/MapContext';
import LocationDeniedModal from './LocationDeniedModal';
import EditProfileModal from './EditProfileModal';
import { useAuthState } from 'react-firebase-hooks/auth';
import GoogleMapsLoader from '../utils/GoogleMapsLoader';
import useIsMounted from '../../hooks/useIsMounted';
import safeAsync from '../../utils/safeAsync';
import { debounce } from 'lodash';
import { useRole } from '../../hooks/useRole';
import {
    MapLayout,
    MainContent,
    MapContainer as StyledMapContainer,
    GoogleMapWrapper,
    Controls,
    SearchControls,
    RangeSlider,
    SearchButton,
    RangeValue,
    SearchResultsContainer,
    SearchResultCard,
    Button,
} from '../../styles/MapContainer.styles';
import LeftSidebar from '../layout/LeftSidebar';
import RightSidebar from '../layout/RightSidebar';
import {
    Add as AddIcon,
    Home as HomeIcon,
    Search as SearchIcon,
    Notifications as NotificationsIcon,
    Message as MessageIcon,
    Dashboard as DashboardIcon,
    Settings as SettingsIcon,
    Help as HelpIcon,
    Map as MapIcon,
    Person as PersonIcon,
    Info as InfoIcon,
    ContactSupport as ContactSupportIcon,
    Logout as LogoutIcon,
    AdminPanelSettings as AdminPanelSettingsIcon,
    MyLocation as MyLocationIcon,
    FilterList as FilterIcon
} from '@mui/icons-material';
import MapLoadingOverlay from '../common/MapLoadingOverlay';

const countryUsingMiles = ['United States', 'Liberia', 'Myanmar', 'United Kingdom'];

const isMobileSafari = () => {
    return (
        navigator.userAgent.match(/Safari/i) &&
        navigator.userAgent.match(/iPhone|iPad|iPod/i) &&
        !navigator.userAgent.match(/Chrome/i)
    );
};

const calculateZoomLevel = (radiusInMiles) => {
    // Direct mapping of distances to zoom levels:
    // These values are based on actual Google Maps zoom levels
    if (radiusInMiles <= 1) return 14;     // Neighborhood level
    if (radiusInMiles <= 2) return 13;
    if (radiusInMiles <= 5) return 12;     // Small city level
    if (radiusInMiles <= 10) return 11;    // Large city level
    if (radiusInMiles <= 20) return 10;    // Metropolitan area
    if (radiusInMiles <= 30) return 9;
    return 8;                              // Region level (for 40-50 miles)
};

const MapContainerContent = ({ isSidebarOpen, toggleSidebar, isLoaded, loadError }) => {
    const mounted = useIsMounted();
    const mainContentRef = useRef(null);
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const { isAdmin, isCreator } = useRole();
    const {
        selectedPosition, setSelectedPosition,
        selectedPlace, setSelectedPlace,
        previousPosition, setPreviousPosition,
        previousSelectedPlace, setPreviousSelectedPlace,
        currentLocation, setCurrentLocation,
        updateCurrentLocation,
        searchResults, setSearchResults,
        isSearching, setIsSearching
    } = useContext(MapContext);

    const [places, setPlaces] = useState([]);
    const [mapMarkers, setMapMarkers] = useState([]);
    const [searchDistance, setSearchDistance] = useState(5);
    const [searchTerms, setSearchTerms] = useState('BJJ');
    const [loading, setLoading] = useState(true);
    const [locationDenied, setLocationDenied] = useState(false);
    const [distanceUnit, setDistanceUnit] = useState('km');
    const [isProfileIncomplete, setIsProfileIncomplete] = useState(false);
    const [profile, setProfile] = useState(null);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showIOSInstall, setShowIOSInstall] = useState(false);
    const [suggestedUsers, setSuggestedUsers] = useState([]);
    const [suggestedGyms, setSuggestedGyms] = useState([]);
    const mapRef = useRef(null);

    const onMapLoad = useCallback((map) => {
        if (map) {
            mapRef.current = map;
            if (previousPosition) {
                map.panTo(previousPosition);
            }
        }
    }, [previousPosition]);

    const handleInstallClick = async () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                setDeferredPrompt(null);
            }
        }
    };

    const handleProfileSave = async (updatedProfile) => {
        try {
            const userRef = doc(firestore, 'users', user.uid);
            await updateDoc(userRef, updatedProfile);
            setProfile(updatedProfile);
            setIsProfileIncomplete(false);
            navigate('/');
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const safeCheckProfile = async () => {
        if (!mounted) return;
        try {
            const userDocRef = doc(firestore, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (!userData.city) {
                    setProfile(userData);
                    setIsProfileIncomplete(true);
                }
            }
        } catch (error) {
            console.error('Error checking profile:', error);
        }
    };

    useEffect(() => {
        if (!user) return;
        safeCheckProfile();
    }, [user]);

    useEffect(() => {
        const checkUserProfile = async () => {
            if (!user) return;

            const safeCheckProfile = safeAsync(async () => {
                const userDocRef = doc(firestore, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    if (!userData.city) {
                        setProfile(userData);
                        setIsProfileIncomplete(true);
                    }
                }
            }, mounted);

            await safeCheckProfile();
        };

        checkUserProfile();
    }, [user, mounted]);

    useEffect(() => {
        if (!currentLocation) return;

        let isCancelled = false;
        const abortController = new AbortController();

        const fetchCountryData = async () => {
            const safeFetchCountry = safeAsync(async () => {
                try {
                    const response = await fetch(
                        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.lat},${currentLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
                        { signal: abortController.signal }
                    );

                    if (!response.ok) {
                        throw new Error('Failed to fetch country data: ' + response.statusText);
                    }

                    const data = await response.json();

                    if (!isCancelled && data.results && data.results.length > 0) {
                        const countryComponent = data.results[0].address_components.find(
                            comp => comp.types.includes('country')
                        );
                        
                        if (countryComponent) {
                            const country = countryComponent.long_name;
                            setDistanceUnit(countryUsingMiles.includes(country) ? 'miles' : 'km');
                        }
                    }
                } catch (error) {
                    if (!isCancelled && error.name !== 'AbortError') {
                        console.error('Error fetching country data:', error);
                    }
                }
            }, mounted);

            await safeFetchCountry();
        };

        fetchCountryData();

        return () => {
            isCancelled = true;
            abortController.abort();
        };
    }, [currentLocation, mounted]);

    const handleUseCurrentLocation = () => {
        if (navigator.geolocation) {
            setLoading(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const newPosition = { lat: latitude, lng: longitude };
                    setCurrentLocation(newPosition);
                    setSelectedPosition(newPosition);
                    if (mapRef.current) {
                        mapRef.current.panTo(newPosition);
                    }
                    if (isLoaded) {
                        performSearch(newPosition);
                    }
                    setLoading(false);
                },
                (error) => {
                    console.error('Error fetching current location:', error);
                    setLoading(false);
                    if (isMobileSafari()) {
                        setLocationDenied(true);
                    }
                },
                { timeout: 20000, enableHighAccuracy: false }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            setLoading(false);
        }
    };

    const performSearch = (position) => {
        if (!window.google || !window.google.maps || !mapRef.current) {
            console.error('Google Maps API not loaded or map reference is missing');
            return;
        }

        setLoading(true);
        const service = new window.google.maps.places.PlacesService(mapRef.current);
        const request = {
            location: position,
            radius: searchDistance * 1609.34, // convert miles to meters
            keyword: searchTerms.split(',').map((term) => term.trim()).join('|'),
        };

        service.nearbySearch(request, (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setPlaces(results);
                setSearchResults(results);
                if (!isSidebarOpen) {
                    toggleSidebar();
                }
            } else {
                console.error('Nearby search was not successful for the following reason: ' + status);
                setPlaces([]);
                setSearchResults([]);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        let isMounted = true;

        const requestLocationPermission = () => {
            if (navigator.permissions) {
                navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                    if (isMounted) {
                        if (result.state === 'granted') {
                            handleUseCurrentLocation();
                        } else if (result.state === 'prompt') {
                            handleUseCurrentLocation();
                        } else if (result.state === 'denied') {
                            console.warn('Geolocation permissions are denied');
                            setLoading(false);
                            if (isMobileSafari()) {
                                setLocationDenied(true);
                            }
                        }
                        result.onchange = () => {
                            if (isMounted && result.state === 'granted') {
                                handleUseCurrentLocation();
                            }
                        };
                    }
                });
            } else if (isMounted) {
                handleUseCurrentLocation();
            }
        };

        if (isLoaded) {
            requestLocationPermission();
        }

        return () => {
            isMounted = false;
        };
    }, [isLoaded]);

    const handleSelectPlace = async (place) => {
        if (!mounted) return;
        if (mainContentRef.current) {
            mainContentRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
        
        const placeId = place.place_id || place.id;
        const lat = typeof place.geometry.location.lat === 'function' ? place.geometry.location.lat() : place.geometry.location.lat;
        const lng = typeof place.geometry.location.lng === 'function' ? place.geometry.location.lng() : place.geometry.location.lng;

        try {
            const placeDocRef = doc(firestore, 'locations', placeId);
            const placeDoc = await getDoc(placeDocRef);
            if (placeDoc.exists()) {
                const data = placeDoc.data();
                setSelectedPlace({ ...data, id: placeDoc.id, lat, lng });
            } else {
                setSelectedPlace({ ...place, id: placeId, lat, lng });
            }
        } catch (error) {
            console.error('Error fetching place:', error);
        }
        toggleSidebar();
    };

    useEffect(() => {
        if (!isLoaded) return;

        const fetchMarkersData = async () => {
            const safeFetchMarkers = safeAsync(async () => {
                const snapshot = await getDocs(collection(firestore, 'locations'));
                const markers = snapshot.docs
                    .map((doc) => ({ id: doc.id, ...doc.data() }))
                    .filter((doc) => doc.locationSaved);
                setMapMarkers(markers);
            }, mounted);

            await safeFetchMarkers();
        };

        if (!selectedPosition && !previousPosition && !currentLocation) {
            setSelectedPosition({ lat: 20, lng: 0 });
            if (mapRef.current) {
                mapRef.current.setZoom(2);
            }
        }

        fetchMarkersData();
        setLoading(false);
    }, [isLoaded, mounted, selectedPosition, previousPosition, currentLocation]);

    const debouncedSearch = useCallback(
        debounce((position) => {
            if (isLoaded) {
                performSearch(position);
            }
            if (!isSidebarOpen) {
                toggleSidebar();
            }
        }, 300),
        [isLoaded, performSearch, isSidebarOpen, toggleSidebar]
    );

    const handleMapClick = useCallback((event) => {
        const clickedPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        };
        setSelectedPosition(clickedPosition);
        performSearch(clickedPosition);
    }, [performSearch]);

    const handleMarkerDragEnd = useCallback((event) => {
        const draggedPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        };
        setSelectedPosition(draggedPosition);
        performSearch(draggedPosition);
    }, [performSearch]);

    const handleViewGymInfo = (place) => {
        const lat = place.geometry?.location.lat();
        const lng = place.geometry?.location.lng();
        const placeId = place.place_id || place.id;
        const name = place.name;
        const address = place.vicinity || place.formatted_address;

        setPreviousPosition(selectedPosition);
        setPreviousSelectedPlace(selectedPlace);
        navigate(`/gym/${placeId}`, {
            state: { placeId, lat, lng, name, address }
        });
    };

    const handleSavedMarkerClick = async (id) => {
        try {
            const placeDocRef = doc(firestore, 'locations', id);
            const placeDoc = await getDoc(placeDocRef);
            if (placeDoc.exists()) {
                const data = placeDoc.data();
                setSelectedPlace({ ...data, id: placeDoc.id, lat: data.lat, lng: data.lng });
                if (mapRef.current) {
                    mapRef.current.panTo({ lat: data.lat, lng: data.lng });
                }
            } else {
                console.error('No such document!');
            }
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    };

    const customMarkerIcon = {
        url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
        scaledSize: isLoaded && window.google ? new window.google.maps.Size(50, 50) : null,
    };

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    useEffect(() => {
        const fetchSuggestions = async () => {
            if (!user) return;

            try {
                // Fetch suggested users
                const usersRef = collection(firestore, 'users');
                const usersQuery = query(
                    usersRef,
                    where('role.creator', '==', true),
                    limit(5)
                );
                const userSnapshot = await getDocs(usersQuery);
                const users = userSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(u => u.id !== user.uid);

                setSuggestedUsers(users);

                // Fetch suggested gyms
                const gymsRef = collection(firestore, 'locations');
                const gymsQuery = query(gymsRef, limit(3));
                const gymSnapshot = await getDocs(gymsQuery);
                const gyms = gymSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }));

                setSuggestedGyms(gyms);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        };

        fetchSuggestions();
    }, [user]);

    if (loadError) {
        console.error("Error loading Google Maps:", loadError);
        return <div>Error loading maps. Please try again later.</div>;
    }

    return (
        <MapLayout>
            <LeftSidebar onLocationRequest={handleUseCurrentLocation} />

            <MainContent ref={mainContentRef}>
                <StyledMapContainer>
                    <GoogleMapWrapper>
                        {(!isLoaded || loading) && (
                            <MapLoadingOverlay 
                                message={!isLoaded ? "Initializing map..." : "Finding nearby gyms..."}
                            />
                        )}
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '100%' }}
                            center={selectedPosition || currentLocation}
                            zoom={selectedPosition ? calculateZoomLevel(searchDistance) : 2}
                            onLoad={onMapLoad}
                            onClick={handleMapClick}
                            options={{
                                zoomControl: true,
                                mapTypeControl: false,
                                streetViewControl: false,
                                fullscreenControl: false,
                                gestureHandling: 'greedy'
                            }}
                        >
                            {selectedPosition && (
                                <Marker
                                    position={selectedPosition}
                                    draggable={true}
                                    onDragEnd={handleMarkerDragEnd}
                                    icon={customMarkerIcon}
                                />
                            )}
                            
                            {places.map((place) => (
                                <Marker
                                    key={place.place_id}
                                    position={{
                                        lat: place.geometry.location.lat(),
                                        lng: place.geometry.location.lng()
                                    }}
                                    onClick={() => handleSelectPlace(place)}
                                />
                            ))}
                            
                            {mapMarkers.map((marker, index) => (
                                <Marker
                                    key={`saved-${marker.id}`}
                                    position={{ lat: marker.lat, lng: marker.lng }}
                                    onClick={() => handleSavedMarkerClick(marker.id)}
                                    zIndex={window.google?.maps?.Marker?.MAX_ZINDEX + 1 || 1000}
                                    icon={{
                                        url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                                        scaledSize: isLoaded && window.google ? new window.google.maps.Size(40, 40) : null,
                                    }}
                                />
                            ))}

                            {selectedPlace && selectedPlace.lat && selectedPlace.lng && (
                                <InfoWindow
                                    position={{
                                        lat: selectedPlace.lat,
                                        lng: selectedPlace.lng,
                                    }}
                                    onCloseClick={() => setSelectedPlace(null)}
                                >
                                    <div style={{ color: '#000000' }}>
                                        <h2 style={{ color: '#000000', margin: '0 0 8px 0' }}>{selectedPlace.name}</h2>
                                        <p style={{ color: '#333333', margin: '0 0 12px 0' }}>{selectedPlace.vicinity || selectedPlace.address}</p>
                                        <Button onClick={() => handleViewGymInfo(selectedPlace)}>
                                            View Gym Info
                                        </Button>
                                    </div>
                                </InfoWindow>
                            )}
                        </GoogleMap>
                    </GoogleMapWrapper>

                    <Controls>
                        <SearchControls>
                            <RangeValue>{searchDistance} mi</RangeValue>
                            <RangeSlider
                                type="range"
                                min="1"
                                max="50"
                                value={searchDistance}
                                onChange={(e) => setSearchDistance(e.target.value)}
                            />
                            <SearchButton onClick={() => performSearch(selectedPosition)}>
                                <MyLocationIcon /> Search
                            </SearchButton>
                        </SearchControls>
                    </Controls>
                </StyledMapContainer>

                <SearchResultsContainer>
                    <h2>Search Results ({places.length})</h2>
                    <div className="results-grid">
                        {places.map((place) => (
                            <SearchResultCard 
                                key={place.place_id} 
                                onClick={() => {
                                    handleSelectPlace(place);
                                }}
                            >
                                <h3>{place.name}</h3>
                                <p>{place.vicinity}</p>
                                {place.rating && (
                                    <p>Rating: {place.rating} ({place.user_ratings_total} reviews)</p>
                                )}
                                {place.openMatStatus && (
                                    <div className="status">
                                        <span className={`indicator ${place.openMatStatus}`} />
                                        {place.openMatStatus === 'open' ? 'Open Mat in Progress' : 'No Open Mat'}
                                    </div>
                                )}
                            </SearchResultCard>
                        ))}
                    </div>
                </SearchResultsContainer>
            </MainContent>

            <RightSidebar 
                suggestedUsers={suggestedUsers}
                suggestedGyms={suggestedGyms}
            />

            {locationDenied && (
                <LocationDeniedModal
                    isOpen={locationDenied}
                    onClose={() => setLocationDenied(false)}
                />
            )}
        </MapLayout>
    );
};

const MapContainer = (props) => {
    return (
        <GoogleMapsLoader>
            <MapContainerContent {...props} />
        </GoogleMapsLoader>
    );
};

export default MapContainer;
