import { doc, addDoc, updateDoc, deleteDoc, arrayUnion, arrayRemove, getDoc, collection, query, orderBy, getDocs, serverTimestamp } from 'firebase/firestore';
import { auth, firestore } from './firebase';

// Helper function to get the root path for post types
const getPostTypeRoot = (userId, collectionType) => {
    if (!userId || !collectionType) {
        console.error('Missing parameters:', { userId, collectionType });
        throw new Error('Missing required parameters');
    }

    // Extract just the collection type if a full path is provided
    const type = collectionType.split('/')[0];

    switch (type.toLowerCase()) {
        case 'user':
        case 'users':
            return `users/${userId}/comments`;
        case 'location':
        case 'locations':
        case 'gym':
            return `locations/${userId}/comments`;
        case 'event':
        case 'events':
            // For events, userId should be the locationId and itemId should be the eventId
            return `locations/${userId}/events/${collectionType.split('/')[1]}/comments`;
        default:
            console.error('Invalid collection type:', type);
            throw new Error('Invalid collection type');
    }
};

export const addComment = async (userId, collectionType, commentData) => {
    try {
        const commentToAdd = {
            ...commentData,
            createdAt: serverTimestamp(),
            likes: [],
            replies: [],
            embedUrl: commentData.embedUrl || "",
            profilePicUrl: commentData.profilePicUrl || 'default-profile.png',
            likedBy: []
        };

        const postTypeRoot = getPostTypeRoot(userId, collectionType);
        
        // If this is an event comment, ensure the event document exists
        if (collectionType.startsWith('event/')) {
            const eventId = collectionType.split('/')[1];
            const eventRef = doc(firestore, `locations/${userId}/events/${eventId}`);
            const eventDoc = await getDoc(eventRef);
            
            if (!eventDoc.exists()) {
                throw new Error('Event document does not exist');
            }
        }

        const commentRef = await addDoc(collection(firestore, postTypeRoot), commentToAdd);
        return { id: commentRef.id, ...commentToAdd };

    } catch (error) {
        console.error('Error adding comment:', error);
        throw error;
    }
};

export const addReply = async (userId, collectionType, commentId, replyData) => {
    if (!userId || !collectionType || !commentId || !replyData) {
        throw new Error('Missing required parameters for addReply');
    }

    const postTypeRoot = getPostTypeRoot(userId, collectionType);
    const reply = {
        ...replyData,
        createdAt: serverTimestamp(),
        likes: [],
    };

    try {
        const replyRef = await addDoc(collection(firestore, `${postTypeRoot}/${commentId}/replies`), reply);
        return { id: replyRef.id, ...reply };
    } catch (error) {
        console.error('Error adding reply:', error);
        throw error;
    }
};

export const deleteComment = async (userId, collectionType, commentId) => {
    try {
        const postTypeRoot = getPostTypeRoot(userId, collectionType);
        const commentRef = doc(firestore, `${postTypeRoot}/${commentId}`);
        await deleteDoc(commentRef);
    } catch (error) {
        console.error('Error deleting comment:', error);
    }
};

export const deleteReply = async (userId, collectionType, commentId, replyId) => {
    try {
        const postTypeRoot = getPostTypeRoot(userId, collectionType);
        const replyRef = doc(firestore, `${postTypeRoot}/${commentId}/replies/${replyId}`);
        await deleteDoc(replyRef);
    } catch (error) {
        console.error('Error deleting reply:', error);
        throw error;
    }
};

export const fetchItemsWithReplies = async (userId, collectionType) => {
    if (!userId || !collectionType) {
        console.error('Missing required parameters for fetchItemsWithReplies');
        return [];
    }

    try {
        const postTypeRoot = getPostTypeRoot(userId, collectionType);
        console.log('Fetching from root:', postTypeRoot);
        
        // If this is an event, verify the event exists first
        if (collectionType.startsWith('event/')) {
            const eventId = collectionType.split('/')[1];
            const eventRef = doc(firestore, `locations/${userId}/events/${eventId}`);
            const eventDoc = await getDoc(eventRef);
            
            if (!eventDoc.exists()) {
                console.error('Event document does not exist');
                return [];
            }
        }
        
        // First, get all comments
        const commentsRef = collection(firestore, postTypeRoot);
        const q = query(commentsRef, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);

        // Then, for each comment, get its replies
        const comments = await Promise.all(querySnapshot.docs.map(async (commentDoc) => {
            const commentData = { id: commentDoc.id, ...commentDoc.data() };
            
            // Get replies from the nested 'replies' collection
            const repliesRef = collection(firestore, `${postTypeRoot}/${commentDoc.id}/replies`);
            const repliesSnapshot = await getDocs(query(repliesRef, orderBy('createdAt', 'asc')));
            
            // Add replies as a nested array under the comment
            commentData.replies = repliesSnapshot.docs.map(replyDoc => ({
                id: replyDoc.id,
                ...replyDoc.data(),
                isReply: true,
                parentCommentId: commentDoc.id
            }));
            
            console.log(`Comment ${commentDoc.id} has ${commentData.replies.length} replies`);
            return commentData;
        }));

        console.log('Fetched comments with replies:', comments);
        return comments;
    } catch (error) {
        console.error("Error fetching comments:", error);
        return [];
    }
};

export const toggleLike = async (userId, collectionType, commentId, likerId) => {
    if (!userId || !collectionType || !commentId || !likerId) {
        throw new Error('Missing required parameters for toggling like');
    }

    const postTypeRoot = getPostTypeRoot(userId, collectionType);
    const commentRef = doc(firestore, `${postTypeRoot}/${commentId}`);
    const commentDoc = await getDoc(commentRef);

    if (!commentDoc.exists()) {
        throw new Error('Document does not exist');
    }

    const commentData = commentDoc.data();
    const likedBy = commentData.likedBy || [];
    const isLiked = likedBy.includes(likerId);

    await updateDoc(commentRef, {
        likedBy: isLiked ? arrayRemove(likerId) : arrayUnion(likerId),
        likes: isLiked ? (commentData.likes || 1) - 1 : (commentData.likes || 0) + 1
    });

    return {
        ...commentData,
        id: commentId,
        likedBy: isLiked ? likedBy.filter(id => id !== likerId) : [...likedBy, likerId],
        likes: isLiked ? (commentData.likes || 1) - 1 : (commentData.likes || 0) + 1
    };
};

export const toggleReplyLike = async (userId, collectionType, commentId, replyId, likerId) => {
    if (!userId || !collectionType || !commentId || !replyId || !likerId) {
        throw new Error('Missing required parameters for toggling reply like');
    }

    const postTypeRoot = getPostTypeRoot(userId, collectionType);
    const replyRef = doc(firestore, `${postTypeRoot}/${commentId}/replies/${replyId}`);
    const replyDoc = await getDoc(replyRef);

    if (!replyDoc.exists()) {
        throw new Error('Reply document does not exist');
    }

    const replyData = replyDoc.data();
    const likedBy = replyData.likedBy || [];
    const isLiked = likedBy.includes(likerId);

    await updateDoc(replyRef, {
        likedBy: isLiked ? arrayRemove(likerId) : arrayUnion(likerId),
        likes: isLiked ? (replyData.likes || 1) - 1 : (replyData.likes || 0) + 1
    });

    return {
        ...replyData,
        id: replyId,
        likedBy: isLiked ? likedBy.filter(id => id !== likerId) : [...likedBy, likerId],
        likes: isLiked ? (replyData.likes || 1) - 1 : (replyData.likes || 0) + 1
    };
};

export { getPostTypeRoot };