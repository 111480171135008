export const POST_VISIBILITY = {
    PUBLIC: 'public',
    FOLLOWERS: 'followers',
    SUBSCRIBERS: 'subscribers'
};

export const getVisibilityLabel = (visibility) => {
    switch (visibility) {
        case POST_VISIBILITY.PUBLIC:
            return 'Public';
        case POST_VISIBILITY.FOLLOWERS:
            return 'Followers Only';
        case POST_VISIBILITY.SUBSCRIBERS:
            return 'Subscribers Only';
        default:
            return 'Public';
    }
}; 