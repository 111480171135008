import { styled } from '@mui/material/styles';
import { Card, CardContent, Box, Typography } from '@mui/material';

export const CommentCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  boxShadow: 'none',
  backgroundColor: '#1a1a1a',
  color: '#fff',
  '& .MuiCardHeader-root': {
    backgroundColor: '#1a1a1a'
  },
  '.reply &': {
    backgroundColor: '#242424',
    '& .MuiCardHeader-root': {
      backgroundColor: '#242424'
    }
  },
  '&:hover': {
    boxShadow: theme.shadows[1]
  }
}));

export const CommentContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  backgroundColor: 'inherit',
  '&:last-child': {
    paddingBottom: theme.spacing(1)
  },
  '& .ql-editor': {
    padding: 0,
    width: '100%',
    '& p': {
      color: '#fff',
      marginBottom: 0,
      width: '100%'
    },
    '& img': {
      width: '100%',
      height: 'auto',
      maxWidth: '100%',
      display: 'block'
    },
    '& ul, & ol': {
      color: '#fff',
      paddingLeft: theme.spacing(2)
    },
    '& blockquote': {
      borderLeft: '4px solid #666',
      paddingLeft: theme.spacing(2),
      color: '#ccc',
      margin: theme.spacing(1, 0)
    },
    '& a': {
      color: '#1976d2'
    }
  }
}));

export const ReplyContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  marginTop: theme.spacing(0.5),
  backgroundColor: 'transparent !important',
  '&.reply': {
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent !important'
  },
  '& .MuiBox-root': {
    backgroundColor: 'transparent !important'
  }
}));

export const ActionButton = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontSize: '0.75rem',
  textTransform: 'uppercase',
  letterSpacing: '0.5px',
  fontWeight: 500,
  background: 'none',
  border: 'none',
  padding: 0,
  '&.reply': {
    color: '#1976d2',
    '&:hover': {
      color: '#1565c0',
      background: 'none'
    }
  },
  '&.delete': {
    color: '#d32f2f',
    '&:hover': {
      color: '#c62828'
    }
  }
}));

export const ReplyForm = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  marginTop: theme.spacing(0.5),
  backgroundColor: '#2c2c2c',
  borderRadius: theme.shape.borderRadius,
  '& .quill': {
    backgroundColor: '#363636',
    borderRadius: theme.shape.borderRadius,
    '& .ql-toolbar': {
      border: 'none',
      borderBottom: '1px solid #666',
      '& .ql-stroke': {
        stroke: '#fff'
      },
      '& .ql-fill': {
        fill: '#fff'
      },
      '& .ql-picker': {
        color: '#fff'
      },
      '& .ql-picker-options': {
        backgroundColor: '#363636',
        border: '1px solid #666'
      }
    },
    '& .ql-container': {
      border: 'none',
      height: '120px',
      '& .ql-editor': {
        color: '#fff',
        fontSize: '1rem',
        fontFamily: theme.typography.fontFamily,
        '&.ql-blank::before': {
          color: '#aaa'
        }
      }
    }
  }
}));

export const CommentsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#242424',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2)
})); 