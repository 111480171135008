import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
import '@fortawesome/fontawesome-free/css/all.min.css';

// if (window.location.protocol !== "https:") {
//     window.location.replace(`https://${window.location.host}${window.location.pathname}${window.location.search}`);
// }

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
                // console.log('ServiceWorker registration successful with scope: ', registration.scope);
            })
            .catch(error => {
                // console.log('ServiceWorker registration failed: ', error);
            });
    });
}
