import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../../services/firebase';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import '../../styles/UserListModal.css';

Modal.setAppElement('#root');

const FollowingModal = ({ isOpen, onClose, following }) => {
    const [user] = useAuthState(auth);
    const [myFollowing, setMyFollowing] = useState([]);

    useEffect(() => {
        const fetchMyFollowing = async () => {
            if (!user) return;
            
            const myUserDoc = await getDoc(doc(firestore, 'users', user.uid));
            const myData = myUserDoc.data();
            setMyFollowing(myData?.following || []);
        };

        fetchMyFollowing();
    }, [user]);

    const handleFollow = async (userToFollow) => {
        if (!user) return;
        
        const userRef = doc(firestore, 'users', user.uid);
        const followedRef = doc(firestore, 'users', userToFollow.uid);

        if (myFollowing.includes(userToFollow.uid)) {
            // Unfollow
            await updateDoc(userRef, {
                following: arrayRemove(userToFollow.uid)
            });
            await updateDoc(followedRef, {
                followers: arrayRemove(user.uid)
            });
            setMyFollowing(prev => prev.filter(id => id !== userToFollow.uid));
        } else {
            // Follow
            await updateDoc(userRef, {
                following: arrayUnion(userToFollow.uid)
            });
            await updateDoc(followedRef, {
                followers: arrayUnion(user.uid)
            });
            setMyFollowing(prev => [...prev, userToFollow.uid]);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Following"
            className="user-list-modal"
            overlayClassName="user-list-modal-overlay"
        >
            <div className="modal-header">
                <h2>Following</h2>
                <button className="close-button" onClick={onClose}>×</button>
            </div>
            <div className="user-list">
                {following && following.length > 0 ? (
                    following.map((followedUser) => (
                        <div key={followedUser.uid} className="user-list-item">
                            <div className="user-list-info">
                                <div className="user-avatar-stack">
                                    <img 
                                        src={followedUser.photoURL || '/default-avatar.png'} 
                                        alt={followedUser.displayName} 
                                        className="user-avatar"
                                    />
                                    <span className="display-name">{followedUser.displayName}</span>
                                </div>
                            </div>
                            {user?.uid !== followedUser.uid && (
                                <Button 
                                    variant="contained"
                                    size="small"
                                    onClick={() => handleFollow(followedUser)}
                                    sx={{
                                        minWidth: '100px',
                                        backgroundColor: myFollowing.includes(followedUser.uid) ? '#242424' : '#1976d2',
                                        '&:hover': {
                                            backgroundColor: myFollowing.includes(followedUser.uid) ? '#333' : '#1565c0'
                                        }
                                    }}
                                >
                                    {myFollowing.includes(followedUser.uid) ? 'Following' : 'Follow'}
                                </Button>
                            )}
                        </div>
                    ))
                ) : (
                    <p>Not following anyone yet</p>
                )}
            </div>
        </Modal>
    );
};

export default FollowingModal; 