const safeAsync = (asyncFn, isMounted) => {
    return async (...args) => {
        try {
            const result = await asyncFn(...args);
            if (isMounted.current) {
                return result;
            }
        } catch (error) {
            if (isMounted.current) {
                console.error('Error in async operation:', error);
                throw error;
            }
        }
    };
};

export default safeAsync; 