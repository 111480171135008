import React from 'react';

const TrainingLogNotificationItem = ({ data }) => {
    return (
        <div className="notification-item training-log-notification-item">
            <div className="notification-item-header">
                <h3>{data.displayName} logged a training session:</h3>
            </div>
            <p>{data.trainingSummary}</p>
            <p>On {new Date(data.timestamp.toDate()).toLocaleDateString()}</p>
            <div className="notification-item-footer">
                <button className="like-button">Like</button>
                <button className="comment-button">Comment</button>
            </div>
        </div>
    );
};

export default TrainingLogNotificationItem;
