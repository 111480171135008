import styled from 'styled-components';
import { LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from './layout/Sidebar.styles';

// Layout constants
const CONTENT_MIN_WIDTH = '600px';
const CONTENT_MAX_WIDTH = '800px';

export const MapLayout = styled.div`
  display: grid;
  grid-template-columns: ${LEFT_SIDEBAR_WIDTH} minmax(${CONTENT_MIN_WIDTH}, ${CONTENT_MAX_WIDTH}) ${RIGHT_SIDEBAR_WIDTH};
  gap: 12px;
  flex: 1;
  background-color: #000;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 24px 0;
  min-height: 100vh;
  position: relative;
  overflow-y: auto;

  @media (max-width: 1400px) {
    grid-template-columns: ${LEFT_SIDEBAR_WIDTH} minmax(${CONTENT_MIN_WIDTH}, 1fr) 240px;
    gap: 8px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 80px minmax(${CONTENT_MIN_WIDTH}, 1fr) 80px;
    gap: 4px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
    margin: 0;
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
  }
`;

export const MainContent = styled.main`
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100vh;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 768px) {
    height: calc(100vh - 60px);
    width: 100vw;
    max-width: 100vw;
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;

  @media (max-width: 768px) {
    aspect-ratio: 3/4;
  }
`;

export const GoogleMapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Controls = styled.div`
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  padding: 12px 16px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    bottom: 0;
    width: 100%;
    border-radius: 0;
    padding: 12px;
  }
`;

export const SearchControls = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const RangeSlider = styled.input`
  -webkit-appearance: none;
  width: 200px;
  height: 4px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.2);
  outline: none;
  transition: all 0.2s ease;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary.main};
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary.main};
    cursor: pointer;
    border: none;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  @media (max-width: 768px) {
    width: 140px;
  }
`;

export const Button = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background: white;
  color: black;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;

  &:hover {
    background: #f0f0f0;
  }
`;

export const RangeValue = styled.span`
  color: white;
  font-size: 14px;
  min-width: 48px;
`;

export const SearchButton = styled(Button)`
  background: ${({ theme }) => theme.colors.primary.main};
  color: white;
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;

  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
    transform: translateY(-1px);
  }

  svg {
    font-size: 18px;
  }
`;

export const Input = styled.input`
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: white;
  width: 100%;
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const SearchResultsContainer = styled.div`
  padding: 16px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  margin-top: 24px;
  border-radius: 4px;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    padding: 12px;
    margin-top: 0;
    border-radius: 0;
  }

  h2 {
    margin: 0 0 12px 0;
    color: white;
    font-size: 18px;
  }

  .results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    gap: 12px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
    }
  }
`;

export const SearchResultCard = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 12px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: white;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-1px);
  }

  h3 {
    margin: 0 0 6px 0;
    font-size: 15px;
  }

  p {
    margin: 3px 0;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.7);
  }

  .status {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 8px;
    font-size: 13px;

    .indicator {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: ${props => props.openMatStatus === 'open' ? '#4CAF50' : '#f44336'};
    }
  }
`;

export const SuggestionsContainer = styled.div`
  padding: 8px;
  background-color: #111;
  border-radius: 12px;
  margin-bottom: 8px;
  width: 100%;

  h3 {
    color: #fff;
    font-size: 14px;
    margin: 0 0 8px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1200px) {
    padding: 6px;
    h3 {
      display: none;
    }
  }
`;

export const SuggestionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 1200px) {
    padding: 4px 0;
  }
`;

export const SuggestionInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1;
  min-width: 0;
`;

export const SuggestionAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 1200px) {
    width: 40px;
    height: 40px;
  }
`;

export const SuggestionDetails = styled.div`
  min-width: 0;

  h4 {
    color: #fff;
    font-size: 13px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    color: #999;
    font-size: 11px;
    margin: 2px 0 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const SuggestionButton = styled.button`
  background: none;
  border: 1px solid #333;
  color: #fff;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  margin-left: 8px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 1200px) {
    display: none;
  }
`; 