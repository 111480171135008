import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '../styles/VideoPlayer.css';

const getVideoType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    switch (extension) {
        case 'mp4':
            return 'video/mp4';
        case 'mov':
        case 'qt':
            return 'video/quicktime';
        case 'wmv':
            return 'video/x-ms-wmv';
        case 'avi':
            return 'video/x-msvideo';
        default:
            return 'video/mp4'; // fallback to mp4
    }
};

const VideoPlayer = ({ src }) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const [error, setError] = useState(null);
    const containerRef = useRef(null);

    useEffect(() => {
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            playerRef.current = videojs(videoElement, {
                controls: true,
                fluid: true,
                responsive: true,
                preload: 'auto',
                playbackRates: [0.5, 1, 1.5, 2]
            });

            playerRef.current.on('error', () => {
                const error = playerRef.current.error();
                console.error('Video.js Error:', error);
                setError(error);
            });

            // Handle video metadata loaded
            videoElement.addEventListener('loadedmetadata', () => {
                if (videoElement.videoWidth && videoElement.videoHeight) {
                    const aspectRatio = videoElement.videoHeight / videoElement.videoWidth;
                    
                    // Add class for portrait videos
                    if (aspectRatio > 1) {
                        videoElement.closest('.video-js').classList.add('portrait-video');
                    } else {
                        videoElement.closest('.video-js').classList.remove('portrait-video');
                    }
                }
            });
        }

        if (playerRef.current && src) {
            try {
                const videoType = getVideoType(src);
                console.log('Video type detected:', videoType, 'for source:', src);
                
                playerRef.current.src({
                    src: src,
                    type: videoType
                });
                
                setError(null);
                playerRef.current.load();
            } catch (e) {
                console.error('Error setting video source:', e);
                setError(e);
            }
        }

        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, [src]);

    if (error) {
        console.log('Current video source:', src);
        return (
            <div style={{ 
                padding: '20px', 
                backgroundColor: '#363636', 
                color: '#fff',
                borderRadius: '4px',
                textAlign: 'center' 
            }}>
                <p>Error loading video. Please try refreshing the page.</p>
                <p>Error details: {error.message}</p>
                <p>Video source: {src}</p>
            </div>
        );
    }

    return (
        <div className="video-container" ref={containerRef}>
            <video 
                ref={videoRef}
                className="video-js vjs-big-play-centered"
                playsInline
                preload="auto"
            />
        </div>
    );
};

export default VideoPlayer; 