import React, { createContext, useContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { darkTheme } from '../styles/theme';

const ThemeContext = createContext({
  theme: darkTheme,
  isDarkMode: true
});

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeProvider = ({ children }) => {
  const value = {
    theme: darkTheme,
    isDarkMode: true
  };

  return (
    <ThemeContext.Provider value={value}>
      <StyledThemeProvider theme={darkTheme}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
}; 