import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Footer.css';

const Footer = () => {
    return (
        <footer className="site-footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h3>Navigation</h3>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/map">Find Gyms</Link></li>
                        <li><Link to="/feed">Activity Feed</Link></li>
                        <li><Link to="/notifications">Notifications</Link></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3>Community</h3>
                    <ul>
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                        <li><Link to="/terms">Terms of Service</Link></li>
                        <li><Link to="/privacy">Privacy Policy</Link></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3>For Gym Owners</h3>
                    <ul>
                        <li><Link to="/claim-gym">Claim Your Gym</Link></li>
                        <li><Link to="/gym-dashboard">Gym Dashboard</Link></li>
                        <li><Link to="/promote">Promote Your Gym</Link></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3>Connect</h3>
                    <ul>
                        <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                        <li><a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                        <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} Open Mat. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer; 