import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaVideo, FaImage, FaFileAlt, FaCog, FaChartLine, FaFilter } from 'react-icons/fa';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../../services/firebase';
import { fetchCreatorAnalytics } from '../../../../services/analyticsService';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(4)};
  background-color: ${({ theme }) => theme.colors.background.primary};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const Header = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSize.large};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const StatCard = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing(4)};
  box-shadow: ${({ theme }) => theme.shadows.medium};
`;

const StatTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSize.base};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const StatValue = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSize.large};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const ChartContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing(4)};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  height: 400px;
`;

const ChartTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.fontSize.base};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const ActivityList = styled.ul`
  list-style: none;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const ActivityItem = styled.li`
  padding: ${({ theme }) => theme.spacing(2)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
  }
`;

const ActivityTitle = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const ActivityMeta = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSize.small};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const PopularContent = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const ContentItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
  }
`;

const ContentThumbnail = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;

export const CreatorDashboard = () => {
  const [user] = useAuthState(auth);
  const [analytics, setAnalytics] = useState(null);

  useEffect(() => {
    const loadAnalytics = async () => {
      if (user) {
        const data = await fetchCreatorAnalytics(user.uid);
        setAnalytics(data);
      }
    };

    loadAnalytics();
  }, [user]);

  if (!analytics) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Header>
        <Title>Creator Dashboard</Title>
      </Header>

      <StatsGrid>
        <StatCard>
          <StatTitle>Total Views</StatTitle>
          <StatValue>{analytics.totalViews}</StatValue>
        </StatCard>
        <StatCard>
          <StatTitle>Total Followers</StatTitle>
          <StatValue>{analytics.totalFollowers}</StatValue>
        </StatCard>
        <StatCard>
          <StatTitle>Total Engagement</StatTitle>
          <StatValue>{analytics.totalEngagement}%</StatValue>
        </StatCard>
      </StatsGrid>

      <ChartContainer>
        <ChartTitle>Views Over Time</ChartTitle>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={analytics.viewsOverTime}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Area 
              type="monotone" 
              dataKey="views" 
              stroke={({ theme }) => theme.colors.primary.main}
              fill={({ theme }) => theme.colors.primary.light}
            />
          </AreaChart>
        </ResponsiveContainer>
      </ChartContainer>

      <ChartContainer>
        <ChartTitle>Content Performance</ChartTitle>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={analytics.contentPerformance}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="title" />
            <YAxis />
            <Tooltip />
            <Bar 
              dataKey="views" 
              fill={({ theme }) => theme.colors.primary.main}
            />
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  );
}; 