import React from 'react';
import styled from 'styled-components';
import { Map as MapIcon } from '@mui/icons-material';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.background.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 8px;
`;

const MapPlaceholder = styled.div`
  width: 80px;
  height: 80px;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  animation: pulse 2s infinite;

  svg {
    font-size: 40px;
    color: ${({ theme }) => theme.colors.primary.main};
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const LoadingText = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  max-width: 300px;
  line-height: 1.5;
`;

const MapLoadingOverlay = ({ message = "Loading map..." }) => {
  return (
    <Overlay>
      <MapPlaceholder>
        <MapIcon />
      </MapPlaceholder>
      <LoadingText>{message}</LoadingText>
    </Overlay>
  );
};

export default MapLoadingOverlay; 