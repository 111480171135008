const baseTheme = {
  spacing: (multiplier = 1) => `${0.25 * multiplier}rem`,
  typography: {
    fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
    fontSize: {
      base: '1rem',
      small: '0.875rem',
      large: '1.125rem'
    },
    fontWeight: {
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700
    },
    lineHeight: {
      base: 1.5,
      heading: 1.2,
      relaxed: 1.75
    }
  },
  borderRadius: {
    small: '0.25rem',
    medium: '0.375rem',
    large: '0.5rem'
  }
};

export const lightTheme = {
  ...baseTheme,
  colors: {
    primary: {
      main: '#2563eb',
      light: '#60a5fa',
      dark: '#1d4ed8'
    },
    background: {
      primary: '#ffffff',
      secondary: '#f8fafc',
      paper: '#f1f5f9',
      hover: '#e2e8f0'
    },
    text: {
      primary: '#111827',
      secondary: '#4b5563',
      onPrimary: '#ffffff'
    },
    border: '#e5e7eb',
    disabled: '#9ca3af',
    scrollbar: '#cbd5e1',
    scrollbarHover: '#94a3b8'
  },
  shadows: {
    small: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    medium: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    large: '0 10px 15px -3px rgba(0, 0, 0, 0.1)'
  }
};

export const darkTheme = {
  ...baseTheme,
  colors: {
    primary: {
      main: '#3b82f6',
      light: '#60a5fa',
      dark: '#2563eb'
    },
    background: {
      primary: '#121212',
      secondary: '#1a1a1a',
      paper: '#242424',
      hover: '#2a2a2a'
    },
    text: {
      primary: '#ffffff',
      secondary: '#a3a3a3',
      onPrimary: '#ffffff'
    },
    border: '#333333',
    disabled: '#404040',
    scrollbar: '#404040',
    scrollbarHover: '#4d4d4d'
  },
  shadows: {
    small: '0 1px 2px 0 rgba(0, 0, 0, 0.5)',
    medium: '0 4px 6px -1px rgba(0, 0, 0, 0.6)',
    large: '0 10px 15px -3px rgba(0, 0, 0, 0.7)'
  }
}; 