import React, { useState, useMemo, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import { 
    Button, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    CircularProgress,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { VideoCall as VideoIcon, Link as LinkIcon, Image as ImageIcon } from '@mui/icons-material';
import { storage } from '../../services/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {
    CommentFormContainer,
    FormActions,
    MediaPreview,
    QuillWrapper
} from '../../styles/CommentForm.styled';
import { auth } from '../../services/firebase';
import VideoPlayer from '../VideoPlayer';
import { POST_VISIBILITY, getVisibilityLabel } from '../../constants/visibility';
import { processAndUploadMedia } from '../../services/mediaProcessingService';

const POST_TYPES = {
    COMMENT: 'comment',
    VIDEO: 'video',
    IMAGE: 'image'
};

const dialogStyles = {
    '& .MuiPaper-root': {
        backgroundColor: '#242424',
        color: '#fff',
        width: '90%',
        maxWidth: '600px',
        margin: '16px',
        '& .MuiDialogContent-root': {
            padding: {
                xs: '16px',
                sm: '24px'
            }
        },
        '& .MuiDialogActions-root': {
            padding: {
                xs: '8px 16px',
                sm: '16px 24px'
            }
        },
        '@media (max-width: 768px)': {
            width: '100%',
            maxWidth: '100%',
            margin: 0,
            borderRadius: 0,
            '& .MuiDialogContent-root': {
                padding: '12px'
            },
            '& .MuiDialogActions-root': {
                padding: '8px 12px'
            }
        }
    }
};

const CommentForm = ({ open, onClose, onSubmit, isCreator = false }) => {
    const [content, setContent] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState(null);
    const [videoUrls, setVideoUrls] = useState([]);
    const [linkDialogOpen, setLinkDialogOpen] = useState(false);
    const [linkUrl, setLinkUrl] = useState('');
    const [visibility, setVisibility] = useState(POST_VISIBILITY.PUBLIC);
    const quillRef = useRef(null);

    useEffect(() => {
        if (open) {
            // Wait for next render cycle to ensure modal is fully mounted
            requestAnimationFrame(() => {
                // Target the specific editor div
                const editor = document.querySelector('.ql-editor[data-gramm="false"][contenteditable="true"]');
                if (editor) {
                    editor.focus();
                    // Place cursor at the end
                    const range = document.createRange();
                    const sel = window.getSelection();
                    range.selectNodeContents(editor);
                    range.collapse(false);
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            });
        }
    }, [open]);

    const handleVideoUpload = async (file) => {
        if (!file) return;
        
        const user = auth.currentUser;
        if (!user) {
            setUploadError('You must be logged in to upload videos');
            return;
        }

        try {
            setUploadProgress(0);
            const downloadURL = await processAndUploadMedia(
                file,
                'video',
                user.uid,
                (progress) => setUploadProgress(progress)
            );
            
            console.log('Video uploaded successfully. URL:', downloadURL);
            setVideoUrls(prev => [...prev, downloadURL]);
            setUploadProgress(0);
            setUploadError(null);
        } catch (error) {
            console.error('Upload error:', error);
            setUploadError(`Upload error: ${error.message}`);
            setUploadProgress(0);
        }
    };

    const handleImageUpload = async (file) => {
        if (!file) return;
        
        const user = auth.currentUser;
        if (!user) {
            setUploadError('You must be logged in to upload images');
            return;
        }

        try {
            setUploadProgress(0);
            const downloadURL = await processAndUploadMedia(
                file,
                'image',
                user.uid,
                (progress) => setUploadProgress(progress)
            );
            
            console.log('Image uploaded successfully. URL:', downloadURL);
            const editor = quillRef.current.getEditor();
            const range = editor.getSelection();
            editor.insertEmbed(range.index, 'image', downloadURL);
            setUploadProgress(0);
            setUploadError(null);
        } catch (error) {
            console.error('Upload error:', error);
            setUploadError(`Upload error: ${error.message}`);
            setUploadProgress(0);
        }
    };

    const quillModules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['clean']
            ]
        },
        clipboard: {
            matchVisual: false
        }
    }), []);

    const quillFormats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet',
        'link', 'image', 'video'
    ];

    const determinePostType = () => {
        if (videoUrls.length > 0) {
            return POST_TYPES.VIDEO;
        }
        // Add image detection here when implemented
        return POST_TYPES.COMMENT;
    };

    const handleSubmit = (e) => {
        e?.preventDefault();
        
        const postType = determinePostType();
        const fullContent = {
            text: content,
            videos: videoUrls,
            type: postType,
            visibility
        };

        onSubmit(fullContent);
        handleClose();
    };

    const handleClose = () => {
        setContent('');
        setVideoUrls([]);
        setUploadProgress(0);
        setUploadError(null);
        onClose();
    };

    const handleLinkSubmit = () => {
        if (linkUrl.trim() && quillRef.current) {
            const editor = quillRef.current.getEditor();
            const range = editor.getSelection(true);
            editor.insertText(range.index, linkUrl.trim(), 'link', linkUrl.trim());
            setLinkUrl('');
            setLinkDialogOpen(false);
        }
    };

    return (
        <>
            <Dialog 
                open={open} 
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                sx={dialogStyles}
            >
                <DialogTitle>Create New Post</DialogTitle>
                <DialogContent>
                    <CommentFormContainer 
                        component="form" 
                        onSubmit={handleSubmit}
                        sx={{ 
                            position: 'relative',
                            '& .visibility-selector': {
                                position: 'absolute',
                                right: 0,
                                bottom: -48,
                                zIndex: 1
                            }
                        }}
                    >
                        {uploadError && (
                            <Typography color="error" sx={{ mb: 2 }}>
                                {uploadError}
                            </Typography>
                        )}

                        {uploadProgress > 0 && uploadProgress < 100 && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <CircularProgress variant="determinate" value={uploadProgress} sx={{ mr: 2 }} />
                                <Typography>Uploading: {Math.round(uploadProgress)}%</Typography>
                            </Box>
                        )}

                        <QuillWrapper>
                            <ReactQuill
                                ref={quillRef}
                                theme="snow"
                                value={content}
                                onChange={setContent}
                                modules={quillModules}
                                formats={quillFormats}
                                placeholder="What's on your mind?"
                                style={{ 
                                    backgroundColor: '#363636', 
                                    color: '#fff', 
                                    borderRadius: '4px'
                                }}
                                className="custom-quill"
                            />
                        </QuillWrapper>

                        {/* Controls row */}
                        <Box sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            mt: 3
                        }}>
                            {/* Left side - media buttons */}
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <IconButton
                                    onClick={() => {
                                        const input = document.createElement('input');
                                        input.setAttribute('type', 'file');
                                        input.setAttribute('accept', 'video/mp4,video/avi,video/quicktime,video/x-ms-wmv');
                                        input.click();

                                        input.onchange = async () => {
                                            const file = input.files[0];
                                            if (!file) return;

                                            try {
                                                await handleVideoUpload(file);
                                            } catch (error) {
                                                setUploadError(error.message);
                                                console.error('Upload error:', error);
                                            }
                                        };
                                    }}
                                    sx={{ color: '#fff', p: 1 }}
                                >
                                    <VideoIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => setLinkDialogOpen(true)}
                                    sx={{ color: '#fff', p: 1 }}
                                >
                                    <LinkIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        const input = document.createElement('input');
                                        input.setAttribute('type', 'file');
                                        input.setAttribute('accept', 'image/*');
                                        input.click();

                                        input.onchange = async () => {
                                            const file = input.files[0];
                                            if (!file) return;

                                            try {
                                                await handleImageUpload(file);
                                            } catch (error) {
                                                setUploadError(error.message);
                                                console.error('Upload error:', error);
                                            }
                                        };
                                    }}
                                    sx={{ color: '#fff', p: 1 }}
                                >
                                    <ImageIcon />
                                </IconButton>
                            </Box>
                        </Box>

                        <style>
                            {`
                                .custom-quill .ql-editor.ql-blank::before {
                                    color: #999;
                                    font-style: normal;
                                }
                            `}
                        </style>

                        {videoUrls.length > 0 && (
                            <Box 
                                sx={{ 
                                    mt: 2,
                                    maxWidth: '500px',
                                    margin: '0 auto'
                                }}
                            >
                                {videoUrls.map((url, index) => (
                                    <Box key={index} sx={{ mb: 2 }}>
                                        <VideoPlayer src={url} />
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </CommentFormContainer>
                </DialogContent>
                <DialogActions sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {isCreator && (
                            <FormControl size="small">
                                <Select
                                    value={visibility}
                                    onChange={(e) => setVisibility(e.target.value)}
                                    displayEmpty
                                    sx={{ 
                                        minWidth: 140,
                                        height: '32px',
                                        backgroundColor: '#363636',
                                        color: '#fff',
                                        fontSize: '14px',
                                        '& .MuiSelect-icon': { color: '#fff' }
                                    }}
                                >
                                    <MenuItem value={POST_VISIBILITY.PUBLIC}>{getVisibilityLabel(POST_VISIBILITY.PUBLIC)}</MenuItem>
                                    <MenuItem value={POST_VISIBILITY.FOLLOWERS}>{getVisibilityLabel(POST_VISIBILITY.FOLLOWERS)}</MenuItem>
                                    <MenuItem value={POST_VISIBILITY.SUBSCRIBERS}>{getVisibilityLabel(POST_VISIBILITY.SUBSCRIBERS)}</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button onClick={handleClose} sx={{ color: '#fff' }}>Cancel</Button>
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={handleSubmit}
                            sx={{
                                '&.Mui-disabled': {
                                    backgroundColor: '#424242',
                                    color: '#666'
                                }
                            }}
                        >
                            Post
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            {/* Link Dialog */}
            <Dialog
                open={linkDialogOpen}
                onClose={() => setLinkDialogOpen(false)}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: '#242424',
                        color: '#fff',
                        minWidth: '400px'
                    }
                }}
            >
                <DialogTitle>Add Link</DialogTitle>
                <DialogContent>
                    <Box 
                        component="form" 
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleLinkSubmit();
                        }}
                        sx={{ pt: 1 }}
                    >
                        <input
                            type="url"
                            value={linkUrl}
                            onChange={(e) => setLinkUrl(e.target.value)}
                            placeholder="Enter URL"
                            style={{
                                width: '100%',
                                padding: '8px 12px',
                                backgroundColor: '#363636',
                                border: 'none',
                                borderRadius: '4px',
                                color: '#fff',
                                fontSize: '16px',
                                outline: 'none'
                            }}
                            autoFocus
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setLinkDialogOpen(false)}
                        sx={{ color: '#fff' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleLinkSubmit}
                        variant="contained"
                        color="primary"
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CommentForm;