import styled from 'styled-components';

export const StyledApp = styled.div`
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.primary} !important;
  color: ${({ theme }) => theme.colors.text.primary};
  transition: all 0.2s ease;
`;

export const AppHeader = styled.header`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: ${({ theme }) => theme.colors.text.primary};
  transition: all 0.2s ease;
`;

export const AppLink = styled.a`
  color: ${({ theme }) => theme.colors.primary.main};
  transition: all 0.2s ease;
`; 