import { useState, useEffect, useCallback, useMemo } from 'react';
import { collection, query, getDocs, limit, startAfter, orderBy, deleteDoc, doc, addDoc } from 'firebase/firestore';
import { firestore } from '../services/firebase';

const COMMENTS_PER_PAGE = 10;

export const useComments = (user, commentType = 'user', itemId, userId, collectionType = 'users') => {
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [lastDoc, setLastDoc] = useState(null);

    // Construct the path based on the comment type
    const path = useMemo(() => {
        const targetUserId = commentType === 'gym' ? itemId : 
                           commentType === 'event' ? userId : userId;
        const targetType = commentType === 'gym' ? 'location' : 
                         commentType === 'event' ? `event/${itemId}` : commentType;
        
        if (commentType === 'gym') {
            return `locations/${targetUserId}/comments`;
        } else if (commentType === 'event') {
            return `locations/${userId}/events/${itemId}/comments`;
        } else {
            return `users/${targetUserId}/comments`;
        }
    }, [commentType, itemId, userId]);

    const fetchComments = useCallback(async (isInitial = false) => {
        if (!path || loading || (!hasMore && !isInitial)) return;
        
        setLoading(true);
        try {
            const commentsRef = collection(firestore, path);
            let q = query(
                commentsRef,
                orderBy('createdAt', 'desc'),
                limit(COMMENTS_PER_PAGE)
            );

            if (lastDoc && !isInitial) {
                q = query(q, startAfter(lastDoc));
            }

            const snapshot = await getDocs(q);
            const newComments = [];
            
            // Batch fetch all replies for these comments
            const replyPromises = snapshot.docs.map(async (doc) => {
                const commentData = { id: doc.id, ...doc.data() };
                const repliesRef = collection(firestore, `${path}/${doc.id}/replies`);
                const repliesSnapshot = await getDocs(query(repliesRef, orderBy('createdAt', 'asc')));
                commentData.replies = repliesSnapshot.docs.map(replyDoc => ({
                    id: replyDoc.id,
                    ...replyDoc.data()
                }));
                return commentData;
            });

            const commentsWithReplies = await Promise.all(replyPromises);
            
            if (isInitial) {
                setComments(commentsWithReplies);
            } else {
                setComments(prev => [...prev, ...commentsWithReplies]);
            }

            setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
            setHasMore(snapshot.docs.length === COMMENTS_PER_PAGE);
        } catch (error) {
            console.error('Error fetching comments:', error);
        } finally {
            setLoading(false);
        }
    }, [path, lastDoc, loading, hasMore]);

    useEffect(() => {
        if (path) {
            fetchComments(true);
        }
    }, [path, fetchComments]);

    const loadMore = () => {
        fetchComments();
    };

    const addLocalComment = useCallback((newComment) => {
        if (Array.isArray(newComment)) {
            setComments(newComment);
        } else {
            setComments(prevComments => [newComment, ...prevComments]);
        }
    }, []);

    const handleDeleteComment = useCallback(async (commentId) => {
        try {
            await deleteDoc(doc(firestore, `${path}/${commentId}`));
            setComments(prevComments => prevComments.filter(comment => comment.id !== commentId));
            return true;
        } catch (error) {
            console.error('Error deleting comment:', error);
            return false;
        }
    }, [path]);

    const handleDeleteReply = useCallback(async (commentId, replyId) => {
        try {
            await deleteDoc(doc(firestore, `${path}/${commentId}/replies/${replyId}`));
            setComments(prevComments => prevComments.map(comment => 
                comment.id === commentId
                    ? {
                        ...comment,
                        replies: comment.replies.filter(reply => reply.id !== replyId)
                    }
                    : comment
            ));
            return true;
        } catch (error) {
            console.error('Error deleting reply:', error);
            return false;
        }
    }, [path]);

    const handleAddReply = useCallback(async (commentId, replyData) => {
        try {
            const replyRef = collection(firestore, `${path}/${commentId}/replies`);
            const newReplyDoc = await addDoc(replyRef, replyData);
            const newReply = { id: newReplyDoc.id, ...replyData };
            
            setComments(prevComments => prevComments.map(comment =>
                comment.id === commentId
                    ? {
                        ...comment,
                        replies: [...comment.replies, newReply]
                    }
                    : comment
            ));
            return newReply;
        } catch (error) {
            console.error('Error adding reply:', error);
            throw error;
        }
    }, [path]);

    return { 
        comments, 
        loading, 
        hasMore, 
        loadMore,
        addLocalComment,
        handleDeleteComment,
        handleDeleteReply,
        handleAddReply
    };
};

export default useComments;