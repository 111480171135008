import { firestore } from './firebase';
import { 
  doc, 
  collection, 
  addDoc, 
  updateDoc, 
  increment, 
  serverTimestamp,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  getDoc,
  setDoc
} from 'firebase/firestore';

// Track content views
export const trackContentView = async (contentId, userId) => {
  try {
    const contentRef = doc(firestore, 'creator_content', contentId);
    const analyticsRef = doc(firestore, 'creator_content', contentId, 'analytics', 'views');
    const today = new Date().toISOString().split('T')[0];

    // Update total views
    await updateDoc(analyticsRef, {
      total: increment(1),
      [`daily.${today}`]: increment(1)
    });

    // If user is logged in, track viewer demographics
    if (userId) {
      const userRef = doc(firestore, 'users', userId);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();

      if (userData?.beltRank) {
        await updateDoc(analyticsRef, {
          [`viewerDemographics.beltRanks.${userData.beltRank.toLowerCase()}`]: increment(1)
        });
      }
    }
  } catch (error) {
    console.error('Error tracking content view:', error);
  }
};

// Track engagement (likes, comments, shares)
export const trackEngagement = async (contentId, type) => {
  try {
    const analyticsRef = doc(firestore, 'creator_content', contentId, 'analytics', 'engagement');
    await updateDoc(analyticsRef, {
      [`${type}`]: increment(1)
    });
  } catch (error) {
    console.error('Error tracking engagement:', error);
  }
};

// Track subscription events
export const trackSubscription = async (creatorId, subscriberId, subscriptionData) => {
  try {
    const today = new Date().toISOString().split('T')[0];
    const analyticsRef = doc(firestore, `users/${creatorId}/analytics/subscribers`);
    
    // Update daily stats
    await updateDoc(analyticsRef, {
      [`dailyStats.${today}.total`]: increment(1),
      [`dailyStats.${today}.new`]: increment(1)
    });

    // Record subscription transaction
    const transactionRef = collection(firestore, `users/${creatorId}/transactions`);
    await addDoc(transactionRef, {
      type: 'subscription',
      amount: subscriptionData.amount,
      status: 'completed',
      subscriberId,
      date: serverTimestamp()
    });

    // Update revenue analytics
    const revenueRef = doc(firestore, `users/${creatorId}/analytics/revenue`);
    await updateDoc(revenueRef, {
      [`dailyRevenue.${today}.total`]: increment(subscriptionData.amount),
      [`dailyRevenue.${today}.subscriptions`]: increment(subscriptionData.amount)
    });
  } catch (error) {
    console.error('Error tracking subscription:', error);
  }
};

// Track subscription cancellations
export const trackSubscriptionCancellation = async (creatorId, subscriberId) => {
  try {
    const today = new Date().toISOString().split('T')[0];
    const analyticsRef = doc(firestore, `users/${creatorId}/analytics/subscribers`);
    
    await updateDoc(analyticsRef, {
      [`dailyStats.${today}.cancelled`]: increment(1)
    });
  } catch (error) {
    console.error('Error tracking subscription cancellation:', error);
  }
};

// Fetch analytics data for dashboard
export const fetchCreatorAnalytics = async (creatorId, period = '7d') => {
  try {
    const analyticsData = {
      subscribers: { total: 0, growth: [] },
      revenue: { total: 0, growth: [] },
      content: { total: 0, performance: [] }
    };

    // Fetch subscriber data
    const subscribersRef = doc(firestore, `users/${creatorId}/analytics/subscribers`);
    const subscribersDoc = await getDoc(subscribersRef);
    if (subscribersDoc.exists()) {
      const data = subscribersDoc.data();
      analyticsData.subscribers = processSubscriberData(data, period);
    }

    // Fetch revenue data
    const revenueRef = doc(firestore, `users/${creatorId}/analytics/revenue`);
    const revenueDoc = await getDoc(revenueRef);
    if (revenueDoc.exists()) {
      const data = revenueDoc.data();
      analyticsData.revenue = processRevenueData(data, period);
    }

    // Fetch content performance
    const contentQuery = query(
      collection(firestore, 'creator_content'),
      where('creatorId', '==', creatorId),
      orderBy('views', 'desc'),
      limit(5)
    );
    const contentSnapshot = await getDocs(contentQuery);
    analyticsData.content.performance = contentSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return analyticsData;
  } catch (error) {
    console.error('Error fetching creator analytics:', error);
    throw error;
  }
};

// Helper functions to process analytics data
const processSubscriberData = (data, period) => {
  const dates = getLast_N_Days(period);
  const growth = dates.map(date => ({
    date,
    subscribers: data.dailyStats?.[date]?.total || 0
  }));

  return {
    total: calculateTotal(growth, 'subscribers'),
    growth
  };
};

const processRevenueData = (data, period) => {
  const dates = getLast_N_Days(period);
  const growth = dates.map(date => ({
    date,
    revenue: data.dailyRevenue?.[date]?.total || 0
  }));

  return {
    total: calculateTotal(growth, 'revenue'),
    growth
  };
};

const getLast_N_Days = (period) => {
  const days = period === '7d' ? 7 : period === '30d' ? 30 : 90;
  const dates = [];
  for (let i = days - 1; i >= 0; i--) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    dates.push(date.toISOString().split('T')[0]);
  }
  return dates;
};

const calculateTotal = (data, key) => {
  return data.reduce((sum, item) => sum + (item[key] || 0), 0);
}; 