import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../../services/firebase';
import { useRole } from '../../hooks/useRole';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { 
    LeftSidebar as StyledLeftSidebar, 
    NavItem,
    NewPostButton,
    SidebarNewPostButton,
    MoreMenu,
    MoreMenuContent 
} from '../../styles/layout/Sidebar.styles';
import CommentForm from '../forms/CommentForm';
import { addComment } from '../../services/commentsService';
import {
    Home as HomeIcon,
    Map as MapIcon,
    Notifications as NotificationsIcon,
    Message as MessageIcon,
    Person as PersonIcon,
    MoreHoriz as MoreIcon,
    Search as SearchIcon,
    Info as InfoIcon,
    ContactSupport as ContactSupportIcon,
    AdminPanelSettings as AdminPanelSettingsIcon,
    Settings as SettingsIcon,
    Help as HelpIcon,
    Logout as LogoutIcon,
    Add as AddIcon,
} from '@mui/icons-material';

const LeftSidebar = ({ onLocationRequest }) => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const { isAdmin, isCreator } = useRole();
    const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
    const [isNewPostOpen, setIsNewPostOpen] = useState(false);

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleNewPost = () => {
        setIsNewPostOpen(true);
    };

    const handleSubmitPost = async (content) => {
        if (!user) return;
        
        try {
            const commentToAdd = {
                ...content,
                userId: user.uid,
                userName: user.displayName || 'Anonymous',
                profilePicUrl: user.photoURL || 'default-profile.png',
                createdAt: new Date()
            };

            const newComment = await addComment(user.uid, 'user', commentToAdd);
            setIsNewPostOpen(false);
            navigate(`/profile/${user.uid}#${newComment.id}`);
        } catch (error) {
            console.error('Error creating post:', error);
        }
    };

    // Left group navigation items
    const leftNavItems = (
        <>
            <NavItem onClick={() => navigate('/')} className="primary-nav left-group">
                <HomeIcon /> <span>Home</span>
            </NavItem>
            <NavItem onClick={() => navigate('/find-gym')} className="primary-nav left-group">
                <MapIcon /> <span>Find a Gym</span>
            </NavItem>
            {user && (
                <NavItem onClick={() => navigate('/notifications')} className="primary-nav left-group">
                    <NotificationsIcon /> <span>Notifications</span>
                </NavItem>
            )}
        </>
    );

    // Right group navigation items
    const rightNavItems = (
        <>
            {user && (
                <>
                    <NavItem onClick={() => navigate('/messages')} className="primary-nav right-group">
                        <MessageIcon /> <span>Messages</span>
                    </NavItem>
                    <NavItem onClick={() => navigate(`/profile/${user?.uid}`)} className="primary-nav right-group">
                        <PersonIcon /> <span>Profile</span>
                    </NavItem>
                    <SidebarNewPostButton onClick={handleNewPost}>
                        <AddIcon /> <span>New Post</span>
                    </SidebarNewPostButton>
                </>
            )}
            <NavItem 
                onClick={() => setIsMoreMenuOpen(!isMoreMenuOpen)}
                className="more-button right-group"
            >
                <MoreIcon /> <span>More</span>
            </NavItem>
        </>
    );

    // Secondary items that go in the More menu
    const moreMenuItems = (
        <MoreMenuContent $isOpen={isMoreMenuOpen}>
            <NavItem onClick={() => navigate('/search')}>
                <SearchIcon /> <span>Search</span>
            </NavItem>
            <NavItem onClick={() => navigate('/about')}>
                <InfoIcon /> <span>About</span>
            </NavItem>
            <NavItem onClick={() => navigate('/contact')}>
                <ContactSupportIcon /> <span>Contact</span>
            </NavItem>
            {isAdmin && (
                <NavItem onClick={() => navigate('/admin-dashboard')}>
                    <AdminPanelSettingsIcon /> <span>Admin</span>
                </NavItem>
            )}
            <NavItem onClick={() => navigate('/settings')}>
                <SettingsIcon /> <span>Settings</span>
            </NavItem>
            <NavItem onClick={() => navigate('/help')}>
                <HelpIcon /> <span>Help</span>
            </NavItem>
            {user && (
                <NavItem onClick={handleLogout} className="logout-button">
                    <LogoutIcon /> <span>Logout</span>
                </NavItem>
            )}
        </MoreMenuContent>
    );

    return (
        <StyledLeftSidebar>
            {leftNavItems}
            <NewPostButton onClick={handleNewPost}>
                <AddIcon />
            </NewPostButton>
            {rightNavItems}
            {moreMenuItems}
            <CommentForm 
                open={isNewPostOpen}
                onClose={() => setIsNewPostOpen(false)}
                onSubmit={handleSubmitPost}
                isCreator={isCreator}
            />
        </StyledLeftSidebar>
    );
};

export default LeftSidebar; 