import styled from 'styled-components';
import { LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from './Sidebar.styles';

// Layout constants
const CONTENT_MIN_WIDTH = '600px';
const CONTENT_MAX_WIDTH = '800px';

export const PageLayout = styled.div`
  display: grid;
  grid-template-columns: ${LEFT_SIDEBAR_WIDTH} minmax(${CONTENT_MIN_WIDTH}, ${CONTENT_MAX_WIDTH}) ${RIGHT_SIDEBAR_WIDTH};
  gap: 12px;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background.primary};
  max-width: 1440px;
  margin: 0 auto;
  transition: all 0.3s ease;
  position: relative;

  @media (max-width: 1400px) {
    grid-template-columns: ${LEFT_SIDEBAR_WIDTH} minmax(${CONTENT_MIN_WIDTH}, 1fr) 240px;
    gap: 8px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 80px minmax(${CONTENT_MIN_WIDTH}, 1fr) 80px;
    gap: 4px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    width: 100%;
  }
`;

export const PageContent = styled.main`
  width: 100%;
  margin: 0 auto;
  transition: all 0.3s ease;
  position: relative;
  flex: 1;
  padding: 16px;

  &::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    margin-bottom: 60px;
  }
`; 